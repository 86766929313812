
import Vue from 'vue';
import moment from 'moment';

interface CanHaveBirthday {
  birthday?: Date;
}

export default Vue.extend({
  props: {
    entity: {
      type: Object as () => CanHaveBirthday,
      required: true,
    },
  },
  computed: {
    moment: () => moment,
  },
})
