import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {StudentFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/StudentFields';

export const studentFieldsDictionary: FieldDictionary<StudentFields> = [
    {
        text: 'Geschlecht',
        value: StudentFields.GENDER,
    },
    {
        text: 'Vorname',
        value: StudentFields.FIRST_NAME,
    },
    {
        text: 'Nachname',
        value: StudentFields.LAST_NAME,
    },
    {
        text: 'Geburtsdatum',
        value: StudentFields.BIRTHDAY,
    },
    {
        text: 'Anschrift',
        value: StudentFields.ADDRESS,
    },
    {
        text: 'Zahlungsinformationen',
        value: StudentFields.PAYMENT,
    },
    {
        text: 'Zugang zu Vanilla',
        value: StudentFields.HAS_LOGIN,
    },
    {
        text: 'Aktivitätsstatus',
        value: StudentFields.IS_ACTIVE,
    },
    {
        text: 'Datum der Deaktivierung',
        value: StudentFields.DEACTIVATED_AT,
    },
    {
        text: 'Kontaktdaten',
        value: StudentFields.CONTACT_INFORMATION,
    },
    {
        text: 'Kundennummer',
        value: StudentFields.CUSTOM_ID,
        readOnly: true,
    },
    {
        text: 'Art der Kundennummer',
        value: StudentFields.CUSTOM_ID_SCHEME,
        readOnly: true,
    },
    {
        text: 'Referenz zur Buchhaltungsschnittstelle',
        value: StudentFields.ACCOUNTING_REF,
        readOnly: true,
    },
    {
        text: 'Standort',
        value: StudentFields.INSTITUTION,
    },
    {
        text: 'Eltern',
        value: StudentFields.PARENTS,
    },
    {
        text: 'Klasseninfos',
        value: StudentFields.CLASS,
    },
    {
        text: 'Zusatzinfos',
        value: StudentFields.SPECIAL_INFORMATION,
    },
    {
        text: 'Status des Schülers',
        value: StudentFields.STATE,
    },
    {
        text: 'Fächer',
        value: StudentFields.SUBJECTS,
    },
    {
        text: 'Schüler ist Kunde?',
        value: StudentFields.IS_CUSTOMER,
    },
    {
        text: 'Pausierung',
        value: StudentFields.PAUSED_UNTIL,
    },
    {
        text: 'Datum der Erzeugung',
        value: StudentFields.CREATED_AT,
        readOnly: true,
    },
    {
        text: 'Datum der letzten Aktualisierung',
        value: StudentFields.UPDATED_AT,
        readOnly: true,
    },
    {
        text: 'Datum einer geplanten Deaktivierung',
        value: StudentFields.DEACTIVATE_AT,
        readOnly: true,
    },
    {
        text: 'Datum einer geplanten Aktivierung',
        value: StudentFields.ACTIVATE_AT,
        readOnly: true,
    },
    {
        text: 'Tags',
        value: StudentFields.TAGS,
    },
];
