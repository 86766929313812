import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import de from 'vuetify/src/locale/de';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
    // dark: true,
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#f2e791',
        // primary: '#e5da92',
        tabs: '#616161',
        nav: '#f2e791',
        navContent: '#332f20',
        search: '#455A64',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        warning: '#FFC107',
      },
      dark: {
        primary: '#f2e791',
      },
    },
  },
    lang: {
      locales: { de },
      current: 'de',
    },
});
