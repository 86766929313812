
import Vue from 'vue';
import { CrudEntityTypes } from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import { Types } from 'mongoose';
import { Contract } from '@/interfaces/contract.interface';
import { ContractorAPI } from '@/classes/api/contractor.api';
import { ContractFields } from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/ContractFields';
import { SalaryBasisVue } from '@/enums/SalaryBasis.enum';
import { toCurrencyString } from '@/helpers/toCurrencyString.helper';

export default Vue.extend({
  components: {
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    contractId: {
      type: Object as () => Types.ObjectId,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    contract: null as null | Contract,
  }),
  computed: {
    SalaryBasisVue: () => SalaryBasisVue,
    toCurrency: () => toCurrencyString,
  },
  methods: {
    async getContract() {
      try {
        this.loading = true;
        this.contract = await ContractorAPI.findById(this.contractId, {
          fields: ['name', 'conditions', 'travelExpenseCompensationPerKilometer', 'salaryPerUnit', 'salaryBasis', 'createdAt'] as ContractFields[],
        });
      } catch (e) {
        this.$$showSnackbar('Es ist ein Fehler beim Laden des Arbeitsvertrags aufgetreten. Bitte versuche es erneut.', 'error');
        this.$emit('input', false)
      } finally {
        this.loading = false;
      }
    },
    reset() {
      this.loading = false;
      this.contract = null;
      this.$emit('input', false);
    },
    shareAccess() {
      this.$$shareAccessDialog.show(CrudEntityTypes.CONTRACT, this.contractId, 'Arbeitsvertrag freigeben')
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v: boolean) {
        if (v) {
          this.getContract();
        }
      },
    },
  },
});
