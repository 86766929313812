
import Vue from 'vue';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {Form} from '@/interfaces/Form.interface';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
    },
  },
  data: () => ({
    pass: '',
    valid: false,
  }),
  computed: {
    RuleFactory: () => RuleFactory,
  },
  methods: {
    confirm() {
      const form = this.$refs.form as Form;
      if (form.validate()) {
        this.$emit('commit', this.pass);
        this.reset();
      }
    },
    reset() {
      const form = this.$refs.form as Form;
      form.reset();
      this.$emit('input', false);
    },
    setFocus() {
      const field = this.$refs.passField as Vue;
      if (field) {
        const element = field.$el as HTMLElement;
        const inputField = element.querySelector('input');
        if (inputField) {
          inputField.focus();
        }
      }
    },
  },
  watch: {
    value(v) {
      const form = this.$refs.form as Form;
      if (v) {
        setTimeout(() => {
          this.setFocus();
          form?.resetValidation();
        }, 150);
      }
    },
  },
  mounted() {
    this.setFocus();
  },
});
