export enum InvoiceDocumentFields {
    INVOICE = 'invoice',
    TEMPLATE = 'template',
    ACCOUNTING_REF = 'accountingRef',
    TYPE = 'type',
    CUSTOMER_TYPE = 'customerType',
    CUSTOMER = 'customer',
    STUDENT = 'student',
    CUSTOM_ID = 'customId',
    IS_CANCELED = 'isCanceled',
}
