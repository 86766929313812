export class TimeRange {
    startTime: number;
    endTime: number;

    constructor(startTime: number, endTime: number) {
        this.startTime = startTime;
        this.endTime = endTime;
    }

    public intersectsWith(interval: TimeRange) {
        if (this.startTime < interval.endTime && this.endTime > interval.startTime) {
            return true;
        } else if (interval.startTime < this.endTime && interval.endTime > this.startTime) {
            return true;
        }
        return false;
    }
}
