
import Vue from 'vue';
import {FindGlobalResponseDto} from '@/classes/dto/finder/response/FindGlobal.response.dto';
import SearchGridItem from '@/components/app/appBar/searchDialog/searchGridItem.vue';

export default Vue.extend({
  components: {SearchGridItem},
  props: {
    result: {
      type: Object as () => FindGlobalResponseDto,
      required: true,
    },
    highlightBestMatch: {
      type: Boolean,
      required: true,
    },
  },
});
