
import Vue from 'vue';
import {ContactInformation} from '@/interfaces/contactInformation.interface';

export default Vue.extend({
  props: {
    contactInformation: {
      type: Object as () => ContactInformation,
      required: true,
    },
  },
});
