
import Vue from 'vue';
import { ConfigHandlerAPI } from '@/classes/api/config-handler.api.class';
import { Config } from '@/interfaces/config.interface';
import StackDialog from '@/components/stack/StackDialog.vue';
import AppBarProfileMenu from '@/components/app/appBar/appBarProfileMenu.vue';
import AppBarSearchControl from '@/components/app/appBar/appBarSearchControl.vue';
import GmapsSearchDialog from '@/components/app/gmapsSearchDialog.vue';
import { CustomAccessKeys } from '@/classes/clientOnly/permissionTreeResources/enums/CustomAccessKeys';
import CreateAvataaarDialog from '@/components/common/profile/createAvataaarDialog.vue';

export default Vue.extend({
  components: {CreateAvataaarDialog, AppBarSearchControl, AppBarProfileMenu, StackDialog, GmapsSearchDialog},
  props: {
    showDrawer: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showStackDialog: false,
    showCreateAvataaarDialog: false,

    showSearchInput: false,
    showUserMenu: true,
    showGmapsSearchDialog: false,
    loading: false,
    customerConfig: null as null | Config,
  }),
  computed: {
    isLoggedIn(): boolean {
      return !!this.$store.state.axios;
    },
    canUseGmapsDistanceMatrix(): boolean {
      return !!this.$store.state.crudAccessManager.getCustom(CustomAccessKeys.USE_GMAPS_DISTANCE_MATRIX);
    },
  },
  methods: {
    async getConfig() {
      try {
        this.loading = true;
        this.customerConfig = await ConfigHandlerAPI.getConfig();
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    '$store.state.user': {
      deep: true,
      handler() {
        this.showUserMenu = false;
        setTimeout(() => {
          this.showUserMenu = true;
        }, 500);
      },
    },
  },
  async mounted() {
    // await this.getConfig();
  },
});
