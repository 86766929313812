
import Vue from 'vue';
import { Responsible } from '@/interfaces/responsible.interface';
import ProfileAvatar from '@/components/common/profile-avatar.vue';
import { toSalutation } from '@/helpers/toSalutation.helper';
import CreateResponsibleDialog from '@/components/responsibles/createResponsibleDialog.vue';
import EditResponsibleDialog from '@/components/responsibles/editResponsibleDialog.vue';
import ResponsibleCardDialog from '@/components/responsibles/responsibleCardDialog.vue';
import { Institution } from '@/interfaces/institution.interface';
import { isObjectId } from '@/helpers/isObjectId.helper';
import { isJobType } from '@/interfaces/jobType.interface';
import { GenderTypes } from '@/enums/GenderTypes.enum';

export default Vue.extend({
  data: () => ({
    activeResponsible: null as null | Responsible,
    showEditResponsibleDialog: false,
    showResponsibleDialog: false,
    showCreateResponsibleDialog: false,
  }),
  components: {
    ProfileAvatar,
    CreateResponsibleDialog,
    EditResponsibleDialog,
    ResponsibleCardDialog,
  },
  props: {
    entity: {
      type: Object as () => Institution,
      required: true,
    },
  },
  computed: {
    filteredEntities(): Responsible[] {
      if (Array.isArray(this.entity.responsibles)) {
        return this.entity.responsibles.filter((el) => !isObjectId(el) && el) as Responsible[];
      } else {
        return [];
      }
    },
    toSalutation: () => toSalutation,
  },
  methods: {
    getJobTypeString(responsible: Responsible) {
      if (responsible.jobType && isJobType(responsible.jobType)) {
        switch (responsible.gender) {
          case GenderTypes.FEMALE:
            return responsible.jobType.female;
          case GenderTypes.MALE:
            return responsible.jobType.male;
          case GenderTypes.TRANS:
            return responsible.jobType.neutral;
          default:
            return responsible.jobType.neutral;
        }
      } else {
        return 'Jobposition unbekannt';
      }
    },
  },
});
