import Vue from 'vue';
import VueRouter from 'vue-router';
import {RouteConfig} from 'vue-router/types/router';
import store from '@/store';
import {LoginApi} from '@/classes/api/login.api.class';
import {CheckLoginResponseDto} from '@/classes/dto/authenticator/response/CheckLogin.response.dto';
import {ConfigHandlerAPI} from '@/classes/api/config-handler.api.class';
import {CrudEntityTypes} from "@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes";
import {CrudActionTypes} from "@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/courseRepairKit',
        name: 'Kursreparaturkit',
        component: () => import('../views/private/CourseRepairKit.vue'),
    },
    {
        path: '/',
        name: 'Dashboard',
        component: () => import('../views/Dashboard.vue'),
    },
    {
        path: '/demo',
        name: 'Demo der neuesten Funktionen in Vanilla',
        component: () => import('../views/demoView.vue'),
    },
    {
        path: '/terminations',
        name: 'Kündigungen',
        component: () => import('../views/Terminations.vue'),
    },
    // {
    //   path: '/announcements',
    //   name: 'Ankündigungen',
    //   component: () => import(/* webpackChunkName: "about" */ '../views/Announcements.vue'),
    // },
    {
        path: '/schedule/:calendarDate?',
        name: 'Mein Stundenplan',
        component: () => import(/* webpackChunkName: "about" */ '../views/MySchedule.vue'),
    },
    {
        path: '/my-appointments/',
        name: 'Meine Termine',
        component: () => import(/* webpackChunkName: "about" */ '../views/MyAppointments.vue'),
    },
    {
        path: '/payoff',
        name: 'Abrechnung',
        component: () => import(/* webpackChunkName: "about" */ '../views/Payoff.vue'),
    },
    {
        path: '/courses',
        name: 'Kurse',
        component: () => import(/* webpackChunkName: "about" */ '../views/AllCourses.vue'),
    },
    {
        path: '/courses/:courseId',
        name: 'Kurs',
        component: () => import(/* webpackChunkName: "about" */ '../views/Course.vue'),
    },
    {
        path: '/substitution-plan',
        name: 'Vertretungsplan',
        component: () => import(/* webpackChunkName: "about" */ '../views/SubstitutionPlan.vue'),
    },
    {
        path: '/employees',
        name: 'Mitarbeitende',
        component: () => import(/* webpackChunkName: "about" */ '../views/Employees.vue'),
    },
    {
        path: '/employees/page/:page',
        name: 'Mitarbeiter (Seite)',
        component: () => import('@/views/Employees.vue'),
    },
    {
        path: '/teachers',
        name: 'Lehrkräfte',
        component: () => import('../views/Teachers.vue'),
    },
    {
        path: '/missing-documents',
        name: 'Fehlende Dokumente',
        component: () => import('../views/MissingDocuments.vue'),
    },
    {
        path: '/teachers/:employeeId',
        name: 'Lehrkraft',
        component: () => import('../views/Employee.vue'),
    },
    {
        path: '/teachers/page/:page',
        name: 'Alle Lehrer - Seite',
        component: () => import('@/views/Teachers.vue'),
    },
    {
        path: '/employees/:employeeId',
        name: 'Mitarbeitende:r',
        component: () => import(/* webpackChunkName: "about" */ '../views/Employee.vue'),
    },
    {
        path: '/students',
        name: 'Alle Schüler',
        component: () => import('@/views/Students.vue'),
    },
    {
        path: '/students/page/:page',
        name: 'Alle Schüler (Seite)',
        component: () => import('@/views/Students.vue'),
    },
    {
        path: '/students/:studentId',
        name: 'Schüler',
        component: () => import('@/views/Student.vue'),
    }, {
        path: '/parents',
        name: 'Alle Eltern',
        component: () => import('@/views/Parents.vue'),
    },
    {
        path: '/parents/page/:page',
        name: 'Alle Eltern (Seite)',
        component: () => import('@/views/Parents.vue'),
    },
    {
        path: '/parents/:parentId',
        name: 'Eltern',
        component: () => import(/* webpackChunkName: "about" */ '../views/Parent.vue'),
    },
    {
        path: '/tools/bookInvoiceGenerator',
        name: 'Buchrechnungsgenerator',
        component: () => import('../views/tools/BookInvoiceGenerator.vue'),
    },
    {
        path: '/tools/sepaTools',
        name: 'SEPA XML',
        component: () => import('../views/tools/SepaTools.vue'),
    },
    {
        path: '/tools/tags',
        name: 'Tag Manager',
        component: () => import('../views/tools/TagManager.vue'),
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
        meta: {
            guestOnly: true,
        },
    },
    {
        path: '/stats',
        name: 'Statistiken und Analysen',
        component: () => import('@/views/Stats.vue'),
    },
    {
        path: '/stats/teacher-termination-stats',
        name: 'Kündigungsrate der Lehrkräfte',
        component: () => import('@/views/stats/TeacherTerminationStats.vue'),
    },
    {
        path: '/stats/employee-acquisition-stats',
        name: 'Akquiseleistung der Mitarbeitenden',
        component: () => import('@/views/stats/EmployeeAcquisitionStats.vue'),
    },
    {
        path: '/planning/handout-events',
        name: 'Austeilaktionen',
        component: () => import('@/views/planning/HandoutEvents.vue'),
    },
    {
        path: '/planning/handout-events/:handoutEventId',
        name: 'Austeilaktion',
        component: () => import('@/views/planning/HandoutEvent.vue'),
    },
    {
        path: '/planning/handout-overview',
        name: 'Terminübersicht für Austeilaktionen',
        component: () => import('@/views/planning/HandoutOverview.vue'),
    },
    {
        path: '/settings/import',
        name: 'Import',
        component: () => import('@/views/Import.vue'),
    },
    {
        path: '/settings/course-table',
        name: 'Tabellarische Kursübersicht',
        component: () => import('@/views/settings/CourseTable.vue'),
    },
    {
        path: '/settings/referencing',
        name: 'Referenzierung',
        component: () => import('@/views/settings/Referencing.vue'),
    },
    {
        path: '/settings/standing-data',
        name: 'Stammdaten',
        component: () => import('@/views/settings/StandingData.vue'),
    },
    {
        path: '/settings/permissions',
        component: () => import('@/views/settings/Permissions.vue'),
        children: [
            {
                path: '/',
                name: 'Berechtigungen',
                component: () => import('@/views/settings/permissions/PermissionOverview.vue'),
            },
            {
                path: 'user/:id',
                name: 'Berechtigungen für Benutzer',
                component: () => import('@/views/settings/permissions/UserPermissions.vue'),
            },
            {
                path: 'role/:id',
                name: 'Berechtigungen für Rolle',
                component: () => import('@/views/settings/permissions/RolePermissions.vue'),
            },
        ],
    },
    {
        path: '/settings/auto-permissions',
        component: () => import('@/views/settings/PermissionHooks.vue'),
        children: [
            {
                path: '/',
                name: 'Berechtigungsroutinen',
                component: () => import('@/views/settings/permissionHooks/PermissionHookOverview.vue'),
            },
        ],
    },
    {
        path: '/settings/document-types',
        name: 'Dokumenttypen',
        component: () => import('@/views/settings/DocumentTypes.vue'),
    },
    {
        path: '/settings/cron-jobs',
        name: 'Automatisierung',
        component: () => import('@/views/settings/CronJobs.vue'),
    },
    {
        path: '/settings/api',
        name: 'API & Webhooks',
        component: () => import('@/views/settings/API.vue'),
    },
    {
        path: '/settings',
        name: 'Einstellungen',
        component: () => import(/* webpackChunkName: "about" */ '../views/Settings.vue'),
    },
    {
        path: '/products',
        name: 'Logistik',
        component: () => import(/* webpackChunkName: "about" */ '../views/Products.vue'),
    },
    {
        path: '/products/:articleId',
        name: 'Produkt',
        component: () => import(/* webpackChunkName: "about" */ '../views/Product.vue'),
    },
    {
        path: '/templates/invoice',
        name: 'Rechnungsvorlagen',
        component: () => import(/* webpackChunkName: "about" */ '../views/templates/InvoiceTemplates.vue'),
    },
    {
        path: '/templates/invoice/:id',
        name: 'Rechnungsvorlage',
        component: () => import(/* webpackChunkName: "about" */ '../views/templates/InvoiceTemplate.vue'),
    },
    {
        path: '/templates/contract',
        name: 'Verträge',
        component: () => import(/* webpackChunkName: "about" */ '../views/Contracts.vue'),
    },
    {
        path: '/templates/mail',
        name: 'E-Mail Templates',
        component: () => import('../views/templates/MailTemplates.vue'),
    },
    {
        path: '/templates/mail/:templateId/:templateType',
        name: 'E-Mail Template',
        component: () => import('../views/templates/MailEditor.vue'),
    },
    {
        path: '/templates/handout',
        name: 'Anmeldungsvorlagen',
        component: () => import('@/views/templates/HandoutTemplates.vue'),
    },
    {
        path: '/institutions',
        name: 'Standorte',
        component: () => import('@/views/Institutions.vue'),
    },
    {
        path: '/institutions/page/:page',
        name: 'Standorte (Seite)',
        component: () => import('@/views/Institutions.vue'),
    },
    {
        path: '/institutions/:institutionId',
        name: 'Standort',
        component: () => import(/* webpackChunkName: "about" */ '../views/Institution.vue'),
    },
    {
        path: '/coursematerials',
        name: 'Kursmaterialien',
        component: () => import ('../views/CourseMaterials.vue'),
    },
    {
        path: '/subjects',
        name: 'Fächer',
        component: () => import('../views/Subjects.vue'),
    },
    {
        path: '/vacation',
        name: 'Ferien und Pausen',
        component: () => import('../views/Vacation.vue'),
    },
    {
        path: '/booking',
        name: 'Anmeldungen',
        component: () => import('../views/bookingForm/BookingFormTemplates.vue'),
    },
    {
        path: '/booking/:id',
        name: 'Anmeldungsformular',
        component: () => import('../views/bookingForm/BookingFormTemplateView.vue'),
    },
    {
        path: '/booking/onlineFormBuilder/new/:templateId',
        name: 'Online-Formbuilder',
        component: () => import('../views/OnlineFormBuilder.vue'),
    },
    {
        path: '/booking/onlineFormBuilder/:templateId/:id',
        name: 'Online-Formbuilder',
        component: () => import('../views/OnlineFormBuilder.vue'),
    },
    {
        path: '/booking/onlineFormBuilderPreview/:formId',
        name: 'Online-Formular Vorschau',
        component: () => import('../views/bookingForm/BookingFormBuilderPreview.vue'),
    },
    {
        path: '/booking/printedFormBuilder/new/:templateId',
        name: 'Formular erstellen',
        component: () => import('../views/bookingForm/PrintedFormBuilder.vue'),
    },
    {
        path: '/booking/printedFormBuilder/:templateId/:id',
        name: 'Formular bearbeiten',
        component: () => import('../views/bookingForm/PrintedFormBuilder.vue'),
    },
    {
        path: '/trainings',
        name: 'Schulungen',
        component: () => import('../views/Trainings.vue'),
    },
    {
        path: '/signup/:id',
        name: 'Registrierung abschließen',
        component: () => import ('../views/Signup.vue'),
        meta: {
            guestOnly: true,
        },
    },
    {
        path: '/handout-event-confirmation/:token',
        name: 'Terminbestätigung für Austeilaktion',
        component: () => import ('../views/HandoutEventConfirmation.vue'),
        meta: {
            forAll: true,
        },
    },
    {
        path: '*',
        name: '404',
        component: () => import('../views/404.vue'),
        meta: {
            forAll: true,
        },
    },
];

const router = new VueRouter({
    routes,
});

router.beforeEach(async (to, from, next) => {
    let triggerLoading = true;
    setTimeout(() => {
        if (triggerLoading) {
            store.commit('setLoadingState', true);
            triggerLoading = false;
        }
    }, 200);
    if (to.meta?.forAll) {
        next();
    } else if (to.meta?.guestOnly && store.state.axios) {
        if (store.state.routeCache) {
            router.push(store.state.routeCache.path).catch();
            store.commit('setRouteCache', null);
        } else {
            router.push('/').catch();
        }
    } else if (!to.meta || !to.meta.guestOnly && !store.state.axios) {
        try {
            let resp: CheckLoginResponseDto | undefined;
            try {
                resp = await LoginApi.checkLogin();
            } catch (err) {
                store.commit('setRouteCache', to);
            }
            if (resp) {
                store.commit('login');
                store.commit('user', resp.meta);
                store.commit('setUserType', resp.loginType);
                store.commit('setOriginUserId', resp.originUserId);
                await LoginApi.setCrud();
                const cam = store.state.crudAccessManager;
                if (cam.canAccessType(CrudEntityTypes.CONFIG, CrudActionTypes.READ)) {
                    const dhlApiConfig = await ConfigHandlerAPI.getDhlApiConfig();
                    store.commit('setDhlApiConfig', dhlApiConfig);
                } else {
                    store.commit('setDhlApiConfig', {
                        useTrackingApi: false,
                        useDhlApi: false,
                        useRetoureApi: false,
                    });
                }
                store.commit('setAppReady', true);
                next();
            } else {
                next('/login');
            }
        } catch (error) {
            next('/login');
        }
    } else {
        store.commit('setTitle', to.name);
        store.commit('hideActions');
        if (store.state.axios) {
            await LoginApi.setCrud();
            store.commit('setAppReady', true);
        }
        next();
    }
    triggerLoading = false;
    store.commit('setLoadingState', false);
});

export default router;
