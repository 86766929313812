
import Vue from 'vue';
import { FindInstitutionFilter } from '@/classes/dto/institutioner/request/filters/FindInstitution.filter';
import { RuleFactory } from '@/helpers/ruleFactory.helper';
import { InstitutionTypesVue } from '@/enums/InstitutionTypes.enum';
import { InstitutionStatusVue } from '@/enums/InstitutionStatus.enum';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    filter: {
      type: Object as () => FindInstitutionFilter,
      default: () => ({
        isActive: true,
      } as FindInstitutionFilter),
    },
  },
  data: () => ({
    filterStates: {} as Record<keyof FindInstitutionFilter, boolean>,
    innerFilter: {} as FindInstitutionFilter,
    isActiveItems: [{
      text: 'Nur aktive Standorte anzeigen',
      value: true,
    }, {
      text: 'Nur inaktive Standorte anzeigen',
      value: false,
    }],
    institutionTypes: InstitutionTypesVue,
    institutionStatus: InstitutionStatusVue,
  }),
  computed: {
    RuleFactory: () => RuleFactory,
  },
  methods: {
    initializeFilterStates() {
      this.$set(this, 'filterStates', {});
      this.$nextTick(() => {
        this.$set(this, 'innerFilter', {...this.filter});
        for (const key in this.filter) {
          if (this.filter.hasOwnProperty(key)) {
            this.$set(this.filterStates, key, typeof (this.filter as Record<string, any>)[key] !== 'undefined');
          }
        }
      });
    },
    // updateFilter() {
    //   const form = this.$refs.form as Form;
    //   if (form) {
    //     form.resetValidation();
    //   }
    //   for (const key in this.filterStates) {
    //     if (!this.filterStates.hasOwnProperty(key) || !(this.filterStates as Record<string, boolean>)[key]) {
    //       this.$delete(this.innerFilter, key);
    //     }
    //   }
    // },
    applyFilter() {
        this.$emit('update:filter', {...this.innerFilter});
    },
  },
  watch: {
    // filterStates: {
    //   deep: true,
    //   handler() {
    //     this.updateFilter();
    //   },
    // },
    innerFilter: {
      deep: true,
      handler() {
        this.applyFilter();
      },
    },
  },
  mounted() {
    this.initializeFilterStates();
  },
});
