
import Vue from 'vue';
import {sleep} from '@/helpers/sleep.helper';
import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {CrudEntityTypesVue} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {Form} from '@/interfaces/Form.interface';
import PasswordDialog from '@/components/common/passwordDialog.vue';
import {PermissionerApi} from '@/classes/api/permissioner.api.class';
import {CrudActionTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes';
import {UserLoginHandlerApi} from '@/classes/api/user-login-handler.api';
import CrudAccessManager from '@/classes/clientOnly/permissionTreeResources/classes/CrudAccessManager';
import mongoose from "mongoose";

export default Vue.extend({
  components: {PasswordDialog},
  data: () => ({
    userItemSearchInput: '',
    userItems: [] as Array<{ text: string; value: mongoose.Types.ObjectId }>,
    userId: null as mongoose.Types.ObjectId | null,
    loadingUserItems: false,
    throttleCounter: 0,
    readSelection: [] as string[],
    writeSelection: [] as string[],
    grantFullReadAccess: false,
    grantFullWriteAccess: false,
    showPasswordDialog: false,
    valid: false,
    loading: false,
    userSelectionReady: false,
    userAccessUnavailable: false,
  }),
  computed: {
    RuleFactory: () => RuleFactory,
    title(): string {
      return this.$store.state.shareAccessDialogInfo.dialogTitle || 'Entität freigeben';
    },
    userSearchNoData(): string {
      if (this.loadingUserItems) {
        return 'Suche...';
      } else {
        return 'Kein Benutzer gefunden';
      }
    },
    fieldDictionary(): FieldDictionary<any> {
      return CrudEntityTypesVue.find((type) =>
          type.value === this.$store.state.shareAccessDialogInfo.entityType,
      )?.fields ?? [];
    },
    readItems(): FieldDictionary<any> {
      return this.fieldDictionary.filter((item) => !item.updateOnly);
    },
    writeItems(): FieldDictionary<any> {
      return this.fieldDictionary.filter((item) => !item.readOnly);
    },
  },
  methods: {
    async findUserItems() {
      if (!this.userItemSearchInput) {
        return;
      }
      this.loadingUserItems = true;
      this.throttleCounter++;
      await sleep(500);
      this.throttleCounter--;
      if (this.throttleCounter === 0) {
        try {
          if (this.userItemSearchInput) {
            const resp = await UserLoginHandlerApi.find({
              filter: {$search: this.userItemSearchInput},
              populate: {
                meta: {
                  fields: ['firstName', 'lastName', 'customId'],
                },
              },
            });
            this.userItems = resp.loginUsers.map((loginUser) => ({
              text: `${(loginUser.meta as any).firstName} ${(loginUser.meta as any).lastName} - ${(loginUser.meta as any).customId}`,
              value: loginUser._id as unknown as mongoose.Types.ObjectId,
            }));
          }
        } finally {
          this.loadingUserItems = false;
        }
      }
    },
    preSave() {
      const form = this.$refs.form as Form;
      if (form.validate()) {
        this.showPasswordDialog = true;
      }
    },
    async save(_pass: string) {
      const form = this.$refs.form as Form;
      if (form.validate()) {
        this.loading = true;
        try {
          const loginUser = await UserLoginHandlerApi.findById(this.userId as mongoose.Types.ObjectId);
          await PermissionerApi.grantAccess('user', loginUser._id, {
            entityId: this.$store.state.shareAccessDialogInfo.id,
            entityType: this.$store.state.shareAccessDialogInfo.entityType,
            action: CrudActionTypes.READ,
            fields: this.grantFullReadAccess ? ['fullAccess'] : this.readSelection.filter((el) => el !== '_id'),
            _pass,
          });
          await PermissionerApi.grantAccess('user', loginUser._id, {
            entityId: this.$store.state.shareAccessDialogInfo.id,
            entityType: this.$store.state.shareAccessDialogInfo.entityType,
            action: CrudActionTypes.UPDATE,
            fields: this.grantFullWriteAccess ? ['fullAccess'] : this.writeSelection.filter((el) => el !== '_id'),
            _pass,
          });
          this.reset();
          this.$$showSnackbar({
            text: 'Freigabe erfolgreich',
            btnColor: 'info',
          });
        } finally {
          this.loading = false;
        }
      }
    },
    async getUserAccess() {
      if (this.userId) {
        try {
          const user = await UserLoginHandlerApi.findByMeta(this.userId);
          const crud = await PermissionerApi.getCrudById('user', user._id);
          const crudAccessManager = new CrudAccessManager(crud);
          const entity = crudAccessManager.entity(this.$store.state.shareAccessDialogInfo.entityType, this.$store.state.shareAccessDialogInfo.id);
          if (entity.hasFullReadAccess) {
            this.grantFullReadAccess = true;
          } else {
            for (const key in entity.readFields) {
              if (entity.readFields.hasOwnProperty(key) && entity.readFields[key]) {
                this.readSelection.push(key);
              }
            }
          }
          if (entity.hasFullUpdateAccess) {
            this.grantFullWriteAccess = true;
          } else {
            for (const key in entity.updateFields) {
              if (entity.updateFields.hasOwnProperty(key) && entity.updateFields[key]) {
                this.writeSelection.push(key);
              }
            }
          }
        } catch (e) {
          this.userAccessUnavailable = true;
        } finally {
          this.userSelectionReady = true;
        }
      }
    },
    reset() {
      const form = this.$refs.form as Form;
      form.reset();
      this.$$shareAccessDialog.hide();
    },
  },
  watch: {
    userItemSearchInput() {
      this.findUserItems();
    },
    grantFullReadAccess() {
      this.readSelection = [];
    },
    grantFullWriteAccess() {
      this.writeSelection = [];
    },
  },
});
