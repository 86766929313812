
import Vue from 'vue';
import {Stack} from '@/interfaces/stack.interface';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';
import {StackBus} from '@/busses/StackBus';
import {Types} from 'mongoose';
import OverflowText from '@/components/common/overflow-text.vue';
import CreateStackDialog from '@/components/stack/createStackDialog.vue';
import AddElementToStackButton from '@/components/stack/stackDialog/stacksList/AddElementToStackButton.vue';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import {CrudActionTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes';
import StacksListMenu from '@/components/stack/stackDialog/stacksList/StacksListMenu.vue';

export default Vue.extend({
  components: {StacksListMenu, AddElementToStackButton, CreateStackDialog, OverflowText},
  props: {
    stackId: {
      type: Types.ObjectId,
      required: true,
    },
    showClosedStacks: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    showCreateStackDialog: false,
    initializing: false,
    stacks: [] as Stack[],
  }),
  computed: {
    maxHeightCss(): string {
      if (this.$$crudAccessManager.getAccess(CrudEntityTypes.STACK, CrudActionTypes.CREATE)) {
        return 'calc(100% - 114px)';
      } else {
        return 'calc(100% - 57px)';
      }
    },
    activeStackIndex: {
      get(): number {
        return this.stacks.findIndex((s) => s._id === this.stackId);
      },
      set(v: number) {
        if (v >= 0) {
          this.$emit('update:stackId', this.stacks[v]._id);
        } else {
          this.$emit('update:stackId', null);
        }
      },
    },
  },
  methods: {
    async getStacks() {
      if (!this.$$crudAccessManager.canAccessType(CrudEntityTypes.STACK, CrudActionTypes.READ)) {
        return;
      }
      this.initializing = true;
      try {
        const resp = await StackManagerApi.find({
          skipPagination: true,
          filter: this.showClosedStacks ? undefined : { isClosed: false },
          fields: ['name', 'color', 'closedAt'],
        });
        this.stacks = resp.stacks;
        if (this.stackId) {
          const found = this.stacks.find((stack) => stack._id === this.stackId);
          if (!found) {
            this.$emit('update:stackId', null);
          }
        }
      } catch (e) {
        this.$$showSnackbar(`Fehler beim Laden der Ablagestapel`);
      } finally {
        this.initializing = false;
      }
    },
  },
  watch: {
    showClosedStacks() {
      this.getStacks();
    },
  },
  mounted() {
    StackBus.$on('reload:stacks', this.getStacks);
    StackBus.$on('deleted:stack', this.getStacks);
    this.getStacks();
  },
});
