
import Vue from 'vue';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {FindEmployeeFilter} from '@/classes/dto/employee-handler/request/filters/FindEmployee.filter';
import EmployeeAutocomplete from '@/components/common/search-fields/employeeAutocomplete.vue';
import SubjectAutocomplete from '@/components/common/search-fields/subjectAutocomplete.vue';
import InstitutionAutocomplete from '@/components/common/search-fields/institutionAutocomplete.vue';
import FilterDialogExpansionPanelHeader
  from '@/components/common/filterDialogComponents/filterDialogExpansionPanelHeader.vue';

export default Vue.extend({
  components: {FilterDialogExpansionPanelHeader, InstitutionAutocomplete, SubjectAutocomplete, EmployeeAutocomplete},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    filter: {
      type: Object as () => FindEmployeeFilter,
      default: () => ({
        isTeacher: true,
        isActive: true,
      } as FindEmployeeFilter),
    },
  },
  data: () => ({
    filterStates: {
      isTeacher: true,
      isActive: true,
    } as Record<keyof FindEmployeeFilter, boolean>,
    innerFilter: {} as FindEmployeeFilter,
    panelValue: null as number | null,
    isActiveItems: [{
      text: 'Nur aktive Lehrkräfte anzeigen',
      value: true,
    }, {
      text: 'Nur inaktive Lehrkräfte anzeigen',
      value: false,
    }],
    hasDriverLicenseItems: [{
      text: 'Nur Lehrkräfte mit Führerschein anzeigen',
      value: true,
    }, {
      text: 'Nur Lehrkräfte ohne Führerschein anzeigen',
      value: false,
    }],
    hasCarItems: [{
      text: 'Nur Lehrkräfte mit Fahrzeug anzeigen',
      value: true,
    }, {
      text: 'Nur Lehrkräfte ohne Fahrzeug anzeigen',
      value: false,
    }],
    hasLoginItems: [{
      text: 'Nur Lehrkräfte mit Zugang zu Vanilla anzeigen',
      value: true,
    }, {
      text: 'Nur Lehrkräfte ohne Zugang zu Vanilla anzeigen',
      value: false,
    }],
  }),
  computed: {
    RuleFactory: () => RuleFactory,
    numberOfMobilityFilters(): number {
      let n = 0;
      if (this.filterStates.hasDriverLicense) {
        n++;
      }
      if (this.filterStates.hasCar) {
        n++;
      }
      return n;
    },
    numberOfInstitutionPlanningFilters(): number {
      let n = 0;
      if (this.filterStates.assignedInstitutions) {
        n++;
      }
      if (this.filterStates.plannedInstitutions) {
        n++;
      }
      return n;
    },
    numberOfOtherFilters(): number {
      let n = 0;
      if (this.filterStates.acquiredBy) {
        n++;
      }
      if (this.filterStates.hasLogin) {
        n++;
      }
      return n;
    },
  },
  methods: {
    initializeFilterStates() {
      this.$set(this, 'filterStates', {});
      this.$nextTick(() => {
        this.$set(this, 'innerFilter', {...this.filter});
        for (const key in this.filter) {
          if (this.filter.hasOwnProperty(key)) {
            this.$set(this.filterStates, key, typeof (this.filter as Record<string, any>)[key] !== 'undefined');
          }
        }
      });
    },
    // updateFilter() {
    //   const form = this.$refs.form as Form;
    //   if (form) {
    //     form.resetValidation();
    //   }
    //   for (const key in this.filterStates) {
    //     if (!this.filterStates.hasOwnProperty(key) || !(this.filterStates as Record<string, boolean>)[key]) {
    //       this.$delete(this.innerFilter, key);
    //     }
    //   }
    // },
    applyFilter() {
        this.$emit('update:filter', {...this.innerFilter});
    },
  },
  watch: {
    // filterStates: {
    //   deep: true,
    //   handler() {
    //     this.updateFilter();
    //   },
    // },
    innerFilter: {
      deep: true,
      handler() {
        this.applyFilter();
      },
    },
  },
  mounted() {
    this.initializeFilterStates();
  },
});
