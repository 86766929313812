
import Vue from 'vue';
import { LeanDocument, Types } from 'mongoose';
import { Institution } from '@/interfaces/institution.interface';
import { GetDirectionResponseDto } from '@/classes/dto/geocoder/response/GetDirections.response.dto';
import { Employee } from '@/interfaces/employee.interface';
import { getGoogleTravelModesIcon, getTravelModeReadable } from '@/classes/api/google/GoogleTravelModes.enum';
import InstitutionCardDialog from '@/components/common/profile/institutionCardDialog.vue';
import EmployeeCardDialog from '@/components/common/profile/employeeCardDialog.vue';

export default Vue.extend({
  components: {EmployeeCardDialog, InstitutionCardDialog},
  props: {
    value: {
      type: Boolean,
    },
    targets: {
      type: Array as () => Array<LeanDocument<Institution | Employee> & GetDirectionResponseDto>,
    },
  },
  data: () => ({
    activeEmployeeId: null as null | Types.ObjectId,
    activeInstitutionId: null as null | Types.ObjectId,
    showInstitutionCardDialog: false,
    showEmployeeCardDialog: false,
  }),
  computed: {
    getGoogleTravelModesIcon: () => getGoogleTravelModesIcon,
    getTravelModeReadable: () => getTravelModeReadable,
  },
  methods: {
    isEmployee(item: LeanDocument<Institution | Employee> & GetDirectionResponseDto): item is LeanDocument<Employee> & GetDirectionResponseDto {
      return item.hasOwnProperty('firstName') || item.hasOwnProperty('lastName');
    },
    isInstitution(item: LeanDocument<Institution | Employee> & GetDirectionResponseDto): item is LeanDocument<Institution> & GetDirectionResponseDto {
      return item.hasOwnProperty('name');
    },
    getDurationReadable(seconds: number) {
      const hours = Math.floor(seconds / 3600);
      let remaining = seconds % 3600;
      const minutes = Math.floor(remaining / 60);
      remaining = remaining % 3600;
      if (hours > 0) {
        if (minutes > 0) {
          return `${hours} ${hours === 1 ? 'Stunde' : 'Stunden'} und ${minutes} ${minutes === 1 ? 'Minute' : 'Minuten'}`;
        } else {
          return `${hours} ${hours === 1 ? 'Stunde' : 'Stunden'}`;
        }
      } else {
        if (minutes > 0) {
          return `${minutes} ${minutes === 1 ? 'Minute' : 'Minuten'}`;
        } else {
          return 'Weniger als eine Minute';
        }
      }
    },
  },
});
