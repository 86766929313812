
import Vue from 'vue';

export default Vue.extend({
  props: {
    color: { type: String, required: true },
    outputValue: { type: String, required: true },
    value: { type: String, required: true },
    iconColor: { type: String, default: 'white' },
  },
  methods: {
    emitInput() {
      this.$emit('input', this.outputValue);
    },
  },
});
