import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {ParentFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/ParentFields';

export const parentFieldsDictionary: FieldDictionary<ParentFields> = [
    {
        text: 'Geschlecht',
        value: ParentFields.GENDER,
    },
    {
        text: 'Vorname',
        value: ParentFields.FIRST_NAME,
    },
    {
        text: 'Nachname',
        value: ParentFields.LAST_NAME,
    },
    {
        text: 'Geburtsdatum',
        value: ParentFields.BIRTHDAY,
    },
    {
        text: 'Anschrift',
        value: ParentFields.ADDRESS,
    },
    {
        text: 'Zahlungsinformationen',
        value: ParentFields.PAYMENT,
    },
    {
        text: 'Zugang zu Vanilla',
        value: ParentFields.HAS_LOGIN,
    },
    {
        text: 'Aktivitätsstatus',
        value: ParentFields.IS_ACTIVE,
    },
    {
        text: 'Datum der Deaktivierung',
        value: ParentFields.DEACTIVATED_AT,
    },
    {
        text: 'Kontaktdaten',
        value: ParentFields.CONTACT_INFORMATION,
    },
    {
        text: 'Kundennummer',
        value: ParentFields.CUSTOM_ID,
        readOnly: true,
    },
    {
        text: 'Art der Kundennummer',
        value: ParentFields.CUSTOM_ID_SCHEME,
        readOnly: true,
    },
    {
        text: 'Referenz zur Buchhaltungsschnittstelle',
        value: ParentFields.ACCOUNTING_REF,
        readOnly: true,
    },
    {
        text: 'Tags',
        value: ParentFields.TAGS,
    },
    {
        text: 'Datum der Erzeugung',
        value: ParentFields.CREATED_AT,
        readOnly: true,
    },
    {
        text: 'Datum der letzten Aktualisierung',
        value: ParentFields.UPDATED_AT,
        readOnly: true,
    },
];
