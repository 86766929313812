
import Vue from 'vue';
import { ContactInformation } from '@/interfaces/contactInformation.interface';
import { RuleFactory } from '@/helpers/ruleFactory.helper';
import { compareForUpdate, UpdateObject } from '@/helpers/compareForUpdate';

export default Vue.extend({
  props: {
    isBusinessContact: {
      type: Boolean,
      default: false,
    },
    contactInformation: {
      type: Object as () => ContactInformation,
      required: true,
    },
  },
  data: () => ({
    fax1: undefined as string | undefined,
    fax2: undefined as string | undefined,
    mail1: undefined as string | undefined,
    mail2: undefined as string | undefined,
    phone1: undefined as string | undefined,
    phone2: undefined as string | undefined,
    mobile1: undefined as string | undefined,
    mobile2: undefined as string | undefined,
    website: undefined as string | undefined,
    mail1Note: undefined as string | undefined,
    mail2Note: undefined as string | undefined,
    phone1Note: undefined as string | undefined,
    phone2Note: undefined as string | undefined,
    mobile1Note: undefined as string | undefined,
    mobile2Note: undefined as string | undefined,
  }),
  computed: {
    RuleFactory: () => RuleFactory,
    phoneMask: () => ({
      mask: '+#### #### #### #### #### ##',
      tokens: {
        '+': {
          pattern: /[0-9]|\+/,
        },
        '#': {
          pattern: /[0-9]/,
        },
      },
    }),
    computedContactInformation(): UpdateObject<ContactInformation> {
      const updateObj = {} as UpdateObject<ContactInformation>;
      updateObj.fax1 = compareForUpdate(this.fax1 || undefined, this.contactInformation.fax1);
      updateObj.fax2 = compareForUpdate(this.fax2 || undefined, this.contactInformation.fax2);
      updateObj.mail1 = compareForUpdate(this.mail1 || undefined, this.contactInformation.mail1);
      updateObj.mail2 = compareForUpdate(this.mail2 || undefined, this.contactInformation.mail2);
      updateObj.phone1 = compareForUpdate(this.phone1 || undefined, this.contactInformation.phone1);
      updateObj.phone2 = compareForUpdate(this.phone2 || undefined, this.contactInformation.phone2);
      updateObj.mobile1 = compareForUpdate(this.mobile1 || undefined, this.contactInformation.mobile1);
      updateObj.mobile2 = compareForUpdate(this.mobile2 || undefined, this.contactInformation.mobile2);
      updateObj.website = compareForUpdate(this.website || undefined, this.contactInformation.website);
      updateObj.notes = {
        mail1: compareForUpdate(this.mail1Note, this.contactInformation.notes?.mail1),
        mail2: compareForUpdate(this.mail2Note, this.contactInformation.notes?.mail2),
        mobile1: compareForUpdate(this.mobile1Note, this.contactInformation.notes?.mobile1),
        mobile2: compareForUpdate(this.mobile2Note, this.contactInformation.notes?.mobile2),
        phone1: compareForUpdate(this.phone1Note, this.contactInformation.notes?.phone1),
        phone2: compareForUpdate(this.phone2Note, this.contactInformation.notes?.phone2),
      } as any;
      return updateObj;
    },
  },
  methods: {
    copy() {
      this.fax1 = this.contactInformation.fax1;
      this.fax2 = this.contactInformation.fax2;
      this.mail1 = this.contactInformation.mail1;
      this.mail2 = this.contactInformation.mail2;
      this.phone1 = this.contactInformation.phone1;
      this.phone2 = this.contactInformation.phone2;
      this.mobile1 = this.contactInformation.mobile1;
      this.mobile2 = this.contactInformation.mobile2;
      this.website = this.contactInformation.website;
      this.mail1Note = this.contactInformation.notes?.mail1;
      this.mail2Note = this.contactInformation.notes?.mail2;
      this.mobile1Note = this.contactInformation.notes?.mobile1;
      this.mobile2Note = this.contactInformation.notes?.mobile2;
      this.phone1Note = this.contactInformation.notes?.phone1;
      this.phone2Note = this.contactInformation.notes?.phone2;
      this.$emit('input', this.computedContactInformation);
    },
  },
  watch: {
    contactInformation: {
      immediate: true,
      deep: true,
      handler() {
        if (this.contactInformation) {
          this.copy();
        }
      },
    },
  },
});
