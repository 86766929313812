
import Vue from 'vue';

export default Vue.extend({
  props: {
    text: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: Number,
      required: false,
    },
    tooltipMaxWidth: {
      type: Number,
      default: 300,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isEllipsisActive: false,
  }),
  methods: {
    checkEllipsis() {
      const container = this.$refs.container as HTMLElement;
      if (container) {
        this.isEllipsisActive = container.offsetWidth < container.scrollWidth;
      } else {
        this.isEllipsisActive = false;
      }
    },
  },
  watch: {
    text() {
      this.$nextTick(() => {
        this.checkEllipsis();
      });
    },
  },
  async mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.checkEllipsis();
        window.addEventListener('resize', this.checkEllipsis);
      });
    }, 500);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkEllipsis);
  },
});
