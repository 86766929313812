
import Vue from 'vue';
import {Place} from '@/interfaces/place.interface';

interface CanHaveAddress {
  address?: Place;
}

export default Vue.extend({
  props: {
    entity: {
      type: Object as () => CanHaveAddress,
      required: true,
    },
  },
})
