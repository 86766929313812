
import Vue from 'vue';
import {EmployeeHandlerAPI} from '@/classes/api/employee-handler.api.class';
import {Employee} from '@/interfaces/employee.interface';
import AssignEmployeesDialog from '@/components/institutions/institutionView/profileView/assignEmployeesDialog.vue';
import EmployeeCardDialog from '@/components/common/profile/employeeCardDialog.vue';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import {EmployeeFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/EmployeeFields';
import mongoose from "mongoose";

export default Vue.extend({
  components: {EmployeeCardDialog, AssignEmployeesDialog},
  props: {
    institutionId: {
      type: Object as () => mongoose.Types.ObjectId,
      required: true,
    },
  },
  data: () => ({
    assignedEmployees: [] as Array<Pick<Employee, '_id' | 'firstName' | 'lastName'>>,
    activeEmployeeId: null as null | mongoose.Types.ObjectId,
    showEmployeeCardDialog: false,
    showAssignEmployeesDialog: false,
    expandListGroup: false,
    loading: false,
  }),
  methods: {
    async getAssignedEmployees() {
      if (!this.$$crudAccessManager.canReadAllProvided(CrudEntityTypes.EMPLOYEE, EmployeeFields.ASSIGNED_INSTITUTIONS)) {
        return;
      }
      try {
        this.loading = true;
        const employeeResp = await EmployeeHandlerAPI.find({
          skipPagination: true,
          filter: {
            isActive: true,
            assignedInstitutions: [this.institutionId],
          },
          fields: ['firstName', 'lastName'],
        });
        this.assignedEmployees = employeeResp.employees;
      } catch (e) {
        this.$$showSnackbar('Es ist ein Fehler beim Laden der zugeordneten Lehrkräfte aufgetreten.', 'error');
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getAssignedEmployees();
  },
});
