export enum GoogleTravelModesEnum {
  DRIVING = 'driving',
  WALKING = 'walking',
  BICYCLING = 'bicycling',
  TRANSIT = 'transit',
}

export function getGoogleTravelModesIcon(mode: GoogleTravelModesEnum) {
  switch (mode) {
    case GoogleTravelModesEnum.DRIVING:
      return 'mdi-car';
    case GoogleTravelModesEnum.WALKING:
      return 'mdi-walk';
    case GoogleTravelModesEnum.BICYCLING:
      return 'mdi-bicycle';
    case GoogleTravelModesEnum.TRANSIT:
      return 'mdi-bus';
    default:
      throw new Error(`Unknown GoogleTravelModesEnum: ${mode}`);
  }
}

export function getTravelModeReadable(mode: GoogleTravelModesEnum) {
  switch (mode) {
    case GoogleTravelModesEnum.DRIVING:
      return 'mit dem Auto';
    case GoogleTravelModesEnum.WALKING:
      return 'zu Fuß';
    case GoogleTravelModesEnum.BICYCLING:
      return 'mit dem Fahrrad';
    case GoogleTravelModesEnum.TRANSIT:
      return 'mit öffentlichen Verkehrsmitteln';
  }
}
