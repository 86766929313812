
import Vue from 'vue';
import { Employee } from '@/interfaces/employee.interface';
import InstitutionAutocomplete from '@/components/common/search-fields/institutionAutocomplete.vue';
import { Types } from 'mongoose';
import { EmployeeHandlerAPI } from '@/classes/api/employee-handler.api.class';
import { EventBus } from '@/busses/EventBus';
import { FindInstitutionFilter } from '@/classes/dto/institutioner/request/filters/FindInstitution.filter';
import { CrudEntityTypes } from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import { CrudActionTypes } from '@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes';


export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    employee: {
      type: Object as () => Employee,
      required: true,
    },
  },
  components: {
    InstitutionAutocomplete,
  },
  data: () => ({
    loading: false,
    addInstitution: null as null | Types.ObjectId,
    removeLoading: false,
    institutionPrefetch: false,
    institutionPrefetchFilter: {} as FindInstitutionFilter,
  }),
  methods: {
    async addAssignment() {
      try {
        if (this.addInstitution) {
          this.loading = true;
          await EmployeeHandlerAPI.addAssignment(this.employee._id, {
            type: 'assign',
            institutionId: this.addInstitution,
          });
          this.$$showSnackbar(`Die Zuteilung des Standorts war erfolgreich.`, 'success');
          this.$emit('update:employee');
          EventBus.$emit('update:employee');
        }
      } catch (e) {
        this.$$showSnackbar('Es ist ein Fehler bei der Zuweisung des Standorts aufgetreten. Bitte erneut versuchen.', 'error');
      } finally {
        this.loading = false;
        this.reset();
      }
    },
    async removeAssignment(id: Types.ObjectId) {
      try {
        this.removeLoading = false;
        await EmployeeHandlerAPI.removeAssignment(this.employee._id, {
          type: 'assign',
          institutionId: id,
        });
        this.$$showSnackbar('Die Zuweisung wurde erfolgreich aufgehoben.', 'success');
        this.$emit('update:employee');
        EventBus.$emit('update:employee');
      } catch (e) {
        this.$$showSnackbar('Es ist ein Fehler beim Aufheben der Zuweisung ausgetreten. Bitte erneut versuchen.', 'error');
      } finally {
        this.removeLoading = false;
      }
    },
    reset() {
      this.addInstitution = null;
      this.$emit('input', false);
    },
    async getInstitutionPrefetchFilter() {
      try {
        if (!this.employee.address || !this.employee.address.city) {
          this.employee.address = (await EmployeeHandlerAPI.findById(this.employee._id, {
            fields: ['address'],
          })).address;
        }
        if (this.employee.address.location.coordinates.length === 2 && this.employee.address.location.coordinates[0] !== 0 && this.employee.address.location.coordinates[1] !== 0) {
          this.institutionPrefetchFilter = {
            isActive: true,
            near: {
              lat: this.employee.address.location.coordinates[1],
              lon: this.employee.address.location.coordinates[0],
              maxDistance: 20000,
            },
          } as FindInstitutionFilter;
          this.institutionPrefetch = true;
        }
      } catch (e) {
        this.$$showSnackbar('Beim Laden der Daten ist ein Fehler aufgetreten', 'error');
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        const canReadEmployeeAddress = this.$$crudAccessManager.canAccessEntity(CrudEntityTypes.EMPLOYEE, CrudActionTypes.READ, this.employee._id, 'address');
        if (canReadEmployeeAddress) {
          this.getInstitutionPrefetchFilter();
        }
      },
    },
  },
});
