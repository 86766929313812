export function toCurrencyString(val: number, fractionDigits?: number) {
  if (!val) {
    val = 0;
  }
  return val.toLocaleString('de-DE', {
    currency: 'EUR',
    style: 'currency',
    minimumFractionDigits: fractionDigits || 2,
    maximumFractionDigits: fractionDigits || 2,
  });
}
