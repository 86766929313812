
import { GenderTypes } from '@/enums/GenderTypes.enum';
import Vue from 'vue';
import { Form } from '@/interfaces/Form.interface';

import DatePicker from '@/components/common/datePicker.vue';
import JobTypeAutocomplete from '@/components/common/search-fields/jobTypeAutocomplete.vue';
import { Types } from 'mongoose';
import { ResponsibleHandlerAPI } from '@/classes/api/responsible-handler.api.class';
import EditPersonalInformation from '@/components/common/edit/editPersonalInformation.vue';
import { Responsible } from '@/interfaces/responsible.interface';
import { CrudEntityTypes } from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import EditContactInformation from '@/components/common/edit/editContactInformation.vue';
import { isJobType, JobType } from '@/interfaces/jobType.interface';
import { ContactInformation } from '@/interfaces/contactInformation.interface';
import { compareForUpdate, UpdateObject, UpdateType } from '@/helpers/compareForUpdate';
import { UpdateResponsibleRequestDTO } from '@/classes/dto/responsible-handler/request/UpdateResponsible.request.dto';
import { isObjectId } from '@/helpers/isObjectId.helper';
import { EventBus } from '@/busses/EventBus';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    responsibleId: {
      type: Types.ObjectId,
      required: true,
    },
  },
  components: {
    EditPersonalInformation,
    JobTypeAutocomplete,
    DatePicker,
    EditContactInformation,
  },
  data: () => ({
    valid: true,
    responsible: null as null | Responsible,
    loading: false,
    globalLoading: false,
    // responsible props
    // hasLogin: false, @ToDo login implementation
    // schoolType: undefined, @ToDo remove schoolType
    firstName: undefined as UpdateType<string>,
    lastName: undefined as UpdateType<string>,
    jobType: undefined as undefined | Types.ObjectId | JobType,
    birthday: undefined as undefined | Date,
    contactInformation: undefined as UpdateType<ContactInformation>,
    gender: undefined as UpdateType<GenderTypes>,
    updateObj: {} as any,
  }),
  computed: {
    CrudEntityTypes: () => CrudEntityTypes,
  },
  methods: {
    async getResponsible() {
      try {
        this.globalLoading = true;
        this.responsible = await ResponsibleHandlerAPI.findById(this.responsibleId);
        // @ToDo hasLogin not implemented yet
        // this.hasLogin = this.responsible.hasLogin;
        this.jobType = isObjectId(this.responsible.jobType) ? this.responsible.jobType : isJobType(this.responsible.jobType) ? this.responsible.jobType._id : undefined;
        this.birthday = this.responsible.birthday;
      } catch (e) {
        this.reset();
      } finally {
        this.globalLoading = false;
      }
    },
    async updateResponsible() {
      const form = this.$refs.form as Form;
      if (form && form.validate()) {
        try {
          this.loading = true;
          const updateObj: UpdateObject<Responsible> = {};
          updateObj.firstName = this.firstName;
          updateObj.lastName = this.lastName;
          updateObj.jobType = compareForUpdate(this.jobType, this.responsible?.jobType?._id);
          updateObj.birthday = compareForUpdate(this.birthday, this.responsible?.birthday);
          updateObj.contactInformation = this.contactInformation;
          updateObj.gender = this.gender;
          this.updateObj = updateObj;
          await ResponsibleHandlerAPI.update(this.responsibleId, updateObj as unknown as UpdateResponsibleRequestDTO);
          this.$$showSnackbar(`Ansprechpartner:in wurde erfolgreich aktualisiert`, 'success');
          this.$emit('update:institution');
          EventBus.$emit('update:responsible');
          this.reset();
        } catch (e) {
          this.$$showSnackbar(`Bei der Aktualisierung des Standorts ist ein fehler aufgetreten`, 'error');
        } finally {
          this.loading = false;
        }
      }
    },
    reset() {
      this.responsible = null;
      const form = this.$refs.form as Form;
      if (form) {
        form.reset();
      }
      this.$emit('input', false);
    },
  },
  mounted() {
    this.getResponsible();
  },
  watch: {
    responsibleId: {
      immediate: true,
      handler() {
        this.getResponsible();
      },
    },
  },
});
