

import Vue from 'vue';
import {GenderTypes, SalutationTypesVue} from '@/enums/GenderTypes.enum';
import {RuleFactory} from '@/helpers/ruleFactory.helper';

export default Vue.extend({
    props: {
        gender: {
            type: Object as () => GenderTypes,
            required: true,
        },
        firstName: {
            type: String,
            required: true,
        },
        lastName: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: 'info',
        },
        includeSubheader: {
            type: Boolean,
            default: true,
        },
        subheaderText: {
            type: String,
            default: 'Persönliche Informationen',
        },
        requireFirstName: {
            type: Boolean,
            default: true,
        },
        outlined: {
            type: Boolean,
            default: true,
        },
        rounded: {
            type: Boolean,
            default: true,
        },
        filled: {
            type: Boolean,
            default: false,
        },
        solo: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        SalutationTypesVue: () => SalutationTypesVue,
        RuleFactory: () => RuleFactory,
        GenderTypes: () => GenderTypes,
    },
});

