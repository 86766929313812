
import Vue from 'vue';

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    panelValue: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    filterCount: {
      type: Number,
      required: true,
    },
  },
})
