
import Vue from 'vue';
import {sleep} from '@/helpers/sleep.helper';
import AppBarSearchDialogResultsGrid from '@/components/app/appBar/searchDialog/appBarSearchDialogResultsGrid.vue';
import {FindGlobalResponseDto} from '@/classes/dto/finder/response/FindGlobal.response.dto';
import {FinderAPI} from '@/classes/api/finder.api';

export default Vue.extend({
  components: {AppBarSearchDialogResultsGrid},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    searchInput: '',
    result: undefined as FindGlobalResponseDto | undefined,
    loading: false,

    throttleCounter: 0,
    throttleDelay: 800,

    clearSearchTimeout: undefined as number | undefined,
    bestMatchLimit: 5,
  }),
  computed: {
    highlightBestMatch(): boolean {
      if (this.result) {
        const maxScore = this.result.maxScore;
        let numberOfBestMatches = 0;
        const items = this.result.items as Record<string, FindGlobalResponseDto['items']['students']>
        for (const key in items) {
          if (items.hasOwnProperty(key)) {
            const typeResponse = items[key];
            for (const item of typeResponse.items) {
              if (item.score === maxScore) {
                numberOfBestMatches++;
                if (numberOfBestMatches > this.bestMatchLimit) {
                  return false;
                }
              }
            }
          }
        }
      }
      return true;
    },
  },
  methods: {
    async search() {
      if (!this.searchInput) {
        this.result = undefined;
        return;
      }
      this.loading = true;
      this.throttleCounter++;
      await sleep(this.throttleDelay);
      this.throttleCounter--;
      if (this.throttleCounter === 0) {
        if (!this.searchInput) {
          this.loading = false;
          this.result = undefined;
          return;
        }
        try {
          this.result = await FinderAPI.findGlobal(this.searchInput);
        } catch (e) {
          this.$$showSnackbar('Beim Laden der Suchergebnisse ist ein Fehler aufgetreten', 'error');
        } finally {
          this.loading = false;
        }
      }
    },
    reset() {
      this.$emit('input', false);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v: boolean) {
        if (v) {
          if (this.clearSearchTimeout) {
            clearTimeout(this.clearSearchTimeout);
          }
        } else {
          this.clearSearchTimeout = setTimeout(() => {
            this.searchInput = '';
            this.result = undefined;
          }, 10_000) as unknown as number;
        }
      },
    },
  },
});
