
import Vue from 'vue';
import {Types} from 'mongoose';
import {Stack, StackItem} from '@/interfaces/stack.interface';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';
import {sleep} from '@/helpers/sleep.helper';
import {User} from '@/interfaces/user.interface';
import {Institution} from '@/interfaces/institution.interface';
import {isPopulated} from '@/helpers/isObjectId.helper';
import {StackItemMetaTypes} from '@/enums/StackItemMetaTypes';
import StackItemNoteComponent from '@/components/stack/stackDialog/stackItemContent/stackItemNoteComponent.vue';
import StackItemStateComponent from '@/components/stack/stackDialog/stackItemContent/stackItemStateComponent.vue';
import StackItemMetaInfoComponent from '@/components/stack/stackDialog/stackItemContent/stackItemMetaInfoComponent.vue';
import StackItemDueDateComponent from '@/components/stack/stackDialog/stackItemContent/stackItemDueDateComponent.vue';
import StackItemAssignedToComponent
  from '@/components/stack/stackDialog/stackItemContent/stackItemAssignedToComponent.vue';
import {StackBus} from '@/busses/StackBus';
import moment from 'moment';

moment.locale('de');

export default Vue.extend({
  components: {
    StackItemAssignedToComponent,
    StackItemDueDateComponent, StackItemMetaInfoComponent, StackItemStateComponent, StackItemNoteComponent,
  },
  props: {
    stackId: {type: Types.ObjectId},
    itemId: {type: Types.ObjectId},
  },
  data: () => ({
    initializing: false,
    stack: null as Stack | null,
    item: null as StackItem<any> | null,
  }),
  computed: {
    moment: () => moment,
    maxWidthCss(): string {
      if (!this.stackId || this.$vuetify.breakpoint.smAndDown) {
        return 'calc(100% - 300px)';
      } else {
        return 'calc(100% - 600px)';
      }
    },
    isReady(): boolean {
      return !!this.stack && !!this.item && !this.initializing;
    },
    meta(): User | Institution | null {
      if (isPopulated(this.item?.meta)) {
        return this.item?.meta;
      }
      return null;
    },
    name(): string {
      if (!this.meta) {
        return '';
      }
      if (this.item?.type === StackItemMetaTypes.INSTITUTION) {
        return (this.meta as Institution).name;
      } else {
        const user = this.meta as User;
        return `${user.firstName} ${user.lastName}`;
      }
    },
  },
  methods: {
    async getItem() {
      if (!this.stackId || !this.itemId) {
        this.stack = null;
        this.item = null;
        return;
      }
      this.initializing = true;
      try {
        if (this.stack?._id?.toString() !== this.stackId.toString()) {
          this.stack = await StackManagerApi.findById(this.stackId, {
            populate: {
              'items.meta': {
                fields: ['firstName', 'lastName', 'name', 'contactInformation', 'address', 'institution', 'isActive', 'customId'],
                populate: {
                  institution: {
                    fields: ['name', 'address'],
                  },
                },
              },
            },
          });
        } else {
          await sleep(200);
        }
        this.item = this.stack.items.find((item) => item._id === this.itemId) || null;
      } catch (e) {
        alert(e);
        this.$$showSnackbar(`Fehler beim Laden des Stapelelements vom Server`, 'error');
      } finally {
        this.initializing = false;
      }
    },
  },
  watch: {
    stackId() {
      this.getItem();
    },
    itemId() {
      this.getItem();
    },
  },
  mounted() {
    StackBus.$on('update:itemState', (data) => {
      if (this.itemId === data.itemId && this.item) {
        this.item.state = data.state;
      }
    });
  },
});
