
import Vue from 'vue';
import {Institution} from '@/interfaces/institution.interface';
import {User} from '@/interfaces/user.interface';
import {Stack, StackItem} from '@/interfaces/stack.interface';
import ProfileAvatar from '@/components/common/profile-avatar.vue';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';
import {StackBus} from '@/busses/StackBus';
import {StackItemStates, StackItemStatesVue} from '@/enums/StackItemStates.enum';
import OverflowText from '@/components/common/overflow-text.vue';

export default Vue.extend({
  components: {OverflowText, ProfileAvatar},
  props: {
    item: {
      type: Object as () => StackItem<any>,
    },
    stack: {
      type: Object as () => Stack,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    StackItemStates: () => StackItemStates,
    name(): string {
      if (this.item.type === 'Institution') {
        const doc = this.item.meta as Institution;
        return doc.name;
      } else {
        const doc = this.item.meta as User;
        return `${doc.firstName} ${doc.lastName}`;
      }
    },
    stateText(): string {
      return StackItemStatesVue.find((el) => el.value === this.item?.state)?.text || '';
    },
    stateIcon(): string {
      return StackItemStatesVue.find((el) => el.value === this.item?.state)?.icon || '';
    },
  },
  methods: {
    async setStateAs(state: StackItemStates) {
      this.loading = true;
      try {
        await StackManagerApi.updateItem(this.stack._id, this.item?._id, { state });
        StackBus.$emit('update:itemState', { itemId: this.item?._id, state });
        this.$$showSnackbar(`Der Status wurde erfolgreich aktualisiert`, 'success');
      } catch (e) {
        this.$$showSnackbar(`Statusupdate fehlgeschlagen`, 'error');
      } finally {
        this.loading = false;
      }
    },
    async deleteItem() {
      this.loading = true;
      try {
        await StackManagerApi.pullItem(this.stack._id, this.item?._id);
        StackBus.$emit('reload:selectedStack');
        this.$$showSnackbar(`Das Element wurde erfolgreich aus dem Stapel gelöscht`, 'success');
      } catch (e) {
        this.$$showSnackbar(`Fehler beim Löschen des Elements`, 'error');
      } finally {
        this.loading = false;
      }
    },
  },
});
