

import Vue from 'vue';
import {GenderTypes, SalutationTypesVue} from '@/enums/GenderTypes.enum';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {Types} from 'mongoose';
import {compareForUpdate, UpdateType} from '@/helpers/compareForUpdate';
import {Responsible} from '@/interfaces/responsible.interface';
import {Student} from '@/interfaces/student.interface';
import {Parent} from '@/interfaces/parent.interface';
import {Employee} from '@/interfaces/employee.interface';
import {SingleAccessEntityTypes} from '@/classes/clientOnly/permissionTreeResources/classes/CrudAccessEntity';

export default Vue.extend({
  props: {
    color: {
      type: String,
      default: 'warning',
    },
    gender: {
      type: Object as () => GenderTypes,
    },
    firstName: {
      type: String,
    },
    lastName: {
      type: String,
    },
    entity: {
      type: Object as () => Responsible | Student | Parent | Employee,
      required: true,
    },
    crudEntity: {
      type: Object as () => SingleAccessEntityTypes,
      required: true,
    },
    id: {
      type: Object as () => Types.ObjectId,
      required: true,
    },
    requireFirstName: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    genderCopy: undefined as UpdateType<GenderTypes>,
    firstNameCopy: undefined as UpdateType<string>,
    lastNameCopy: undefined as UpdateType<string>,
  }),
  computed: {
    SalutationTypesVue: () => SalutationTypesVue,
    RuleFactory: () => RuleFactory,
    updateGender() {
      return compareForUpdate(this.genderCopy, this.entity.gender);
    },
    updateFirstName() {
      return compareForUpdate(this.firstNameCopy, this.entity.firstName);
    },
    updateLastName() {
      return compareForUpdate(this.lastNameCopy, this.entity.lastName);
    },
  },
  methods: {
    canUpdate(key: string): boolean {
      const entity = this.$$getCrudEntity(this.crudEntity as SingleAccessEntityTypes, this.id);
      return entity.canUpdate(key) && entity.canRead(key);
    },
  },
  watch: {
    entity: {
      deep: true,
      immediate: true,
      handler() {
        this.genderCopy = this.entity.gender;
        this.firstNameCopy = this.entity.firstName;
        this.lastNameCopy = this.entity.lastName;
      },
    },
  },
});

