import {Types, Document} from 'mongoose';
import {Employee} from '@/interfaces/employee.interface';
import {API} from './api.class';
import {CreateEmployeeRequestDTO} from '../dto/employee-handler/request/CreateEmployee.request.dto';
import {CreateLoginUserDTO} from '../dto/_common/request/createLoginUser.request.dto';
import {CreateProfileImgRequestDTO} from '../dto/employee-handler/request/CreateProfileImg.request.dto';
import {FindEmployeeRequestDto} from '@/classes/dto/employee-handler/request/FindEmployee.request.dto';
import {EmployeeInstitutionAssignmentRequestDto} from '@/classes/dto/employee-handler/request/EmployeeInstitutionAssignment.request.dto';
import {UpdateEmployeeRequestDTO} from '@/classes/dto/employee-handler/request/UpdateEmployee.request.dto';
import {DeactivationRequestDto} from '@/classes/dto/_common/request/Deactivation.request.dto';
import {ActivationRequestDto} from '@/classes/dto/_common/request/Activation.request.dto';
import {FindByIdOptionsRequestDto} from '@/classes/dto/_common/request/FindByIdOptions.request.dto';
import { GetDirectionResponseDto } from '@/classes/dto/geocoder/response/GetDirections.response.dto';
import {FindEmployeeFilter} from '@/classes/dto/employee-handler/request/filters/FindEmployee.filter';
import {EmployeeGeoNearRequest} from '@/classes/dto/_common/request/EmployeeGeoNearRequest';

/**
 * This class is used for communicating with the employee handler microservice.
 * @class EmployeeHandlerAPI
 * @author Joshua Seipel
 */
export class EmployeeHandlerAPI extends API {
    static service = 'employee-handler';

    static async import(newDocuments: Array<Omit<Employee, keyof Document> & Partial<Document>>): Promise<Employee[]> {
        return this.post('import', newDocuments);
    }

    static async create(newDocument: CreateEmployeeRequestDTO): Promise<Employee> {
        return this.post('', newDocument);
    }

    static async findTeachersForStudent(studentId: Types.ObjectId): Promise<Employee[]> {
        return this.get(`forStudent/${studentId}`);
    }

    static async find(e: FindEmployeeRequestDto & {
        filter: FindEmployeeFilter & { near: EmployeeGeoNearRequest & { useExactDistance: true } };
    }): Promise<{ maxPage: number; employees: Array<Employee & GetDirectionResponseDto> }>;
    static async find(e: FindEmployeeRequestDto): Promise<{ maxPage: number; employees: Employee[] }>;
    static async find(e: FindEmployeeRequestDto): Promise<{ maxPage: number; employees: Employee[] | Array<Employee & GetDirectionResponseDto> }> {
        return this.get('', e);
    }

    static async findById(id: Types.ObjectId, options?: FindByIdOptionsRequestDto): Promise<Employee> {
        return this.get(`${id}`, options);
    }

    static async update(employeeId: Types.ObjectId, update: UpdateEmployeeRequestDTO): Promise<void> {
        return this.put(`${employeeId}`, update);
    }

    static async addAssignment(employeeId: Types.ObjectId, data: EmployeeInstitutionAssignmentRequestDto): Promise<void> {
        return this.put(`addAssignment/${employeeId}`, data);
    }

    static async removeAssignment(employeeId: Types.ObjectId, data: EmployeeInstitutionAssignmentRequestDto): Promise<void> {
        return this.put(`removeAssignment/${employeeId}`, data);
    }

    static async createLogin(data: CreateLoginUserDTO): Promise<void> {
        return this.put('createLogin', data);
    }

    static async deactivateLogin(id: Types.ObjectId): Promise<void> {
        return this.put(`deactivateLogin/${id}`, '');
    }

    static async deactivateEmployee(id: Types.ObjectId, data: DeactivationRequestDto): Promise<void> {
        return this.put(`deactivate/${id}`, data);
    }

    static async reactivateEmployee(id: Types.ObjectId, data: ActivationRequestDto): Promise<void> {
        return this.put(`reactivate/${id}`, data);
    }

    static async setProfileImg(data: CreateProfileImgRequestDTO): Promise<void> {
        return this.put(`setProfileImg`, data);
    }

    static remove(id: Types.ObjectId) {
        return this.delete(`${id}`);
    }
}
