
import Vue from 'vue';
import { Types } from 'mongoose';
import OverflowText from '@/components/common/overflow-text.vue';
import InstitutionCardDialog from '@/components/common/profile/institutionCardDialog.vue';
import { Institution } from '@/interfaces/institution.interface';
import AssignInstitutionsDialog from '@/components/employees/employeeView/profileView/assignInstitutionsDialog.vue';
import {getInstitutionNameWithSateCode} from '@/helpers/getInstitutionNameWithSateCode';

interface CanHaveAssignedInstitutions {
  _id: Types.ObjectId;
  assignedInstitutions?: Array<Pick<Institution, 'name' | '_id'>>;
}

export default Vue.extend({
  components: {AssignInstitutionsDialog, OverflowText, InstitutionCardDialog},
  props: {
    entity: {
      type: Object as () => CanHaveAssignedInstitutions,
      required: true,
    },
  },
  computed: {
    getInstitutionNameWithSateCode: () => getInstitutionNameWithSateCode,
  },
  data: () => ({
    activeInstitutionId: null as null | Types.ObjectId,
    showInstitutionCardDialog: false,
    showAssignInstitutionsDialog: false,
    expandListGroup: false,
  }),
});
