
import Vue from 'vue';
import AppBarSearchDialog from '@/components/app/appBar/appBarSearchDialog.vue';

export default Vue.extend({
  components: {AppBarSearchDialog},
  data: () => ({
    showSearchDialog: false,
  }),
  mounted() {
    const isMac = /Mac/i.test(navigator.platform);
    if (isMac) {
      document.onkeydown = (ev) => {
        if (ev.metaKey && ev.key === 'f') {
          ev.preventDefault();
          this.showSearchDialog = true;
        }
      };
    } else {
      document.onkeydown = (ev) => {
        if (ev.ctrlKey && ev.key === 'f') {
          ev.preventDefault();
          this.showSearchDialog = true;
        }
      };
    }
  },
});
