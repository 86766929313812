
import Vue from 'vue';
import {Types} from 'mongoose';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';

export default Vue.extend({
  props: {
    note: {type: String, required: true},
    stackId: {type: Object as () => Types.ObjectId, required: true},
    itemId: {type: Object as () => Types.ObjectId, required: true},
  },
  data: () => ({
    editMode: false,
    loading: false,
    updatedNote: '',
  }),
  methods: {
    initializeEditMode() {
      this.updatedNote = this.note;
      this.editMode = true;
    },
    stopEditMode() {
      this.updatedNote = '';
      this.editMode = false;
    },
    async saveChanges() {
      this.loading = true;
      try {
        await StackManagerApi.updateItem(this.stackId, this.itemId, {note: this.updatedNote || null});
        this.$$showSnackbar('Die Änderungen wurden erfolgreich gespeichert', 'success');
        this.$emit('update:note', this.updatedNote);
        this.stopEditMode();
      } catch (e) {
        this.$$showSnackbar('Beim Speichern der Änderungen ist ein Fehler aufgetreten', 'error');
      } finally {
        this.loading = false;
      }
    },
  },
});
