
import Vue, { Component } from 'vue';
import ProfileAvatar from '@/components/common/profile-avatar.vue';
import ContactDataListItems from '@/components/common/profile/contactDataListItems.vue';
import {toSalutation} from '@/helpers/toSalutation.helper';
import moment from 'moment';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import {GenderTypes} from '@/enums/GenderTypes.enum';
import {Institution} from '@/interfaces/institution.interface';
import {InstitutionerApi} from '@/classes/api/institutioner.api.class';
import AddressListItem from '@/components/common/profile/addressListItem.vue';
import ResponsibleListItem from '@/components/institutions/institutionView/profileView/responsibleListItem.vue';
import InstitutionAvailabilitiesListItem from '@/components/institutions/institutionView/profileView/institutionAvailabilitiesListItem.vue';
import * as mongoose from "mongoose";

export default Vue.extend({
  components: {
    InstitutionAvailabilitiesListItem,
    ContactDataListItems, AddressListItem, ResponsibleListItem, ProfileAvatar,
  },
  // because of typescripts circular dependency issues there is an import of PlannedEmployeesListITem and AssignedEmployeesListItem
  // @ToDo: limit circular dept level, since there is currently no limiter for the amount of opened dialogs
  // assignedEmployees => assignedInstitutions => assignedEmployees ....
  beforeCreate() {
    if (this.$options.components) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      this.$options.components.PlannedEmployeesListItem = require('@/components/institutions/institutionView/profileView/plannedEmployeesListItem.vue').default as Component;
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      this.$options.components.AssignedEmployeesListItem = require('@/components/institutions/institutionView/profileView/assignedEmployeesListItem.vue').default as Component;
    }
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    institutionId: {
      type: Object as () => mongoose.Types.ObjectId,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    institution: null as null | Institution,
  }),
  computed: {
    toSalutation: () => toSalutation,
    moment: () => moment,
    GenderTypes: () => GenderTypes,
  },
  methods: {
    async getInstitution() {
      try {
        this.loading = true;
        this.institution = await InstitutionerApi.findById(this.institutionId, {
          populate: {
            responsibles: {
              fields: ['firstName', 'lastName', 'gender', 'jobType'],
            },
          },
        });
      } catch (e) {
        this.$emit('input', false);
        this.$$showSnackbar('Es ist ein Fehler aufgetreten.');
      } finally {
        this.loading = false;
      }
    },
    shareAccess() {
      if (this.institution) {
        this.$$shareAccessDialog.show(CrudEntityTypes.INSTITUTION, this.institution._id, 'Standort freigeben');
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v: boolean) {
        if (v) {
          this.getInstitution();
        }
      },
    },
  },
});
