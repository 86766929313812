export enum BookingFormTemplateFields {
    NAME = 'name',
    DESCRIPTION = 'description',
    CREATES_STUDENT = 'createsStudent',
    CUSTOMER_TYPE = 'customerType',
    CREATES_COURSE = 'createsCourse',
    COURSE_TYPE = 'courseType',
    SELECTABLE_TIMESLOTS = 'selectableTimeslots',
    SELECTABLE_COURSES = 'selectableCourses',
    USE_OPEN_COURSES = 'useOpenCourses',
    USE_DRAFT_COURSES = 'useDraftCourses',
    PRINTED_FORM = 'printedForm',
    INVOICE_TEMPLATES = 'invoiceTemplates',
    SELECTABLE_SUBJECTS = 'selectableSubjects',
    CUSTOM_PLACEHOLDERS = 'customPlaceholders',
}
