export enum CourseFields {
    CUSTOM_ID = 'customId',

    SUBJECT = 'subject',
    TEACHER = 'teacher',
    OWNING_TEACHER = 'owningTeacher',
    STUDENT_ITEMS = 'studentItems',
    ROOM = 'room',
    INSTITUTION = 'institution',

    DAY_OF_WEEK = 'dayOfWeek',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    TIME_RANGE = 'timeRange',
    IS_ALL_DAY = 'isAllDay',
    WEEK_INTERVAL = 'weekInterval',

    COURSE_STATE = 'courseState',

    HOLIDAY_STATE = 'holidayState',
    HOLIDAY_COUNTRY = 'holidayCountry',

    INDIVIDUAL_HOLIDAYS_APPLY = 'individualHolidaysApply',
    SCHOOL_HOLIDAYS_APPLY = 'schoolHolidaysApply',
    PUBLIC_HOLIDAYS_APPLY = 'publicHolidaysApply',

    IS_UNPROFITABLE = 'isUnprofitable',
    PROFIT_REPORT = 'profitReport',

    STATE_HOLIDAYS_APPLY = 'stateHolidaysApply',
    COUNTRY_HOLIDAYS_APPLY = 'countryHolidaysApply',

    PREDECESSOR = 'predecessor',
    SUCCESSOR = 'successor',
}
