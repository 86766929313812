import Vue from 'vue';

type nonParamUpdateEvents = 'update:employee' | 'update:student' | 'update:parent' | 'update:institution' | 'update:shipment' | 'update:shipmentLabel' | 'update:orders' | 'update:responsible' | 'update:room' | 'update:profileActivityState' | 'update:logs';
type nonParamDeleteEvents = 'delete:responsible';
type fbWebhooks = 'fastbillWebhookCreated' | 'fastbillWebhookDeleted' | 'showCreateFastbillWebhookDialog';
type dhl = 'dhl:defaults';
type otherNonParamEvents = 'refetchDocuments'

type nonParamEvents = nonParamUpdateEvents | nonParamDeleteEvents | fbWebhooks | dhl | otherNonParamEvents;

interface EventBusInterface {
    $emit(event: nonParamEvents): void;
    $on(event: nonParamEvents, callback: () => void): any;
}

export const EventBus: EventBusInterface = new Vue();
