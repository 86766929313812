
import Vue from 'vue';
import moment from 'moment';
import {RuleFactory} from '@/helpers/ruleFactory.helper';

moment.locale('de');

export default Vue.extend({
    props: {
        value: {
            required: true,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: 'Uhrzeit',
        },
        required: {
            type: Boolean,
            default: false,
        },
        useDateInput: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            required: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        prependIcon: {
            type: String,
            default: '',
        },
        filled: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: true,
        },
        rounded: {
            type: Boolean,
            default: true,
        },
        shaped: {
            type: Boolean,
            default: false,
        },
        solo: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        tValue: null as string | null,
        showMenu: false,
        loading: false,
        throttleCounter: 0,
        acceptedFormats: ['HH:mm'],
        textFieldValue: '',
        showPreviewInMenu: false,
        showRoundedMinuteDialog: false,
    }),
    computed: {
        moment: () => moment,
        RuleFactory: () => RuleFactory,
        fValue: {
            get(): string {
                if (this.value) {
                    if (this.useDateInput) {
                        return moment(this.value as Date).format('HH:mm');
                    }
                    if (typeof this.value === 'string') {
                        return this.value;
                    }
                    const hour = (this.value as { hour: number }).hour
                        .toLocaleString('DE-de', {
                            minimumIntegerDigits: 2,
                            maximumFractionDigits: 0,
                        });
                    const minute = (this.value as { minute: number }).minute
                        .toLocaleString('DE-de', {
                            minimumIntegerDigits: 2,
                            maximumFractionDigits: 0,
                        });
                    return `${hour}:${minute}`;
                } else {
                    return '';
                }
            },
            set() {
                if (this.value) {
                    if (this.useDateInput) {
                        this.tValue = moment(this.value as Date).format('HH:mm');
                    } else {
                        this.tValue = this.value as any;
                    }
                }
            },
        },
    },
    methods: {
        updateTempValue() {
            if (this.value) {
                if (this.useDateInput) {
                    this.tValue = moment(this.value as Date).format('HH:mm');
                } else {
                    this.tValue = this.value as any;
                }
            } else {
                this.tValue = null;
                this.fValue = '';
            }
            this.textFieldValue = this.fValue;
            this.$nextTick(() => {
                const picker = this.$refs.picker as { selectingHour: boolean } & Vue;
                if (picker) {
                    picker.selectingHour = true;
                }
            });
        },
        parseTime() {
            if (this.textFieldValue === '') {
                if (this.throttleCounter === 0) {
                    this.$emit('input', null);
                }
                return;
            }
            this.throttleCounter++;
            this.loading = true;
            setTimeout(() => {
                this.throttleCounter--;
                if (this.throttleCounter === 0 && this.textFieldValue) {
                    for (const format of this.acceptedFormats) {
                        if (moment(this.textFieldValue, format).isValid()) {
                            const roundedMinute = Math.round(moment(this.textFieldValue, format).minute() / 5) * 5;
                            if (roundedMinute !== moment(this.textFieldValue, format).minute()) {
                                this.showRoundedMinuteDialog = true;
                            }
                            this.handleEmit(moment(this.textFieldValue, format).minute(roundedMinute).format('HH:mm'));
                            this.updateTempValue();
                            this.loading = false;
                            return;
                        }
                    }
                    this.$$showSnackbar({text: 'Der eingegebene Text konnte nicht als Uhrzeit interpretiert werden'});
                    this.$emit('input', null);
                    setTimeout(() => {
                        this.$emit('input', this.value);
                        this.updateTempValue();
                        this.loading = false;
                    });
                } else if (this.textFieldValue === '') {
                    this.$emit('input', null);
                    this.loading = false;
                }
            }, 1500);
        },
        handleEmit(timeString: string | null) {
            if (this.useDateInput) {
                const date = new Date(this.value as Date);
                date.setHours(moment(timeString, 'HH:mm').hours());
                date.setMinutes(moment(timeString, 'HH:mm').minutes());

                this.$emit('input', date);
            } else {
                this.$emit('input', timeString);
            }
        },
    },
    watch: {
        value() {
            this.$nextTick(() => {
                this.updateTempValue();
            });
        },
        showMenu(v: boolean) {
            if (!v) {
                setTimeout(() => {
                    this.showPreviewInMenu = !!this.tValue;
                }, 500);
            }
        },
    },
    mounted() {
        this.updateTempValue();
        this.textFieldValue = this.fValue;
        if (this.tValue) {
            this.showPreviewInMenu = true;
        }
    },
});
