export enum InstitutionFields {
    NAME = 'name',
    CUSTOM_ID = 'customId',
    ADDRESS = 'address',
    COVID_RECORDS = 'covidRecords',
    RESPONSIBLES = 'responsibles',
    TYPE = 'type',
    CONTACT_INFORMATION = 'contactInformation',
    IS_ACTIVE = 'isActive',
    DEACTIVATED_AT = 'deactivatedAt',
    STATUS = 'status',
    ACCOUNTING_REF = 'accountingRef',
    TOTAL_AMOUNT_PUPILS = 'totalAmountPupils',

    IS_RENTED = 'isRented',
    RENT_AMOUNT = 'rentAmount',
    RENT_INTERVAL = 'rentInterval',
    RENT_NOTE = 'rentNote',

    ASSIGNED_EMPLOYEES = 'assignedEmployees',
    PLANNED_EMPLOYEES = 'plannedEmployees',

    BLOCKED_SLOTS_CALENDAR = 'blockedSlotsCalendar',
    EXCEPTIONAL_BLOCKED_SLOTS_CALENDAR = 'exceptionalBlockedSlotsCalendar',
    AVAILABILITIES = 'availabilities',

    ACQUIRED_BY = 'acquiredBy',
    TAGS = 'tags',

    DEACTIVATE_AT = 'deactivateAt',
    ACTIVATE_AT = 'activateAt',
}
