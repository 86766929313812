import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { isNaN } from 'mathjs';

export type Rule = (value: any) => string | boolean;

function isEmpty(value: any): boolean {
  return typeof value === 'undefined' || value === null || value === '';
}

const required = (msg?: string): Rule => {
  return (v: any) => {
    if (Array.isArray(v)) {
      return v.length > 0 || msg || 'Dieses Feld ist erforderlich';
    }
    return !isEmpty(v) || msg || 'Dieses Feld ist erforderlich';
  };
};

const maxLength = (length: number, msg?: string): Rule => {
  return (v: string) => {
    return (!v || v.length <= length)
      || msg || `Die maximale Zeichenlänge beträgt ${length} Zeichen`;
  };
};

const minLength = (length: number, msg?: string): Rule => {
  return (v: string) => {
    return (!v || v.length >= length)
      || msg || `Die minimale Zeichenlänge beträgt ${length} Zeichen`;
  };
};

const maxValue = (max: number, msg?: string): Rule => {
  return (v: string | number) => {
    return (isEmpty(v) || v <= max)
      || msg || `Der Maximalwert für dieses Feld liegt bei ${max}`;
  };
};

const minValue = (min: number, msg?: string): Rule => {
  return (v: string | number) => {
    return (isEmpty(v) || v >= min)
      || msg || `Der Minimalwert für dieses Feld liegt bei ${min}`;
  };
};

const isInteger = (msg?: string): Rule => {
  return (v: number) => {
    const num = Number(v);
    const valid = !isNaN(num) && num - Math.round(num) === 0;
    return !v || valid || msg || 'Für dieses Feld sind nur Ganzzahlen zulässig';
  };
};

const isNumber = (msg?: string): Rule => {
  return (v: any) => {
    return isEmpty(v) || typeof v === 'number' && !isNaN(v) || msg || 'Für dieses Feld sind nur Zahlen zulässig';
  };
};

const isNumericString = (msg?: string): Rule => {
  return (v: any) => {
    const num = Number(v);
    return isEmpty(v) || !isNaN(num) || msg || 'Für dieses Feld sind nur Ziffern zulässig';
  };
};

const isValidMail = (msg?: string): Rule => {
  return (v: string) => {
    if (!v) {
      return true;
    }
    // eslint-disable-next-line no-control-regex
    const rex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gmi;
    const result = rex.exec(v);
    return !!result && result[0].length === v.length
      || msg || `Das ist keine gültige E-Mail-Adresse`;
  };
};

const isValidPhoneNumber = (countryCode: CountryCode = 'DE', msg?: string): Rule => {
  return (v: string) => {
    if (!v) {
      return true;
    }
    const phoneNumber = parsePhoneNumber(v, countryCode);
    return phoneNumber.isValid()
      || msg || `Das ist keine gültige Telefonnummer`;
  };
};

const isValidGermanNumber = (msg?: string): Rule => {
  return (v: string) => {
    if (typeof v === 'undefined' || v === null) {
      return !v || 'Should not happen';
    }
    const convertedString = v.replace(/\./gm, '').replace(/,/gm, '.');
    return !v || !isNaN(Number(convertedString)) || msg || 'Die Zahleneingabe muss dem deutschen Format entsprechen';
  };
};

const is = (value: any, msg: string): Rule => {
    return (v: any) => {
        return v === value || msg;
    };
}

const isIn = (params: Array<string | number>, msg: string): Rule => {
  return (v: string) => {
    return !v || params.map((param) => param.toString()).includes(v) || msg;
  };
};

const isNotIn = (params: Array<string | number>, msg: string): Rule => {
  return (v: string) => {
    return !v || !params.map((param) => param.toString()).includes(v) || msg;
  };
};

const includes = (rex: RegExp, msg: string): Rule => {
  return (v: string) => {
    return !v || rex.test(v) || msg;
  };
};

const includesNot = (rex: RegExp, msg: string): Rule => {
  return (v: string) => {
    return !v || !rex.test(v) || msg;
  };
};

/** Bundle of different rules for vuetify */
export const RuleFactory = {
  required,
  maxLength,
  minLength,
  maxValue,
  minValue,
  isInteger,
  isNumber,
  isNumericString,
  isValidMail,
  isValidPhoneNumber,
  isValidGermanNumber,
  is,
  isIn,
  isNotIn,
  includes,
  includesNot,
};

export enum RuleFactoryEnum {
    REQUIRED = 'required',
    MAX_LENGTH = 'maxLength',
    MIN_LENGTH = 'minLength',
    MAX_VALUE = 'maxValue',
    MIN_VALUE = 'minValue',
    IS_INTEGER = 'isInteger',
    IS_NUMBER = 'isNumber',
    IS_NUMERIC_STRING = 'isNumericString',
    IS_VALID_MAIL = 'isValidMail',
    IS_VALID_PHONE_NUMBER = 'isValidPhoneNumber',
    IS_VALID_GERMAN_NUMBER = 'isValidGermanNumber',
}
