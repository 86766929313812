
import Vue from 'vue';
import {StackItemMetaTypes} from '@/enums/StackItemMetaTypes';
import {Types} from 'mongoose';
import {StackBus} from '@/busses/StackBus';

export default Vue.extend({
  props: {
    stackId: {
      type: Object as () => Types.ObjectId,
      required: true,
    },
  },
  computed: {
    elementToAdd(): { type: StackItemMetaTypes; id: Types.ObjectId } | null {
      const keys = Object.keys(this.$route.params);
      for (const key of keys) {
        if (this.$route.params.hasOwnProperty(key)) {
          switch (key) {
            case 'studentId':
              return {id: this.$route.params[key] as unknown as Types.ObjectId, type: StackItemMetaTypes.STUDENT};
            case 'employeeId':
              return {id: this.$route.params[key] as unknown as Types.ObjectId, type: StackItemMetaTypes.EMPLOYEE};
            case 'institutionId':
              return {id: this.$route.params[key] as unknown as Types.ObjectId, type: StackItemMetaTypes.INSTITUTION};
            case 'parentId':
              return {id: this.$route.params[key] as unknown as Types.ObjectId, type: StackItemMetaTypes.PARENT};
          }
        }
      }
      return null;
    },
    elementTypeString(): string | null {
      if (this.elementToAdd) {
        switch (this.elementToAdd.type) {
          case StackItemMetaTypes.STUDENT:
            return 'Schüler:in';
          case StackItemMetaTypes.EMPLOYEE:
            return 'Mitarbeiter:in';
          case StackItemMetaTypes.INSTITUTION:
            return 'Standort';
          case StackItemMetaTypes.PARENT:
            return 'Elternteil';
        }
      }
      return null;
    },
  },
  methods: {
    handleClick() {
      if (this.elementToAdd) {
        StackBus.$emit('add:item', {
          stackId: this.stackId,
          meta: this.elementToAdd.id,
          type: this.elementToAdd.type,
        });
      }
    },
  },
});
