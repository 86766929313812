
import Vue from 'vue';
import {Types} from 'mongoose';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import DeleteStackDialog from '@/components/stack/deleteStackDialog.vue';
import {Stack} from '@/interfaces/stack.interface';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';
import {StackBus} from '@/busses/StackBus';
import {CrudActionTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes';
import {StackFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/StackFields';
import {CustomAccessKeys} from '@/classes/clientOnly/permissionTreeResources/enums/CustomAccessKeys';

export default Vue.extend({
  components: {DeleteStackDialog},
  props: {
    stackId: {
      type: Object as () => Types.ObjectId,
      required: true,
    },
    stack: {
      type: Object as () => Stack,
      required: true,
    },
    applyDoneFilter: {
      type: Boolean,
      required: true,
    },
    applyMutedFilter: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    showDeleteStackDialog: false,
    loading: false,
  }),
  computed: {
    canPerformActions(): boolean {
      const stackEntity = this.$$getCrudEntity(CrudEntityTypes.STACK, this.stackId);
      const canDelete = this.$$crudAccessManager.getAccess(CrudEntityTypes.STACK, CrudActionTypes.DELETE);
      return stackEntity.canRead(StackFields.ITEMS) || stackEntity.canUpdate(StackFields.CLOSED_AT)
          || canDelete || !!this.$$crudAccessManager.getCustom(CustomAccessKeys.SHARE_ACCESS);
    },
  },
  methods: {
    shareStack() {
      this.$$shareAccessDialog.show(CrudEntityTypes.STACK, this.stackId, 'Stapel freigeben');
    },
    /** Delays execution of show stack dialog to prevent collision with menu behaviour */
    handleShowDeleteStackDialog() {
      setTimeout(() => {
        this.showDeleteStackDialog = true;
      }, 50);
    },
    async toggleStackState() {
      if (!this.stack) {
        this.$$showSnackbar('Keine Referenz zum Stapel gefunden', 'error');
      }
      this.loading = true;
      try {
        await StackManagerApi.update(this.stackId, {closedAt: this.stack.closedAt ? null : new Date()});
        this.$$showSnackbar('Der Stapel wurde erfolgreich aktualisiert', 'success');
        StackBus.$emit('reload:selectedStack');
        StackBus.$emit('reload:stacks');
      } catch (e) {
        this.$$showSnackbar('Beim Aktualisieren des Stapels ist ein Fehler aufgetreten', 'error');
      } finally {
        this.loading = false;
      }
    },
  },
});
