
import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    timeoutPaused: false,
    showInfo: false,
  }),
  computed: {
    timeout(): number {
      return this.timeoutPaused ? -1 : this.$store.state.snackbar.timeout;
    },
    snackbar() {
      return this.$store.state.snackbar;
    },
  },
  methods: {
    hideSnackbar() {
      this.$store.commit('hideSnackbar')
    },
    pauseTimeoutIfWindowIsHidden() {
      if (document.hidden) {
        this.handleWindowBlur()
      } else {
        this.handleWindowFocus()
      }
    },
    handleWindowBlur() {
      this.timeoutPaused = true;
    },
    handleWindowFocus() {
      if (!this.showInfo) {
        this.timeoutPaused = false;
      }
    },
  },
  watch: {
    'snackbar.show'(v: boolean) {
      if (!v) {
        this.showInfo = false;
      }
    },
    showInfo(v: boolean) {
      if (v) {
        this.timeoutPaused = true;
      } else {
        if (this.$store.state.snackbar.show) {
          this.timeoutPaused = false;
        }
      }
    },
  },
  mounted() {
    document.addEventListener('visibilitychange', this.pauseTimeoutIfWindowIsHidden);
    window.addEventListener('blur', this.handleWindowBlur);
    window.addEventListener('focus', this.handleWindowFocus);
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.pauseTimeoutIfWindowIsHidden);
    window.removeEventListener('blur', this.handleWindowBlur);
    window.removeEventListener('focus', this.handleWindowFocus);
  },
});
