
import Vue from 'vue';
import AppBar from '@/components/app/app-bar.app.vue';
import NavigationDrawer from '@/components/app/navigation-drawer.app.vue';
import ErrorDialog from '@/components/app/error-dialog.app.vue';
import UnauthorizedDialog from '@/components/app/unauthorized-dialog.app.vue';
import SnackbarApp from './components/app/snackbar.app.vue';
import ActionButton from '@/components/app/action-button.app.vue';
import ShareAccessDialog from '@/components/app/share-access-dialog.vue';
import EmergencyLogoutDialog from '@/components/app/emergency-logout-dialog.vue';
import InterruptSnackbarApp from '@/components/app/interrupt-snackbar-app.vue';

export default Vue.extend({
  name: 'App',
  components: {
    InterruptSnackbarApp,
    EmergencyLogoutDialog,
    NavigationDrawer,
    AppBar,
    ErrorDialog,
    UnauthorizedDialog,
    SnackbarApp,
    ActionButton,
    ShareAccessDialog,
  },
  data: () => ({
    showDrawer: false,
  }),
  watch: {
    '$vuetify.breakpoint.mdAndUp'(v: boolean) {
      this.$nextTick(() => {
        this.showDrawer = v;
      });
    },
  },
  mounted() {
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.showDrawer = true;
    }
  },
});
