
import Vue from 'vue';
import Avataaars from 'vue-avataaars';
import {AvataaarOptions} from '@/interfaces/avataaarOptions.interface';
import {EmployeeHandlerAPI} from '@/classes/api/employee-handler.api.class';
import store from '@/store';
import CreateAvataaarCommonStep from '@/components/common/profile/createAvataaarDialog/createAvataaarCommonStep.vue';
import CreateAvataaarHairStep from '@/components/common/profile/createAvataaarDialog/createAvataaarHairStep.vue';
import CreateAvataaarFaceStep from '@/components/common/profile/createAvataaarDialog/createAvataaarFaceStep.vue';
import CreateAvataaarOutfitStep from '@/components/common/profile/createAvataaarDialog/createAvataaarOutfitStep.vue';
import {Avataaar} from '@/classes/clientOnly/Avataaar';

export default Vue.extend({
  props: {
    value: Boolean,
    type: String as () => 'employee' | 'parent' | 'student',
  },
  components: {
    CreateAvataaarOutfitStep,
    CreateAvataaarFaceStep,
    CreateAvataaarHairStep,
    CreateAvataaarCommonStep,
    Avataaars,
  },
  data: () => ({
    loading: false,
    step: 1,
    avatarSeed: '',
    avatarOptions: {
      backgroundType: 'transparent',
      backgroundColor: 'black',
      clothesType: 'hoodie',
      clothesColor: 'blue01',
      clothesGraphicsType: 'none',
      eyebrowType: 'default',
      mouthType: 'smile',
      facialHairType: 'none',
      facialHairColor: 'auburn',
      topType: 'shortHairTheCaesar',
      topColor: 'auburn',
      accessoriesType: 'none',
      eyesType: 'default',
      skinColor: 'brown',
    } as AvataaarOptions,
  }),
  methods: {
    initialize() {
      if (this.$store.state.user) {
        const avataaar = new Avataaar(this.$store.state.user.profileImg);
        this.avatarOptions = avataaar.options;
      }
    },
    reset() {
      this.step = 1;
      this.$emit('input', false);
    },
    async save() {
      this.loading = true;
      const avataaar = new Avataaar(this.avatarOptions);
      try {
        switch (this.type) {
          case 'employee':
            if (store.state.user) {
              await EmployeeHandlerAPI.setProfileImg({
                id: store.state.user._id,
                profileImg: avataaar.toHex(),
              });
              store.commit('user', {...store.state.user, profileImg: avataaar.toHex()});
              this.reset();
            }
            break;
          case 'parent':
            // ParentHandlerApi
            break;
          case 'student':
            // StudentHandlerApi
            break;
        }
      } catch (error) {
        this.loading = false;
        return;
      }
      this.loading = false;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v: boolean) {
        if (v) {
          this.initialize();
        }
      },
    },
  },
});
