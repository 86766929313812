import {
    institutionFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/institutionFieldsDictionary';
import {
    eventInfoFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/eventInfoFields.dictionary';
import {
    eventTopicFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/eventTopicFields.dictionary';
import {
    holidayFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/holidayFields.dictionary';
import {
    employeeFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/employeeFields.dictionary';
import {
    parentFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/parentFields.dictionary';
import {
    studentFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/studentFields.dictionary';
import {
    loginUserFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/loginUserFields.dictionary';
import {
    contractFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/contractFields.dictionary';
import {
    subjectFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/subjectFields.dictionary';
import {roomFieldsDictionary} from '@/classes/clientOnly/permissionTreeResources/dictionaries/roomFields.dictionary';
import {
    documentTypeFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/documentTypeFields.dictionary';
import {CrudActionTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes';
import {
    responsibleFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/responsibleFields.dictionary';
import {
    configFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/configFields.dictionary';
import {
    customIdSchemeFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/customIdSchemeFields.dictionary';
import {statsFieldsDictionary} from '@/classes/clientOnly/permissionTreeResources/dictionaries/statsFields.dictionary';
import {
    articleFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/articleFields.dictionary';
import {
    warehouseOrderFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/warehouseOrderFields.dictionary';
import {
    handoutFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/handoutFields.dictionary';
import {
    storedFileFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/storedFileFields.dictionary';
import {
    invoiceTemplateFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/invoiceTemplateFields.dictionary';
import {stackFieldsDictionary} from '@/classes/clientOnly/permissionTreeResources/dictionaries/stackFields.dictionary';
import {
    handoutEventFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/handoutEventFields.dictionary';
import {roleFieldsDictionary} from '@/classes/clientOnly/permissionTreeResources/dictionaries/roleFields.dictionary';
import {
    invoiceDocumentFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/invoiceDocumentFields.dictionary';
import {
    recurringDocumentFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/recurringDocumentFields.dictionary';
import {
    courseFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/courseFields.dictionary';
import {
    terminationFieldsDictionary,
} from "@/classes/clientOnly/permissionTreeResources/dictionaries/terminationFieldsDictionary";
import {
    storedDocumentFieldsDictionary,
} from "@/classes/clientOnly/permissionTreeResources/dictionaries/storedDocumentFields.dictionary";
import {
    bookingFormTemplateFieldsDictionary,
} from "@/classes/clientOnly/permissionTreeResources/dictionaries/bookingFormTemplateFields.dictionary";

export enum CrudEntityTypes {
    ARTICLE = 'articles',
    CONFIG = 'configs',
    CONTRACT = 'contracts',
    CUSTOM_ID_SCHEME = 'customIdSchemes',
    DOCUMENT_TYPE = 'documentTypes',
    EMPLOYEE = 'employees',
    EVENT_INFO = 'eventInfos',
    EVENT_TOPIC = 'eventTopics',
    HANDOUT_LIST = 'handoutLists',
    HANDOUT_LIST_ITEM = 'handoutListItems',
    HANDOUT_TEMPLATE = 'handoutTemplates', // For create and delete of handout templates
    HOLIDAY = 'holidays',
    INSTITUTION = 'institutions',
    INVOICE_TEMPLATE = 'invoiceTemplates',
    JOB_TYPE = 'jobTypes',
    LOGIN_USER = 'loginUsers',
    PARENT = 'parents',
    RESPONSIBLE = 'responsibles',
    ROOM = 'rooms',
    STATS = 'stats',
    STORED_FILE = 'storedFiles',
    STUDENT = 'students',
    SUBJECT = 'subjects',
    WAREHOUSE_ORDER = 'warehouseOrders',
    COURSE = 'courses',

    INVOICE_DOCUMENT = 'invoiceDocuments',
    RECURRING_DOCUMENT = 'recurringDocuments',
    CUSTOMER = 'customer',
    CONTACT = 'contact',
    WEBHOOK = 'webhooks',

    ANY_DOCUMENT = 'anyDocuments', // For create and delete of all stored documents
    OWN_DOCUMENT = 'ownDocuments', // For create and delete of own stored documents
    HANDOUT = 'handouts',
    STORED_DOCUMENT = 'storedDocuments', // For read and update access of stored documents

    TERMINATION = 'terminations',
    LOG_ENTRY = 'logEntries',
    STACK = 'stacks',
    HANDOUT_EVENT = 'handoutEvents',
    ROLE = 'roles',
    SHIPMENT_LABEL = 'shipmentLabels',
    POST_STAMPS = 'postStamps',
    DHL_STANDING_DATA = 'dhlStandingData',
    CONVERSATION = 'conversations',
    TRACKING = 'trackings',
    TAG = 'tags',

    CRON_JOB = 'cronJobs',

    CUSTOMER_MAIL_TEMPLATE = 'customerMailTemplates',
    PERMISSION_HOOK = 'permissionHooks',
    SCHOOL_CLASS = 'schoolClasses',

    BOOKING_FORM_TEMPLATE = 'bookingFormTemplates',
}

const crudEntityTypesVue = [
    {
        text: 'Standorte',
        value: CrudEntityTypes.INSTITUTION,
        fields: institutionFieldsDictionary,
    },
    {
        text: 'Mitarbeitende und Lehrkräfte',
        value: CrudEntityTypes.EMPLOYEE,
        fields: employeeFieldsDictionary,
    },
    {
        text: 'Schüler:innen',
        value: CrudEntityTypes.STUDENT,
        fields: studentFieldsDictionary,
    },
    {
        text: 'Eltern',
        value: CrudEntityTypes.PARENT,
        fields: parentFieldsDictionary,
    },
    {
        text: 'Veranstaltungen',
        value: CrudEntityTypes.EVENT_INFO,
        fields: eventInfoFieldsDictionary,
    },
    {
        text: 'Veranstaltungsarten',
        value: CrudEntityTypes.EVENT_TOPIC,
        fields: eventTopicFieldsDictionary,
    },
    {
        text: 'Ferien und Feiertage',
        value: CrudEntityTypes.HOLIDAY,
        fields: holidayFieldsDictionary,
    },
    {
        text: 'Benutzerzugänge',
        value: CrudEntityTypes.LOGIN_USER,
        fields: loginUserFieldsDictionary,
    },
    {
        text: 'Mitarbeiterverträge',
        value: CrudEntityTypes.CONTRACT,
        fields: contractFieldsDictionary,
    },
    {
        text: 'Fächer',
        value: CrudEntityTypes.SUBJECT,
        fields: subjectFieldsDictionary,
    },
    {
        text: 'Unterrichtsräume',
        value: CrudEntityTypes.ROOM,
        fields: roomFieldsDictionary,
    },
    {
        text: 'Dokumenttypen',
        value: CrudEntityTypes.DOCUMENT_TYPE,
        fields: documentTypeFieldsDictionary,
    },
    {
        text: 'Statistiken',
        value: CrudEntityTypes.STATS,
        actions: [CrudActionTypes.READ],
        fields: statsFieldsDictionary,
    },
    {
        text: 'Verantwortliche',
        value: CrudEntityTypes.RESPONSIBLE,
        fields: responsibleFieldsDictionary,
    },
    {
        text: 'Vorlagen für Anmeldungen',
        value: CrudEntityTypes.HANDOUT_TEMPLATE,
        actions: [CrudActionTypes.CREATE, CrudActionTypes.DELETE],
    },
    {
        text: 'Hochgeladene Dateien',
        value: CrudEntityTypes.STORED_FILE,
        actions: [CrudActionTypes.READ, CrudActionTypes.UPDATE],
        fields: storedFileFieldsDictionary,
    },
    {
        text: 'Konfigurationsdatei',
        value: CrudEntityTypes.CONFIG,
        fields: configFieldsDictionary,
        actions: [CrudActionTypes.READ, CrudActionTypes.UPDATE],
    },
    {
        text: 'Referenznummern',
        value: CrudEntityTypes.CUSTOM_ID_SCHEME,
        fields: customIdSchemeFieldsDictionary,
    },
    {
        text: 'Produkte',
        value: CrudEntityTypes.ARTICLE,
        fields: articleFieldsDictionary,
        actions: [CrudActionTypes.READ, CrudActionTypes.CREATE, CrudActionTypes.UPDATE],
    },
    {
        text: 'Jobposition',
        value: CrudEntityTypes.JOB_TYPE,
        actions: [CrudActionTypes.READ, CrudActionTypes.CREATE, CrudActionTypes.DELETE],
    },
    {
        text: 'Bestellungen',
        value: CrudEntityTypes.WAREHOUSE_ORDER,
        fields: warehouseOrderFieldsDictionary,
    },
    {
        text: 'Dokumente für jede Entität',
        value: CrudEntityTypes.ANY_DOCUMENT,
        actions: [CrudActionTypes.CREATE, CrudActionTypes.DELETE],
    },
    {
        text: 'Eigene Dokumente',
        value: CrudEntityTypes.OWN_DOCUMENT,
        actions: [CrudActionTypes.CREATE, CrudActionTypes.DELETE],
    },
    {
        text: 'Anmeldungen',
        value: CrudEntityTypes.HANDOUT,
        fields: handoutFieldsDictionary,
    },
    {
        text: 'Hinterlegte Dokumente',
        value: CrudEntityTypes.STORED_DOCUMENT,
        actions: [CrudActionTypes.READ, CrudActionTypes.UPDATE],
        fields: storedDocumentFieldsDictionary,
    },
    {
        text: 'Rechnungsvorlagen',
        value: CrudEntityTypes.INVOICE_TEMPLATE,
        fields: invoiceTemplateFieldsDictionary,
    },
    {
        text: 'Stapel',
        value: CrudEntityTypes.STACK,
        fields: stackFieldsDictionary,
    },
    {
        text: 'Austeilaktionen',
        value: CrudEntityTypes.HANDOUT_EVENT,
        fields: handoutEventFieldsDictionary,
    },
    {
        text: 'Benutzergruppen',
        value: CrudEntityTypes.ROLE,
        fields: roleFieldsDictionary,
    },
    {
        text: 'Versandetikette',
        value: CrudEntityTypes.SHIPMENT_LABEL,
        fields: roleFieldsDictionary,
    },
    {
        text: 'Rechnungen (einmalig)',
        value: CrudEntityTypes.INVOICE_DOCUMENT,
        fields: invoiceDocumentFieldsDictionary,
    },
    {
        text: 'Rechnungen (wiederkehrend)',
        value: CrudEntityTypes.RECURRING_DOCUMENT,
        fields: recurringDocumentFieldsDictionary,
    },
    {
        text: 'Webhooks',
        value: CrudEntityTypes.WEBHOOK,
    },
    {
        text: 'Systemkonversationen',
        value: CrudEntityTypes.CONVERSATION,
        fields: roleFieldsDictionary,
    },
    {
        text: 'Kurse',
        value: CrudEntityTypes.COURSE,
        fields: courseFieldsDictionary,
    },
    {
        text: 'Automatisierungen',
        value: CrudEntityTypes.CRON_JOB,
    },
    {
        text: 'E-Mail-Vorlagen',
        value: CrudEntityTypes.CUSTOMER_MAIL_TEMPLATE,
    },
    {
        text: 'Berechtigungsroutinen',
        value: CrudEntityTypes.PERMISSION_HOOK,
    },
    {
        text: 'Protokolleinträge',
        value: CrudEntityTypes.LOG_ENTRY,
    },
    {
        text: 'Trackinginformationen',
        value: CrudEntityTypes.TRACKING,
    },
    {
        text: 'Tracking-Informationen',
        value: CrudEntityTypes.TRACKING,
    },
    {
        text: 'DHL Internetmarken',
        value: CrudEntityTypes.POST_STAMPS,
    },
    {
        text: 'DHL Format und Produktdaten',
        value: CrudEntityTypes.DHL_STANDING_DATA,
    },
    {
        text: 'Tags',
        value: CrudEntityTypes.TAG,
    },
    {
        text: 'Kündigungen',
        value: CrudEntityTypes.TERMINATION,
        fields: terminationFieldsDictionary,
    },
    {
        text: 'Schulklassen',
        value: CrudEntityTypes.SCHOOL_CLASS,
    },
    {
        text: 'Vorlagen für Anmeldungen (neu)',
        value: CrudEntityTypes.BOOKING_FORM_TEMPLATE,
        fields: bookingFormTemplateFieldsDictionary,
    },
];

crudEntityTypesVue.sort((a, b) => a.text.localeCompare(b.text));
for (const el of crudEntityTypesVue) {
    if (el.fields) {
        el.fields.sort((a: { text: string }, b: { text: string }) => a.text.localeCompare(b.text));
    }
}

export const CrudEntityTypesVue = crudEntityTypesVue;
