
import Vue from 'vue';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';
import {Types} from 'mongoose';
import {Stack} from '@/interfaces/stack.interface';
import StackItemsListItem from '@/components/stack/stackDialog/stackItemsList/StackItemsListItem.vue';
import {StackBus} from '@/busses/StackBus';
import {StackItemStates} from '@/enums/StackItemStates.enum';
import StackItemListMenu from '@/components/stack/stackDialog/stackItemsList/StackItemListMenu.vue';
import OverflowText from '@/components/common/overflow-text.vue';

export default Vue.extend({
  components: {OverflowText, StackItemListMenu, StackItemsListItem},
  props: {
    stackId: {
      type: Object as () => Types.ObjectId,
      required: true,
    },
    itemId: {
      type: Object as () => Types.ObjectId,
      required: true,
    },
    applyDoneFilter: {
      type: Boolean,
      required: true,
    },
    applyMutedFilter: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    stack: undefined as unknown as Stack,
    initializing: false,
  }),
  computed: {
    filteredItems() {
      let items = this.stack?.items || [];
      if (this.applyDoneFilter) {
        items = items.filter((item) => item.state !== StackItemStates.DONE);
      }
      if (this.applyMutedFilter) {
        items = items.filter((item) => item.state !== StackItemStates.MUTED);
      }
      return items;
    },
    showFilterAlert(): boolean {
      if (!this.initializing && this.filteredItems.length === 0) {
        return this.applyDoneFilter || this.applyMutedFilter;
      }
      return false;
    },
    selectedStackItemIndex: {
      get(): number {
        return this.filteredItems.findIndex((s) => s._id === this.itemId);
      },
      set(v: number) {
        if (v >= 0) {
          this.$emit('update:itemId', this.filteredItems[v]._id || null);
        } else {
          this.$emit('update:itemId', null);
        }
      },
    },
  },
  methods: {
    async getStack() {
      if (!this.stackId) {
        return;
      }
      this.initializing = true;
      try {
        this.stack = await StackManagerApi.findById(this.stackId, {
          fields: ['items', 'color', 'name', 'closedAt'],
          populate: {
            'items.meta': {
              fields: ['firstName', 'lastName', 'name'],
            },
          },
        });
      } catch (e) {
        this.$$showSnackbar(`Fehler beim Laden der Stapelelemente`);
      } finally {
        this.initializing = false;
      }
    },
  },
  watch: {
    stackId: {
      immediate: true,
      handler(id: Types.ObjectId) {
        if (id) {
          this.getStack();
        }
      },
    },
  },
  mounted() {
    StackBus.$on('reload:selectedStack', this.getStack);
    StackBus.$on('update:itemState', (data) => {
      const item = this.stack.items.find((item) => item._id === data.itemId);
      if (item) {
        item.state = data.state;
      }
    });
  },
});
