
import Vue from 'vue';
import ProfileAvatar from '@/components/common/profile-avatar.vue';
import {LoginApi} from '@/classes/api/login.api.class';
import {toCurrencyString} from "@/helpers/toCurrencyString.helper";
import {CrudEntityTypes} from "@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes";
import {CrudActionTypes} from "@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes";
import {DhlPostApi} from "@/classes/api/dhl-post.api.class";

export default Vue.extend({
  data: () => ({
    loading: false,
    balance: 0,
    visible: false,
  }),
  components: {ProfileAvatar},
  computed: {
    toCurrencyString: () => toCurrencyString,
    apiPath: () => process.env.VUE_APP_API_PATH,
  },
  methods: {
    async logout() {
      try {
        await LoginApi.logout();
        this.$$showSnackbar('Du wurdest erfolgreich ausgeloggt', 'success');
      } catch (e) {
        this.$$showSnackbar('Es ist ein Fehler aufgetreten', 'error');
      }
    },
    async getWalletBalance() {
      if (this.$$crudAccessManager.canReadAllProvided(CrudEntityTypes.DHL_STANDING_DATA, CrudActionTypes.READ)) {
        try {
          this.loading = true;
          this.balance = await DhlPostApi.getBalance();
        } catch (e) {
          this.$$showSnackbar('Es ist ein Fehler beim Abrufen des aktuellen Guthabens der Portokasse aufgetreten', 'error');
        } finally {
          this.loading = false;
        }
      }
    },
    async loginAsOrigin() {
      try {
        await LoginApi.loginAsOrigin();
      } catch (e) {
        this.$$showSnackbar('Fehler bei Rückkehr zum Ursprungsaccount', 'error', e);
      }
    },
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.getWalletBalance();
        }
      },
      immediate: true,
    },
  },
});
