
import Vue from 'vue';
import { RuleFactory } from '@/helpers/ruleFactory.helper';
import { Form } from '@/interfaces/Form.interface';
import { JobType } from '@/interfaces/jobType.interface';
import { ResponsibleHandlerAPI } from '@/classes/api/responsible-handler.api.class';

export default Vue.extend({
  data: () => ({
    loading: false,
    valid: true,
    jobType: {} as JobType,
  }),
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    RuleFactory: () => RuleFactory,
  },
  methods: {
    async createJobType() {
      const form = this.$refs.form as Form;
      if (form.validate()) {
        try {
          this.loading = true;
          const job = await ResponsibleHandlerAPI.jobTypes.create(this.jobType)
          this.valid = true;
          this.$emit('update:jobType', job);
          this.reset();
          this.$$showSnackbar('Jobposition erfolgreich angelegt.', 'success');
        } finally {
          this.loading = false;
        }
      }
    },
    reset() {
      this.jobType = {} as JobType;
      this.loading = false;
      this.$emit('input', false);
    },
  },
});
