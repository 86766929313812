export enum AvailabilityLabels {
    FOR_COURSES = 'forCourses',
    FOR_HANDOUT_EVENTS = 'forHandoutEvents',
    EXCEPTIONAL = 'exceptional',
}

export const AvailabilityLabelsVue = [{
    value: AvailabilityLabels.FOR_COURSES,
    color: 'info',
    text: 'Für Kurse',
}, {
    value: AvailabilityLabels.FOR_HANDOUT_EVENTS,
    color: 'success',
    text: 'Für Austeilaktionen',
}, {
    value: AvailabilityLabels.EXCEPTIONAL,
    color: 'warning',
    text: 'In Ausnahmefällen',
}];
