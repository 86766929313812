import Vue from 'vue';
import Vuex from 'vuex';
import Axios, {AxiosError, AxiosInstance} from 'axios';
import {Employee} from '@/interfaces/employee.interface';
import {StoreActionButtonAction} from '@/interfaces/clientOnly/storeActionButtonAction.interface';
import {ShareAccessDialogDataInterface} from '@/interfaces/clientOnly/shareAccessDialogData.interface';
import CrudAccess from '@/classes/clientOnly/permissionTreeResources/interfaces/CrudAccess';
import CrudAccessManager from '@/classes/clientOnly/permissionTreeResources/classes/CrudAccessManager';
import {SnackbarAction} from '@/interfaces/clientOnly/snackbarAction';
import {Types} from 'mongoose';
import {LoginTypes} from '@/enums/LoginTypes.enum';
import {DhlApiConfigInterface} from '@/interfaces/clientOnly/dhlApiConfig.interface';
import {Route} from "vue-router";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        routeCache: undefined as Route | undefined,
        loading: false,
        appReady: false,
        title: 'Iceesoft Vanilla',
        axios: null as null | AxiosInstance,
        loginUserId: null as Types.ObjectId | null,
        user: null as null | Employee,
        userType: null as null | LoginTypes,
        originUserId: undefined as Types.ObjectId | undefined,
        error: undefined as AxiosError | undefined,
        accessToken: null as null | string,
        showUnauthorizedDialog: false,
        showEmergencyLogoutDialog: false,
        uploadProgress: 0,
        snackbar: {
            show: false,
            text: '',
            btnColor: 'pink',
            timeout: -1,
            actions: [] as SnackbarAction[],
            info: undefined as string | undefined,
        },
        interruptSnackbar: {
            show: false,
            text: '',
            timeout: 3000,
        },
        interruptTriggered: false,
        actionButton: {
            show: false,
            actions: [] as StoreActionButtonAction[],
        },
        openOrders: null as number | null,
        openTeacherDocuments: null as number | null,
        shareAccessDialogInfo: {
            show: false,
        } as ShareAccessDialogDataInterface,
        crudAccessManager: new CrudAccessManager(),
        dhlApiConfig: null as unknown as DhlApiConfigInterface,
    },
    mutations: {
        setRouteCache(state, route: Route) {
            state.routeCache = route;
        },
        clearRouteCache(state) {
            state.routeCache = undefined;
        },
        setCrudAccess(state, crudAccess: CrudAccess) {
            state.crudAccessManager = new CrudAccessManager(crudAccess);
        },
        setTitle(state, title: string) {
            state.title = title || 'Iceesoft Vanilla';
            document.title = state.title;
        },
        unsetTitle(state) {
            state.title = 'Iceesoft Vanilla';
            document.title = state.title;
        },
        setUploadProgress(state, progress: number) {
            state.uploadProgress = progress;
        },
        user(state, meta) {
            state.user = meta;
        },
        setUserType(state, type: LoginTypes) {
            state.userType = type;
        },
        setLoginUserId(state, userId: Types.ObjectId) {
            state.loginUserId = userId;
        },
        login(state, accessToken?: string) {
            if (accessToken) {
                state.accessToken = accessToken;
                state.axios = Axios.create({
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    baseURL: process.env.VUE_APP_API_PATH,
                    withCredentials: true,
                });
            } else {
                state.accessToken = null;
                state.axios = Axios.create({
                    baseURL: process.env.VUE_APP_API_PATH,
                    withCredentials: true,
                });
            }
        },
        logout(state) {
            state.axios = null;
            state.user = null;
        },
        setError(state, error?: AxiosError) {
            state.error = error;
        },
        showUnauthorizedDialog(state, value: boolean) {
            state.showUnauthorizedDialog = value;
        },
        showEmergencyLogoutDialog(state, value: boolean) {
            state.showEmergencyLogoutDialog = value;
        },
        hideSnackbar(state) {
            state.snackbar.show = false;
            setTimeout(() => {
                state.snackbar = {
                    show: false,
                    text: '',
                    btnColor: 'pink',
                    timeout: 3000,
                    actions: [],
                    info: undefined,
                };
            }, 300);
        },
        showSnackbar(state, obj: { text: string; btnColor?: string; timeout?: number; actions?: SnackbarAction[]; info?: any }) {
            state.snackbar = {
                show: true,
                text: obj.text,
                btnColor: obj.btnColor || 'pink',
                timeout: obj.timeout || 3000,
                actions: obj.actions || [],
                info: obj.info,
            };
        },
        showInterruptSnackbar(state, data: { text: string; timeout?: number }) {
            state.interruptSnackbar = {
                show: true,
                text: data.text,
                timeout: data.timeout || 3000,
            };
            state.interruptTriggered = false;
        },
        hideInterruptSnackbar(state) {
            state.interruptSnackbar.show = false;
        },
        triggerInterrupt(state) {
            state.interruptTriggered = true;
            state.interruptSnackbar.show = false;
        },
        setActions(state, actions: StoreActionButtonAction[]) {
            state.actionButton.actions = actions;
            state.actionButton.show = true;
        },
        hideActions(state, options?: { clearActions?: boolean }) {
            if (options && options.clearActions) {
                state.actionButton.actions = [];
            }
            state.actionButton.show = false;
        },
        updateOpenOrders(state, value: number) {
            state.openOrders = value;
        },
        updateOpenTeacherDocuments(state, value: number) {
            state.openTeacherDocuments = value;
        },
        setShareAccessDialogInfo(state, value: ShareAccessDialogDataInterface) {
            state.shareAccessDialogInfo = value;
        },
        setAppReady(state, value: boolean) {
            state.appReady = value;
        },
        setLoadingState(state, value: boolean) {
            state.loading = value;
        },
        setDhlApiConfig(state, value: DhlApiConfigInterface) {
            state.dhlApiConfig = value;
        },
        setOriginUserId(state, value: Types.ObjectId | undefined) {
            state.originUserId = value;
        },
    },
    actions: {},
    modules: {},
});
