
import Vue from 'vue';
export default Vue.extend({
  props: {
    title: String,
    icon: String,
    to: String,
    showTooltip: Boolean,
    infoText: [String, Number],
    infoColor: String,
    infoTooltip: [String, Number],
    noDivider: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    inset: {
      type: Boolean,
      default: false,
    },
  },
});
