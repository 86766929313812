
import Vue from 'vue';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {ContactInformationTemplateDto} from '@/classes/dto/_common/templates/ContactInformation.template.dto';

export default Vue.extend({
    props: {
        hideNotes: {
            type: Boolean,
            default: false,
        },
        isBusinessContact: {
            type: Boolean,
            default: false,
        },
        includeSubheader: {
            type: Boolean,
            default: true,
        },
        subheaderText: {
            type: String,
            default: 'Kontaktinformationen',
        },

        color: {
            type: String,
            default: 'info',
        },
        defaultObject: {
            type: Object as () => ContactInformationTemplateDto,
            required: false,
        },
        requireMail: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: true,
        },
        rounded: {
            type: Boolean,
            default: true,
        },
        filled: {
            type: Boolean,
            default: false,
        },
        solo: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        fax1: '',
        fax2: '',
        mail1: '',
        mail2: '',
        phone1: '',
        phone2: '',
        mobile1: '',
        mobile2: '',
        website: '',
        mail1Note: '',
        mail2Note: '',
        phone1Note: '',
        phone2Note: '',
        mobile1Note: '',
        mobile2Note: '',
        showSecondLine: false,
    }),
    computed: {
        RuleFactory: () => RuleFactory,
        phoneMask: () => ({
            mask: '+#### #### #### #### #### ##',
            tokens: {
                '+': {
                    pattern: /[0-9]|\+/,
                },
                '#': {
                    pattern: /[0-9]/,
                },
            },
        }),
        computedContactInformation(): ContactInformationTemplateDto {
            return {
                fax1: this.fax1 || undefined,
                fax2: this.fax2 || undefined,
                mail1: this.mail1 || undefined,
                mail2: this.mail2 || undefined,
                phone1: this.phone1 || undefined,
                phone2: this.phone2 || undefined,
                mobile1: this.mobile1 || undefined,
                mobile2: this.mobile2 || undefined,
                website: this.website || undefined,
                notes: {
                    mail1: this.mail1Note || undefined,
                    mail2: this.mail2Note || undefined,
                    mobile1: this.mobile1Note || undefined,
                    mobile2: this.mobile2Note || undefined,
                    phone1: this.phone1Note || undefined,
                    phone2: this.phone2Note || undefined,
                },
            }
        },
    },
    methods: {
        loadDefault() {
            if (this.defaultObject) {
                this.fax1 = this.defaultObject.fax1 || '';
                this.fax2 = this.defaultObject.fax2 || '';
                this.mail1 = this.defaultObject.mail1 || '';
                this.mail2 = this.defaultObject.mail2 || '';
                this.phone1 = this.defaultObject.phone1 || '';
                this.phone2 = this.defaultObject.phone2 || '';
                this.mobile1 = this.defaultObject.mobile1 || '';
                this.mobile2 = this.defaultObject.mobile2 || '';
                this.website = this.defaultObject.website || '';
                this.mail1Note = this.defaultObject.notes?.mail1 || '';
                this.mail2Note = this.defaultObject.notes?.mail2 || '';
                this.phone1Note = this.defaultObject.notes?.phone1 || '';
                this.phone2Note = this.defaultObject.notes?.phone2 || '';
                this.mobile1Note = this.defaultObject.notes?.mobile1 || '';
                this.mobile2Note = this.defaultObject.notes?.mobile2 || '';
                this.$emit('input', this.computedContactInformation);
            }
        },
    },
    watch: {
        defaultObject: {
            immediate: true,
            deep: true,
            handler() {
                if (this.defaultObject) {
                    this.loadDefault();
                }
            },
        },
    },
});
