
import Vue from 'vue';

export default Vue.extend({
  props: {
    showClosedStacks: {
      type: Boolean,
      required: true,
    },
  },
})
