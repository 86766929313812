
import Vue from 'vue';
import {ContactInformation} from '@/interfaces/contactInformation.interface';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import {getWhatsAppLink} from '@/helpers/getWhatsAppLink';

interface ContactInfoElement {
  value: string;
  icon: string;
  subTitle?: string;
  actions: Array<'visit' | 'mail' | 'call' | 'whatsapp'>;
}

interface CanHaveContactInformation {
  contactInformation?: Partial<ContactInformation>;
}

export default Vue.extend({
  props: {
    entity: {
      type: Object as () => CanHaveContactInformation,
      required: true,
    },
  },
  computed: {
    getWhatsAppLink: () => getWhatsAppLink,
    /**
     * Parses contactInformation into an array for rendering
     */
    contactInfoElements(): ContactInfoElement[] {
      const arr: ContactInfoElement[] = [];
      if (this.entity && this.entity.contactInformation) {
        const c: Record<string, string> = this.entity.contactInformation as any;
        for (const k in c) {
          if (c.hasOwnProperty(k) && c[k]) {
            if (!['phone1', 'phone2', 'mobile1', 'mobile2', 'fax1', 'fax2', 'mail1', 'mail2', 'website'].includes(k)) {
              continue;
            }
            const key: keyof ContactInformation = k as any;
            const element: ContactInfoElement = {
              value: c[key],
              icon: '',
              actions: [],
            };
            switch (key) {
              case 'mobile1':
              case 'mobile2':
                element.icon = 'mdi-cellphone';
                element.actions.push('call', 'whatsapp');
                element.value = this.getPhoneNumber(element.value);
                break;
              case 'phone1':
              case 'phone2':
                element.icon = 'mdi-phone';
                element.actions.push('call');
                element.value = this.getPhoneNumber(element.value);
                break;
              case 'fax1':
              case 'fax2':
                element.icon = 'mdi-fax';
                break;
              case 'mail1':
              case 'mail2':
                element.icon = 'mdi-email';
                element.actions.push('mail');
                break;
              case 'website':
                element.icon = 'mdi-web';
                element.actions.push('visit');
                break;
            }

            if (c.notes) {
              const notes: Record<string, string> = c.notes as any;
              if (notes[key]) {
                element.subTitle = notes[key];
              }
            }
            arr.push(element);
          }
        }
      }
      return arr;
    },
  },
  methods: {
    getPhoneNumber(value: string) {
      if (value) {
        const num = parsePhoneNumberFromString(value, 'DE');
        if (num) {
          return num.formatInternational();
        }
      }
      return value;
    },
  },
});
