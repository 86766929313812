
import Vue from 'vue';
import Gmap from '@/components/common/gmap.vue';
import {GoogleTravelModesEnum} from '@/classes/api/google/GoogleTravelModes.enum';
import {InstitutionStatus} from '@/enums/InstitutionStatus.enum';
import {InstitutionerApi} from '@/classes/api/institutioner.api.class';
import {LeanDocument} from 'mongoose';
import {HandoutItemStates} from '@/enums/HandoutItemStates.enum';
import {Place} from '@/interfaces/place.interface';
import CreateAddress from '@/components/common/create/createAddress.vue';
import InstitutionGmapsFilter from '@/components/app/gmapsSearchDialogCompoents/institutionGmapsFilter.vue';
import EmployeeGmapsFilter from '@/components/app/gmapsSearchDialogCompoents/employeeGmapsFilter.vue';
import {FindInstitutionFilter} from '@/classes/dto/institutioner/request/filters/FindInstitution.filter';
import {FindEmployeeFilter} from '@/classes/dto/employee-handler/request/filters/FindEmployee.filter';
import {EmployeeHandlerAPI} from '@/classes/api/employee-handler.api.class';
import {Form} from '@/interfaces/Form.interface';
import {Institution} from '@/interfaces/institution.interface';
import {Employee} from '@/interfaces/employee.interface';
import {GetDirectionResponseDto} from '@/classes/dto/geocoder/response/GetDirections.response.dto';
import GmapsSearchResponseNav from '@/components/app/gmapsSearchDialogCompoents/gmapsSearchResponseNav.vue';
import {InstitutionTypes} from '@/enums/InstitutionTypes.enum';
import {GeocoderAPI} from '@/classes/api/geocoder.api.class';

export default Vue.extend({
  data: () => ({

    address: {} as Place,
    tab: 0,
    radius: 10,
    institutionStatus: [] as InstitutionStatus[],
    hasNoAssignmentForTeaching: false,
    considerTravelMode: true,
    travelMode: null as GoogleTravelModesEnum | null,
    targets: [] as Array<LeanDocument<Institution | Employee> & GetDirectionResponseDto>,
    loadingMapsDistance: false,
    loading: false,
    valid: false,
    showFilterOptions: true,
    showResults: false,
    handoutItemStates: [] as HandoutItemStates[],
    institutionFilter: {
      isActive: true,
      type: [InstitutionTypes.PUBLICSCHOOL],
    } as FindInstitutionFilter,
    teacherFilter: {
      isActive: true,
      isTeacher: true,
    } as FindEmployeeFilter,
  }),
  components: {GmapsSearchResponseNav, Gmap, CreateAddress, InstitutionGmapsFilter, EmployeeGmapsFilter},
  props: {
    value: {
      type: Boolean,
    },
  },
  methods: {
    async handleDragPositionUpdate(coordinates: { lat: number; lng: number }) {
      const resp = await GeocoderAPI.findAddress({
        $search: coordinates.lat + ',' + coordinates.lng,
        requireHouseNumber: false,
        requireStreet: false,
      });
      if (resp.items.length > 0) {
        this.address = resp.items[0].value;
      } else {
        this.address = {
          street: coordinates.lat.toString(),
          postal: coordinates.lng.toString(),
          city: 'ausserhalb',
          country: '' as any,
          state: '' as any,
          location: {
            type: 'Point',
            coordinates: [coordinates.lng, coordinates.lat],
          },
        }
      }
      await this.search();
    },
    async searchInstitutions() {
      try {
        this.loading = true;
        const response = await InstitutionerApi.find({
          filter: {
            ...this.institutionFilter,
            near: {
              lat: this.address.location.coordinates[1],
              lon: this.address.location.coordinates[0],
              maxDistance: this.radius * 1000,
              useExactDistance: true,
              useTravelMode: this.travelMode as GoogleTravelModesEnum,
            },
          },
          fields: ['name', 'address', 'assignedTeachers'],
          populate: {
            assignedTeachers: {
              fields: ['firstName', 'lastName'],
            },
          },
          skipPagination: true,
        });
        this.targets = response.institutions as Array<LeanDocument<Institution> & GetDirectionResponseDto>;
        this.showFilterOptions = false;
        this.showResults = true;
      } catch (e) {
        this.$$showSnackbar('Es ist ein Fehler beim Suchen der Standorte aufgetreten. Bitte erneut versuchen.', 'error');
      } finally {
        this.loading = false;
      }
    },
    async searchEmployees() {
      try {
        this.loading = true;
        const response = await EmployeeHandlerAPI.find({
          filter: {
            ...this.teacherFilter,
            near: {
              lat: this.address.location.coordinates[1],
              lon: this.address.location.coordinates[0],
              maxDistance: this.radius * 1000,
              useExactDistance: true,
              considerTravelMode: this.considerTravelMode ? this.considerTravelMode : undefined,
              useTravelMode: this.travelMode && !this.considerTravelMode ? this.travelMode : undefined,
            },
          },
          populate: {
            assignedInstitutions: {
              fields: ['name'],
            },
          },
          fields: ['firstName', 'lastName', 'address', 'hasCar', 'assignedInstitutions'],
          skipPagination: true,
        });
        this.targets = response.employees as Array<LeanDocument<Employee> & GetDirectionResponseDto>;
        this.showFilterOptions = false;
        this.showResults = true;
      } catch (e) {
        this.$$showSnackbar('Es ist ein Fehler beim Suchen der Lehrkräfte aufgetreten. Bitte erneut versuchen.', 'error');
      } finally {
        this.loading = false;
      }
    },
    async search() {
      const form = this.$refs.form as Form;
      if (!form.validate()) {
        return;
      }
      this.loading = true;
      switch (this.tab) {
        case 0:
          await this.searchInstitutions();
          break;
        case 1:
          await this.searchEmployees();
          break;
      }
    },
    reset() {
      this.$emit('input', false);
    },
  },
  computed: {
    getAdditionalMarkers(): { institutions: Array<LeanDocument<Institution> & GetDirectionResponseDto>; employees: Array<LeanDocument<Employee> & GetDirectionResponseDto> } {
      if (this.targets.length > 0) {
        switch (this.tab) {
          case 0:
            return {
              institutions: this.targets as Array<LeanDocument<Institution> & GetDirectionResponseDto>,
              employees: [],
            };
          case 1:
            return {
              employees: this.targets as Array<LeanDocument<Employee> & GetDirectionResponseDto>,
              institutions: [],
            };
          default:
            return {institutions: [], employees: []};
        }
      } else {
        return {
          institutions: [] as Array<LeanDocument<Institution> & GetDirectionResponseDto>,
          employees: [] as Array<LeanDocument<Employee> & GetDirectionResponseDto>,
        };
      }
    },
    googleTravelModes: () => GoogleTravelModesEnum,
    isMapAvailable(): boolean {
      return (this.address && this.address.location
          && this.address.location.coordinates.length === 2);
    },
  },
  watch: {
    tab: {
      immediate: true,
      handler(v: number) {
        if (v === 0) {
          this.travelMode = GoogleTravelModesEnum.DRIVING;
        } else if (v === 1) {
          this.travelMode = null;
        }
      },
    },
  },
});
