import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {
    BookingFormTemplateFields,
} from "@/classes/clientOnly/permissionTreeResources/enums/entityFields/BookingFormTemplateFields";

export const bookingFormTemplateFieldsDictionary: FieldDictionary<BookingFormTemplateFields> = [
    {
        text: 'Name',
        value: BookingFormTemplateFields.NAME,
    },
    {
        text: 'Beschreibung',
        value: BookingFormTemplateFields.DESCRIPTION,
    },
    {
        text: 'Erstellt Schüler',
        value: BookingFormTemplateFields.CREATES_STUDENT,
    },
    {
        text: 'Art des Kundenverhältnisses',
        value: BookingFormTemplateFields.CUSTOMER_TYPE,
    },
    {
        text: 'Erstellt Kursteilnahme',
        value: BookingFormTemplateFields.CREATES_COURSE,
    },
    {
        text: 'Art der Kursauswahl',
        value: BookingFormTemplateFields.COURSE_TYPE,
    },
    {
        text: 'Wählbare Zeitslots',
        value: BookingFormTemplateFields.SELECTABLE_TIMESLOTS,
    },
    {
        text: 'Wählbare Kurse',
        value: BookingFormTemplateFields.SELECTABLE_COURSES,
    },
    {
        text: 'Verwendung offener Kurse?',
        value: BookingFormTemplateFields.USE_OPEN_COURSES,
    },
    {
        text: 'Verwendung von Kursen im Entwurfstatus?',
        value: BookingFormTemplateFields.USE_DRAFT_COURSES,
    },
    {
        text: 'Gedrucktes Formular',
        value: BookingFormTemplateFields.PRINTED_FORM,
    },
    {
        text: 'Rechnungsvorlagen',
        value: BookingFormTemplateFields.INVOICE_TEMPLATES,
    },
    {
        text: 'Wählbare Fächer',
        value: BookingFormTemplateFields.SELECTABLE_SUBJECTS,
    },
];
