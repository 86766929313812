import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {LoginUserFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/LoginUserFields';

export const loginUserFieldsDictionary: FieldDictionary<LoginUserFields> = [
    {
        text: 'Profilart',
        value: LoginUserFields.TYPE,
    },
    {
        text: 'E-Mail',
        value: LoginUserFields.MAIL,
    },
    {
        text: 'Status',
        value: LoginUserFields.STATE,
    },
    {
        text: 'Kennwort',
        value: LoginUserFields.PASSWORD,
        updateOnly: true,
    },
    {
       text: 'Aktivierungslink',
       value: LoginUserFields.ACTIVATION_LINK,
    },
    {
        text: 'Aktivitätsstatus',
        value: LoginUserFields.IS_DEACTIVATED,
    },
    {
        text: 'Fehlgeschlagene Anmeldungen',
        value: LoginUserFields.PASSWORD_ATTEMPTS,
    },
    {
        text: 'Profil',
        value: LoginUserFields.META,
    },
    {
        text: 'Dashboard-Einstellungen',
        value: LoginUserFields.DASHBOARD,
    },
    {
        text: 'Berechtigungen',
        value: LoginUserFields.CRUD_ACCESS,
    },
    {
        text: 'Berechtigungen durch Routinen',
        value: LoginUserFields.PERMISSION_HOOK_ENTRIES,
    },
    {
        text: 'Berechtigungsgruppen',
        value: LoginUserFields.ROLES,
    },
    {
        text: 'Zeitpunkt der letzten Anmeldung',
        value: LoginUserFields.LAST_LOGIN_AT,
        readOnly: true,
    },
];
