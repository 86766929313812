
import Vue from 'vue';
import {User} from '@/interfaces/user.interface';
import {Institution} from '@/interfaces/institution.interface';
import StackItemMetaInfoContactInformation
  from '@/components/stack/stackDialog/stackItemContent/stackItemMetaInfoComponent/stackItemMetaInfoContactInformation.vue';
import {isPopulated} from '@/helpers/isObjectId.helper';
import {Student} from '@/interfaces/student.interface';
import {StackItemMetaTypes} from '@/enums/StackItemMetaTypes';

export default Vue.extend({
  components: {StackItemMetaInfoContactInformation},
  props: {
    meta: {
      type: Object as () => User | Institution,
      required: true,
    },
    type: {
      type: String as () => StackItemMetaTypes,
      required: true,
    },
  },
  computed: {
    institution(): Institution | null {
      const student = this.meta as Student;
      return isPopulated(student.institution) ? student.institution : null;
    },
    metaLink(): string {
      const metaIdString = this.meta._id.toString();
      switch (this.type) {
        case StackItemMetaTypes.PARENT:
          return this.$router.resolve(`/parents/${metaIdString}`).href;
        case StackItemMetaTypes.INSTITUTION:
          return this.$router.resolve(`/institutions/${metaIdString}`).href;
        case StackItemMetaTypes.EMPLOYEE:
          return this.$router.resolve(`/employees/${metaIdString}`).href;
        case StackItemMetaTypes.STUDENT:
          return this.$router.resolve(`/students/${metaIdString}`).href;
        default:
          return '';
      }
    },
  },
});
