
import Vue from 'vue';
import {EmployeeHandlerAPI} from '@/classes/api/employee-handler.api.class';
import {FindEmployeeFilter} from '@/classes/dto/employee-handler/request/filters/FindEmployee.filter';
import mongoose from "mongoose";

export default Vue.extend({
  props: {
    value: {
      required: false,
    },
    rules: {
      type: Array as () => Array<(v: any) => boolean | string>,
      default: () => ([]),
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      required: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Suche',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    throttled: {
      type: Boolean,
      default: true,
    },
    throttleDelay: {
      type: Number,
      default: 500,
    },
    prefetch: {
      type: Boolean,
      default: false,
    },
    prefetchFilter: {
      type: Object as () => FindEmployeeFilter,
      default: () => ({} as FindEmployeeFilter),
    },
    prefetchCount: {
      type: Number,
      default: 5,
    },
    prependIcon: {
      type: String,
      default: '',
    },
    filter: {
      type: Object as () => FindEmployeeFilter,
      default: () => ({} as FindEmployeeFilter),
    },
    appendOuterIcon: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    searchInput: '',
    items: [] as Array<{ text: string; value: mongoose.Types.ObjectId }>,
    loading: false,
    throttleCounter: 0,
  }),
  computed: {
    noDataText() {
      if (!this.searchInput) {
        return 'Nach Namen suchen';
      } else {
        return 'Keine Daten vorhanden';
      }
    },
  },
  methods: {
    async handleSearchInput() {
      this.loading = true;
      if (!this.throttled) {
        if (this.searchInput) {
          await this.getItems();
        }
        this.loading = false;
      } else {
        this.throttleCounter++;
        setTimeout(async () => {
          this.throttleCounter--;
          if (this.throttleCounter === 0) {
            if (this.searchInput) {
              await this.getItems();
            }
            this.loading = false;
          }
        }, this.throttleDelay);
      }
    },
    async getItems() {
      const resp = await EmployeeHandlerAPI.find({
        skipPagination: true,
        filter: {...this.filter, $search: this.searchInput},
      });
      this.items = resp.employees.map((employee) => ({
        text: `${employee.firstName} ${employee.lastName} ${employee.customId ? ' - '+ employee.customId : ''}`,
        value: employee._id,
      }));
    },
    handleInput(v: any) {
      this.$emit('input', v);
      this.searchInput = '';
    },
    async getInitialValues() {
      if (this.value) {
        const idFilter: mongoose.Types.ObjectId[] = [];
        if (Array.isArray(this.value)) {
          idFilter.push(...this.value);
        } else {
          idFilter.push(this.value as mongoose.Types.ObjectId);
        }
        const resp = await EmployeeHandlerAPI.find({skipPagination: true, filter: {_id: idFilter}});
        this.items = resp.employees.map((employee) => ({
          text: `${employee.firstName} ${employee.lastName} ${employee.customId ? ' - '+ employee.customId : ''}`,
          value: employee._id,
        }));
      }
      if (this.prefetch) {
        const prefetchResp = await EmployeeHandlerAPI.find({
          // skip pagination for geo request
          skipPagination: !!(this.prefetchFilter && this.prefetchFilter.near),
          limit: this.prefetchFilter && this.prefetchFilter.near ? undefined : this.prefetchCount,
          fields: ['firstName', 'lastName', 'customId'],
          filter: this.prefetchFilter,
        });
        for (const employee of prefetchResp.employees) {
          const found = this.items.find((item) => item.value.toString() === employee._id.toString());
          if (!found) {
            this.items.push({
              text: `${employee.firstName} ${employee.lastName} ${employee.customId ? ' - '+ employee.customId : ''}`,
              value: employee._id,
            });
          }
        }
      }
    },
  },
  mounted() {
    this.getInitialValues();
  },
  watch: {
    prefetch: {
      handler(v: boolean) {
        if (v && this.prefetchFilter) {
          this.getInitialValues();
        }
      },
      immediate: true,
    },
    prefetchFilter: {
      deep: true,
      handler(v: FindEmployeeFilter) {
        if (v) {
          this.getInitialValues();
        }
      },
    },
  },
});
