
import Vue from 'vue';
import {sleep} from '@/helpers/sleep.helper';

export default Vue.extend({
  computed: {
    snackbar() {
      return this.$store.state.interruptSnackbar;
    },
  },
  data: () => ({
    progress: 100,
    interval: undefined as number | undefined,
  }),
  methods: {
    startInterval() {
      let counter = 0;
      this.interval = setInterval(async () => {
        this.progress = ((this.snackbar.timeout - 101 * ++counter) / this.snackbar.timeout) * 100;
        if (this.progress <= 0 || !this.snackbar.show) {
          if (this.interval) {
            clearInterval(this.interval);
            this.interval = undefined;
          }
          await sleep(100);
          this.$store.commit('hideInterruptSnackbar');
          await sleep(100);
          this.progress = 100;
        }
      }, 150) as unknown as number;
    },
    triggerInterrupt() {
      this.$store.commit('triggerInterrupt');
      this.$$showSnackbar('Der Vorgang wurde erfolgreich abgebrochen.', 'success');
    },
    skip() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = undefined;
      }
      this.$store.commit('hideInterruptSnackbar');
    },
  },
  watch: {
    'snackbar.show'(v) {
      if (v) {
        this.startInterval();
      }
    },
  },
});
