
import Vue from 'vue';
import {FindGlobalResponseDto} from '@/classes/dto/finder/response/FindGlobal.response.dto';
import * as mongoose from "mongoose";

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    result: {
      type: Object as () => FindGlobalResponseDto,
      required: true,
    },
    highlightBestMatch: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    containsMaxScore() {
      const rootItems = this.result.items as Record<string, { maxScore: number }>;
      return rootItems[this.field]?.maxScore === this.result.maxScore;
    },
    items(): Array<{text: string; value: mongoose.Types.ObjectId; isActive: boolean; ref: string; score: number}> {
      const rootItems = this.result.items as Record<string, { items: any[] }>;
      return rootItems[this.field]?.items || [];
    },
  },
  methods: {
    isMaxScore(item: {score: number}): boolean {
      return item.score === this.result.maxScore;
    },
  },
});
