
import Vue from 'vue';
import { StackManagerApi } from '@/classes/api/stack-manager.api.class';
import { Form } from '@/interfaces/Form.interface';
import { RuleFactory } from '@/helpers/ruleFactory.helper';
import {StackBus} from '@/busses/StackBus';
import colors from 'vuetify/lib/util/colors'


export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
   stackOwner: '',
    stackName: '',
    stackColor: '',
    loading: false,
    valid: false,
    swatches: [[colors.red.base], [colors.pink.base], [colors.purple.base], [colors.indigo.base],
      [colors.blue.base], [colors.lightBlue.base], [colors.cyan.base], [colors.teal.base],
      [colors.green.base], [colors.lightGreen.base], [colors.amber.base], [colors.orange.base],
      [colors.deepOrange.base], [colors.blueGrey.base], ['#9E9E9E'],
    ],
  }),
  computed: {
    RuleFactory: () => RuleFactory,
  },
  methods: {
    async save() {
      const form = this.$refs.form as Form;
      if (form.validate()) {
        this.loading = true;
        try {
          await StackManagerApi.create({
            name: this.stackName,
            color: this.stackColor,
          });
          StackBus.$emit('reload:stacks');
          this.reset();
        } catch (err) {
          alert(err);
        }
        this.loading = false;
      }
    },
    initialize() {
      this.stackColor = '#9E9E9E';
      this.stackOwner = `${this.$store.state.user.firstName} ${this.$store.state.user.lastName}`;
    },
    reset() {
      const form = this.$refs.form as Form;
      form.reset();
      this.$emit('input', false);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v: boolean) {
        if (v) {
          this.initialize();
        }
      },
    },
  },
});
