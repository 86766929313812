import { GenderTypes, SalutationTypesVue } from '@/enums/GenderTypes.enum';

export function toSalutation(gender: GenderTypes) {
  const target = SalutationTypesVue.find(v => v.value === gender);
  if (target) {
    return target.text;
  } else {
    return '';
  }
}
