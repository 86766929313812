
import Vue from 'vue';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import {AvailabilityInterface} from '@/interfaces/availability.interface';
import {TimeRange} from '@/helpers/dateHelpers/TimeRange';
import {Weekdays, WeekdaysVue} from '@/helpers/dateHelpers/Weekdays.enum';
import moment from 'moment';
import {AvailabilityLabels, AvailabilityLabelsVue} from '@/enums/AvailabilityLabels';
import OverflowText from '@/components/common/overflow-text.vue';
import {Institution} from '@/interfaces/institution.interface';
import {InstitutionFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/InstitutionFields';
import UpdateInstitutionAvailabilitiesDialog
  from '@/components/institutions/institutionView/profileView/availabilitiesListItem/updateInstitutionAvailabilitiesDialog.vue';

interface AvailabilityGroupItem {
  day: Weekdays;
  dayText: string;
  availabilities: Array<{
    timeRange: TimeRange;
    labelItems: Array<{ text: string; color: string }>;
  }>;
}

export default Vue.extend({
  components: {UpdateInstitutionAvailabilitiesDialog, OverflowText},
  props: {
    institution: {
      type: Object as () => Institution,
      required: true,
    },
  },
  data: () => ({
    showUpdateAvailabilitiesDialog: false,
    labelSelection: [AvailabilityLabels.FOR_COURSES],
  }),
  computed: {
    AvailabilityLabelsVue: () => AvailabilityLabelsVue,
    WeekdaysVue: () => WeekdaysVue,
    labelSelectionVue(): Array<{ text: string; value: string }> {
      return AvailabilityLabelsVue.filter(label => this.labelSelection.includes(label.value));
    },
    listItemTitle(): string {
      let title = 'Verfügbarkeiten';
      if (this.labelSelection.length > 0) {
        title += ` (${(this.labelSelectionVue as Array<{ text: string; value: string }>).map((l) => l.text).join(', ')})`;
      }
      return title;
    },
    filteredAvailabilities(): AvailabilityInterface[] {
      const availabilities = this.institution?.availabilities || [];
      if (this.labelSelection.length === 0) {
        return availabilities;
      }
      const preFiltered = availabilities.filter((availability: AvailabilityInterface) =>
          availability.labels.some((label: AvailabilityLabels) => this.labelSelection.includes(label)),
      ) || [];
      if (!this.labelSelection.includes(AvailabilityLabels.EXCEPTIONAL)) {
        return preFiltered.filter((el) => !el.labels.includes(AvailabilityLabels.EXCEPTIONAL));
      }
      return preFiltered;
    },
    availabilityGroupItems(): AvailabilityGroupItem[] {
      const availabilityGroupItems: AvailabilityGroupItem[] = [];
      for (const day of WeekdaysVue) {
        const newItem: AvailabilityGroupItem = {
          day: day.value,
          dayText: day.text,
          availabilities: [],
        };
        for (const availability of this.filteredAvailabilities as AvailabilityInterface[]) {
          if (availability.dayOfWeek === day.value) {
            newItem.availabilities.push({
              timeRange: availability.timeRange,
              labelItems: availability.labels.map((label) => {
                const item = AvailabilityLabelsVue.find((labelVue) => labelVue.value === label);
                return item || {text: 'Unbekannt', color: 'grey'};
              }),
            });
          }
        }
        availabilityGroupItems.push(newItem);
      }
      availabilityGroupItems.sort((a, b) => a.day - b.day);
      return availabilityGroupItems;
    },
    availableDayStrings(): string[] {
      const rawWeekdayStrings = WeekdaysVue
          .filter((day) => (this.filteredAvailabilities as AvailabilityInterface[])
              .some(availability => availability.dayOfWeek === day.value))
          .map(day => day.text);
      if (rawWeekdayStrings.length >= 4) {
        return rawWeekdayStrings.map((day) => moment(day, 'dddd').format('dd'));
      } else {
        return rawWeekdayStrings.map((day) => moment(day, 'dddd').format('dddd[s]'));
      }
    },
    canReadAvailabilities() {
      if (!this.institution) {
        return false;
      }
      return this.$$getCrudEntity(CrudEntityTypes.INSTITUTION, this.institution._id)
          .canRead(InstitutionFields.AVAILABILITIES);
    },
    canUpdateAvailabilities() {
      if (!this.institution) {
        return false;
      }
      return this.$$getCrudEntity(CrudEntityTypes.INSTITUTION, this.institution._id)
          .canUpdate(InstitutionFields.AVAILABILITIES);
    },
  },
  methods: {
    getTimeStringFromMinutes(minutes: number) {
      return moment().startOf('day').add(minutes, 'minutes').format('HH:mm');
    },
    handleAvailabilityUpdate(availabilities: AvailabilityInterface[] | null) {
      this.$set(this.institution, 'availabilities', availabilities);
    },
  },
});
