export enum StateCodes {
    BADENWUERTTEMBERG = 'BW',
    BAYERN = 'BY',
    BERLIN = 'BE',
    BRANDENBURG = 'BB',
    BREMEN = 'HB',
    HAMBURG = 'HH',
    HESSEN = 'HE',
    MECKLENBURGVORPOMMERN = 'MV',
    NIEDERSACHSEN = 'NI',
    NORDRHEINWESTFALEN = 'NRW',
    RHEINLANDPFALZ = 'RP',
    SAARLAND = 'SL',
    SACHSEN = 'SN',
    SACHSENANHALT = 'ST',
    SCHLESWIGHOLSTEIN = 'SH',
    THUERINGEN = 'TH',
}

export const StateCodesVue = [
    {
        text: 'Baden-Württemberg',
        value: StateCodes.BADENWUERTTEMBERG,
    },
    {
        text: 'Bayern',
        value: StateCodes.BAYERN,
    },
    {
        text: 'Berlin',
        value: StateCodes.BERLIN,
    },
    {
        text: 'Brandenburg',
        value: StateCodes.BRANDENBURG,
    },
    {
        text: 'Bremen',
        value: StateCodes.BREMEN,
    },
    {
        text: 'Hamburg',
        value: StateCodes.HAMBURG,
    },
    {
        text: 'Hessen',
        value: StateCodes.HESSEN,
    },
    {
        text: 'Mecklenburg-Vorpommenr',
        value: StateCodes.MECKLENBURGVORPOMMERN,
    },
    {
        text: 'Niedersachsen',
        value: StateCodes.NIEDERSACHSEN,
    },
    {
        text: 'Nordrhein-Westfalen',
        value: StateCodes.NORDRHEINWESTFALEN,
    },
    {
        text: 'Rheinland-Pfalz',
        value: StateCodes.RHEINLANDPFALZ,
    },
    {
        text: 'Saarland',
        value: StateCodes.SAARLAND,
    },
    {
        text: 'Sachsen',
        value: StateCodes.SACHSEN,
    },
    {
        text: 'Sachsen-Anhalt',
        value: StateCodes.SACHSENANHALT,
    },
    {
        text: 'Schleswig-Holstein',
        value: StateCodes.SCHLESWIGHOLSTEIN,
    },
    {
        text: 'Thüringen',
        value: StateCodes.THUERINGEN,
    },
] as Array<{ text: string; value: string }>;
