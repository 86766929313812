
import Vue from 'vue';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {Form} from '@/interfaces/Form.interface';
import {Types} from 'mongoose';
import {UpdateType} from '@/helpers/compareForUpdate';
import {AvailabilityInterface} from '@/interfaces/availability.interface';
import {Weekdays, WeekdaysVue} from '@/helpers/dateHelpers/Weekdays.enum';
import TimePicker from '@/components/common/timePicker.vue';
import moment from 'moment';
import {TimeRange} from '@/helpers/dateHelpers/TimeRange';
import {AvailabilityLabels, AvailabilityLabelsVue} from '@/enums/AvailabilityLabels';
import {Institution} from '@/interfaces/institution.interface';
import {InstitutionerApi} from '@/classes/api/institutioner.api.class';
import {InstitutionFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/InstitutionFields';

export default Vue.extend({
  components: {TimePicker},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    institutionId: {
      type: Types.ObjectId,
      required: true,
    },
  },
  data: () => ({
    initializing: false,
    loadingAvailabilityUpdate: false,
    loadingAvailabilityUnset: false,
    valid: false,

    availabilities: undefined as UpdateType<AvailabilityInterface[]>,
    institution: undefined as unknown as Institution,

    availabilityDay: undefined as unknown as Weekdays,
    availabilityFrom: undefined as unknown as string,
    availabilityTo: undefined as unknown as string,
    availabilityLabels: [] as AvailabilityLabels[],
  }),
  computed: {
    AvailabilityLabelsVue: () => AvailabilityLabelsVue,
    WeekdaysVue: () => WeekdaysVue,
    RuleFactory: () => RuleFactory,
    loading(): boolean {
      return this.loadingAvailabilityUpdate || this.loadingAvailabilityUnset;
    },
  },
  methods: {
    async initialize() {
      this.initializing = true;
      this.institution = await InstitutionerApi
          .findById(this.institutionId, {fields: [InstitutionFields.AVAILABILITIES]});
      this.availabilities = this.institution.availabilities || [];
      this.initializing = false;
    },
    async saveUpdate() {
      this.loadingAvailabilityUpdate = true;
      try {
        await InstitutionerApi.update(this.institutionId, {
          availabilities: this.availabilities,
        });
        this.$$showSnackbar('Die Verfügbarkeiten wurden erfolgreich gespeichert', 'success');
        this.$emit('update:availabilities', this.availabilities);
        this.reset();
      } catch (e) {
        this.$$showSnackbar('Fehler beim Speichern der Verfügbarkeiten', 'error');
      } finally {
        this.loadingAvailabilityUpdate = false;
      }
    },
    async saveUnset() {
      this.loadingAvailabilityUnset = true;
      const isInterrupted = await this.$$waitForInterrupt('Löschen der Verfügbarkeiten wird vorbereitet...', 2000);
      if (isInterrupted) {
        this.loadingAvailabilityUnset = false;
        return;
      }
      try {
        await InstitutionerApi.update(this.institutionId, {availabilities: null});
        this.$$showSnackbar('Verfügbarkeiten wurden verworfen', 'success');
        this.$emit('update:availabilities', undefined);
        this.reset();
      } catch (e) {
        this.$$showSnackbar('Fehler beim Löschen der Verfügbarkeiten', 'error');
      } finally {
        this.loadingAvailabilityUnset = false;
      }
    },
    addAvailability() {
      const form = this.$refs.form as Form;
      if (form.validate()) {
        if (!this.availabilities) {
          this.availabilities = [];
        }
        const timeRange = new TimeRange(
            moment(this.availabilityFrom, 'HH:mm').diff(moment().startOf('day'), 'minutes'),
            moment(this.availabilityTo, 'HH:mm').diff(moment().startOf('day'), 'minutes'),
        );
        this.availabilities.push({
          dayOfWeek: this.availabilityDay,
          timeRange,
          labels: this.availabilityLabels,
        });
        this.availabilities.sort((a, b) => {
          const delta = a.dayOfWeek - b.dayOfWeek;
          if (delta) {
            return delta;
          }
          return a.timeRange.startTime - b.timeRange.startTime;
        });
      }
    },
    getWeekdaysStringFromWeekday(weekday: Weekdays) {
      const found = this.WeekdaysVue.find(item => item.value === weekday);
      return found ? found.text : '';
    },
    getTimeStringFromMinutes(minutes: number) {
      return moment().startOf('day').add(minutes, 'minutes').format('HH:mm');
    },
    getVueLabel(label: string) {
      return this.AvailabilityLabelsVue.find(item => item.value === label) || {
        text: 'Unbekanntes label',
        color: 'grey',
      };
    },
    reset() {
      this.availabilityDay = undefined as unknown as Weekdays;
      this.availabilityFrom = undefined as unknown as string;
      this.availabilityTo = undefined as unknown as string;
      this.availabilityLabels = [];
      const form = this.$refs.form as Form;
      if (form) {
        form.resetValidation();
      }
      this.$emit('input', false);
      setTimeout(() => {
        this.institution = undefined as unknown as Institution;
      }, 300);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v: boolean) {
        if (v) {
          this.initialize();
        }
      },
    },
  },
});
