
import Vue from 'vue';


interface CanHaveTravelInfos {
  hasDriverLicense?: boolean;
  hasCar?: boolean;
  maxDistance?: number;
  maxTravelTime?: number;
}

export default Vue.extend({
  props: {
    entity: {
      type: Object as () => CanHaveTravelInfos,
      required: true,
    },
  },
})
