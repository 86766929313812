export enum InstitutionStatus {
    UNTOUCHED, PENDING, INPROGRESS , INACTIVE, ACTIVE, DECLINED,
}

export const InstitutionStatusVue = [
    {
        text: 'Unberührt',
        value: InstitutionStatus.UNTOUCHED,
        icon: 'mdi-vibrate-off',
        color: 'grey',
    },
    {
        text: 'Ausstehend',
        value: InstitutionStatus.PENDING,
        icon: 'mdi-account-clock',
        color: 'warning',
    },
    {
        text: 'In Arbeit',
        value: InstitutionStatus.INPROGRESS,
        icon: 'mdi-briefcase',
        color: 'info',
    },
    {
        text: 'Inaktiv',
        value: InstitutionStatus.INACTIVE,
        icon: 'mdi-account-off',
        color: 'grey',
    },
    {
        text: 'Aktiv',
        value: InstitutionStatus.ACTIVE,
        icon: 'mdi-check',
        color: 'success',
    },
    {
        text: 'Abgesagt',
        value: InstitutionStatus.DECLINED,
        icon: 'mdi-cancel',
        color: 'error',
    },
];
