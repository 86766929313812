
import Vue from 'vue';
import {Types} from 'mongoose';
import {StackItemMetaTypes} from '@/enums/StackItemMetaTypes';
import {StackBus} from '@/busses/StackBus';
import DatePicker from '@/components/common/datePicker.vue';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {StackItemStates, StackItemStatesVue} from '@/enums/StackItemStates.enum';
import EmployeeAutocomplete from '@/components/common/search-fields/employeeAutocomplete.vue';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';
import {Form} from '@/interfaces/Form.interface';

export default Vue.extend({
  components: {EmployeeAutocomplete, DatePicker},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    valid: false,

    meta: undefined as Types.ObjectId | undefined,
    type: undefined as StackItemMetaTypes | undefined,
    stack: undefined as unknown as Types.ObjectId,
    dueDate: undefined as Date | undefined,
    assignedTo: undefined as Types.ObjectId | undefined,
    state: undefined as unknown as StackItemStates,
    note: '',
  }),
  computed: {
    RuleFactory: () => RuleFactory,
    StackItemStatesVue: () => StackItemStatesVue,
  },
  methods: {
    async save() {
      const form = this.$refs.form as Form;
      if (form.validate()) {
        this.loading = true;
        try {
          await StackManagerApi.pushItem(this.stack, {
            meta: this.meta,
            type: this.type,
            dueDate: this.dueDate || undefined,
            assignedTo: this.assignedTo || undefined,
            state: this.state,
            note: this.note || undefined,
          });
          this.$$showSnackbar('Das Element wurde erfolgreich dem Stapel hinzugefügt', 'success');
          StackBus.$emit('reload:selectedStack');
          this.reset();
        } catch (e) {
          this.$$showSnackbar('Beim Hinzufügen des Elements ist ein Fehler aufgetreten', 'error');
        } finally {
          this.loading = false;
        }
      }
    },
    reset() {
      const form = this.$refs.form as Form;
      form.reset();
      this.$emit('input', false);
    },
  },
  mounted() {
    StackBus.$on('add:item', (data) => {
      this.meta = data.meta;
      this.type = data.type;
      this.stack = data.stackId;
    });
  },
});
