
import Vue from 'vue';
import ProfileAvatar from '@/components/common/profile-avatar.vue';
import ContactDataListItems from '@/components/common/profile/contactDataListItems.vue';
import { toSalutation } from '@/helpers/toSalutation.helper';
import moment from 'moment';
import { CrudEntityTypes } from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import { GenderTypes } from '@/enums/GenderTypes.enum';
import AddressListItem from '@/components/common/profile/addressListItem.vue';
import { EmployeeHandlerAPI } from '@/classes/api/employee-handler.api.class';
import { Employee } from '@/interfaces/employee.interface';
import BirthdayListItem from '@/components/common/profile/birthdayListItem.vue';
import SubjectsListItem from '@/components/common/profile/subjectsListItem.vue';
import TravelListItem from '@/components/employees/employeeView/profileView/travelListItem.vue';
import ContractListItem from '@/components/employees/employeeView/profileView/contractListItem.vue';
import AssignedInstitutionsListItem
  from '@/components/employees/employeeView/profileView/assignedInstitutionsListItem.vue';
import PlannedInstitutionsListItem
  from '@/components/employees/employeeView/profileView/plannedInstitutionsListItem.vue';
import EmployeeAvailabilitiesListItem
  from '@/components/employees/employeeView/profileView/employeeAvailabilitiesListItem.vue';
import AbilitiesListItem from '@/components/employees/employeeView/profileView/abilitiesListItem.vue';
import mongoose from "mongoose";

export default Vue.extend({
  components: {
    AbilitiesListItem,
    EmployeeAvailabilitiesListItem,
    ContactDataListItems,
    AddressListItem,
    ProfileAvatar,
    BirthdayListItem,
    SubjectsListItem,
    TravelListItem,
    ContractListItem,
    AssignedInstitutionsListItem,
    PlannedInstitutionsListItem,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    employeeId: {
      type: Object as () => mongoose.Types.ObjectId,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    employee: null as null | Employee,
  }),
  computed: {
    toSalutation: () => toSalutation,
    moment: () => moment,
    GenderTypes: () => GenderTypes,
  },
  methods: {
    async getEmployee() {
      try {
        this.loading = true;
        this.employee = await EmployeeHandlerAPI.findById(this.employeeId, {
          populate: {
            assignedInstitutions: {
              fields: ['name'],
            },
            plannedInstitutions: {
              fields: ['name'],
            },
            contract: {
              fields:['name', 'salaryBasis', 'salaryPerUnit', 'conditions', 'travelExpenseCompensationPerKilometer'],
            },
            subjects: {
              fields: ['name'],
            },
          },
        });
      } catch (e) {
        this.$emit('input', false);
        this.$$showSnackbar('Es ist ein Fehler aufgetreten.');
      } finally {
        this.loading = false;
      }
    },
    shareAccess() {
      if (this.employee) {
        this.$$shareAccessDialog.show(CrudEntityTypes.EMPLOYEE, this.employee._id, 'Lehrkraft freigeben');
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v: boolean) {
        if (v) {
          this.getEmployee();
        }
      },
    },
  },
});
