
import Vue from 'vue';
import { Responsible } from '@/interfaces/responsible.interface';
import { ResponsibleHandlerAPI } from '@/classes/api/responsible-handler.api.class';
import ProfileAvatar from '@/components/common/profile-avatar.vue';
import ContactDataListItems from '@/components/common/profile/contactDataListItems.vue';
import { toSalutation } from '@/helpers/toSalutation.helper';
import moment from 'moment';
import { CrudEntityTypes } from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import { isJobType } from '@/interfaces/jobType.interface';
import { GenderTypes } from '@/enums/GenderTypes.enum';
import { EventBus } from '@/busses/EventBus';
import { Types } from 'mongoose';

export default Vue.extend({
  components: { ProfileAvatar, ContactDataListItems },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    responsible: {
      type: Object as () => Responsible,
      required: true,
    },
    institutionId: {
      type: Object as () => Types.ObjectId,
      required: true,
    },
  },
  data: () => ({
    showDeleteAlert: false,
    loading: false,
  }),
  computed: {
    toSalutation: () => toSalutation,
    moment: () => moment,
  },
  methods: {
    getJobTypeString(responsible: Responsible) {
      if (responsible.jobType && isJobType(responsible.jobType)) {
        switch (responsible.gender) {
          case GenderTypes.FEMALE:
            return responsible.jobType.female;
          case GenderTypes.MALE:
            return responsible.jobType.male;
          case GenderTypes.TRANS:
            return responsible.jobType.neutral;
          default:
            return responsible.jobType.neutral;
        }
      } else {
        return 'Jobposition unbekannt';
      }
    },
    shareAccess() {
      this.$$shareAccessDialog.show(CrudEntityTypes.RESPONSIBLE, this.responsible._id, 'Ansprechpartner:in freigeben')
    },
    async deleteResponsible() {
      try {
        this.loading = true;
        await ResponsibleHandlerAPI.remove(this.responsible._id, {
          pullFromInstitution: this.institutionId,
        });
        this.showDeleteAlert = false;
        this.$emit('delete:responsible');
        EventBus.$emit('delete:responsible');
        this.$emit('input', false);
        this.$$showSnackbar('Ansprechpartner:in wurde erfolgreich gelöscht.', 'success')
      } finally {
        this.loading = false;
      }
    },
  },
});
