
import Vue from 'vue';
import { Employee } from '@/interfaces/employee.interface';
import { Types } from 'mongoose';
import { EmployeeHandlerAPI } from '@/classes/api/employee-handler.api.class';
import { EventBus } from '@/busses/EventBus';
import EmployeeAutocomplete from '@/components/common/search-fields/employeeAutocomplete.vue';
import { FindEmployeeFilter } from '@/classes/dto/employee-handler/request/filters/FindEmployee.filter';
import { CrudEntityTypes } from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import { CrudActionTypes } from '@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes';
import { InstitutionerApi } from '@/classes/api/institutioner.api.class';


export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    institutionId: {
      type: Object as () => Types.ObjectId,
      required: true,
    },
    plannedEmployees: {
      type: Array as () => Array<Pick<Employee, '_id' | 'firstName' | 'lastName'>>,
      required: true,
    },
  },
  components: {
    EmployeeAutocomplete,
  },
  data: () => ({
    loading: false,
    addEmployee: null as null | Types.ObjectId,
    removeLoading: false,
    employeePrefetch: false,
    employeePrefetchFilter: {} as FindEmployeeFilter,
  }),
  methods: {
    async getEmployeePrefetchFilter() {
      try {
        const canReadInstitutionAddress = this.$$crudAccessManager.canAccessEntity(CrudEntityTypes.INSTITUTION, CrudActionTypes.READ, this.institutionId, 'address');
        if (canReadInstitutionAddress) {
          const institution = await InstitutionerApi.findById(this.institutionId, {fields: ['address']});
          if (institution.address.location.coordinates.length === 2 && institution.address.location.coordinates[0] !== 0 && institution.address.location.coordinates[1] !== 0) {
            this.employeePrefetchFilter = {
              isTeacher: true,
              near: {
                lat: institution.address.location.coordinates[1],
                lon: institution.address.location.coordinates[0],
                maxDistance: 20000,
              },
            } as FindEmployeeFilter;
            this.employeePrefetch = true;
          }
        }

      } catch (e) {
        this.$$showSnackbar('Beim Laden der Daten ist ein Fehler aufgetreten', 'error');
      }
    },
    async addPlanning() {
      try {
        if (this.addEmployee) {
          this.loading = true;
          await EmployeeHandlerAPI.addAssignment(this.addEmployee, {
            type: 'plan',
            institutionId: this.institutionId,
          });
          this.$$showSnackbar(`Die Vormerkung der Lehrkraft war erfolgreich.`, 'success');
          this.$emit('update:institution');
          EventBus.$emit('update:institution');
        }
      } catch (e) {
        this.$$showSnackbar('Es ist ein Fehler bei der Vormerkung der Lehrkraft aufgetreten. Bitte erneut versuchen.', 'error');
      } finally {
        this.loading = false;
        this.reset();
      }
    },
    async removePlanning(id: Types.ObjectId) {
      try {
        this.removeLoading = false;
        await EmployeeHandlerAPI.removeAssignment(id, {
          type: 'plan',
          institutionId: this.institutionId,
        });
        this.$$showSnackbar('Die Vormerkung wurde erfolgreich aufgehoben.', 'success');
        this.$emit('update:institution');
        EventBus.$emit('update:institution');
      } catch (e) {
        this.$$showSnackbar('Es ist ein Fehler beim Aufheben der Vormerkung ausgetreten. Bitte erneut versuchen.', 'error');
      } finally {
        this.removeLoading = false;
      }
    },
    reset() {
      this.addEmployee = null;
      this.$emit('input', false);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v: boolean) {
        if (v) {
          this.getEmployeePrefetchFilter();
        }
      },
    },
  },
});
