
import Vue from 'vue';
import StacksList from '@/components/stack/stackDialog/StacksList.vue';
import StackItemsList from '@/components/stack/stackDialog/StackItemsList.vue';
import StackItemContent from '@/components/stack/stackDialog/StackItemContent.vue';
import AddElementToStackDialog from '@/components/stack/stackDialog/AddElementToStackDialog.vue';
import {StackBus} from '@/busses/StackBus';
import {sleep} from '@/helpers/sleep.helper';
import mongoose from "mongoose";

export default Vue.extend({
  components: {AddElementToStackDialog, StackItemContent, StackItemsList, StacksList},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    stackId: null as mongoose.Types.ObjectId | null,
    itemId: null as mongoose.Types.ObjectId | null,
    showAddElementToStackDialog: false,

    showClosedStacks: false,
    applyDoneFilter: true,
    applyMutedFilter: false,
  }),
  methods: {
    reset() {
      this.$emit('input', false);
    },
  },
  mounted() {
    StackBus.$on('add:item', () => this.showAddElementToStackDialog = true);
    StackBus.$on('unselect:stack', () => {
      this.stackId = null;
      this.itemId = null;
    });
    StackBus.$on('deleted:stack', (id: mongoose.Types.ObjectId) => {
      if (this.stackId === id) {
        this.stackId = null;
        this.itemId = null;
      }
    });
    StackBus.$on('open:item', async (data) => {
      this.$emit('input', true);
      await sleep(100)
      this.stackId = data.stackId;
      this.itemId = data.itemId;
    });
  },
});
