
import Vue from 'vue';
import {Types} from 'mongoose';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';
import moment from 'moment';
import ProfileAvatar from '@/components/common/profile-avatar.vue';
import {Employee} from '@/interfaces/employee.interface';
import {EmployeeHandlerAPI} from '@/classes/api/employee-handler.api.class';
import EmployeeAutocomplete from '@/components/common/search-fields/employeeAutocomplete.vue';
import {sleep} from '@/helpers/sleep.helper';

moment.locale('de');

export default Vue.extend({
  components: {EmployeeAutocomplete, ProfileAvatar},
  props: {
    assignedTo: {type: Object as () => Types.ObjectId, required: true},
    stackId: {type: Object as () => Types.ObjectId, required: true},
    itemId: {type: Object as () => Types.ObjectId, required: true},
  },
  data: () => ({
    initializing: false,
    assignedEmployee: undefined as unknown as Employee,
    editMode: false,
    loading: false,
    updatedAssignedTo: undefined as unknown as Types.ObjectId,
  }),
  computed: {
    moment: () => moment,
  },
  methods: {
    initializeEditMode() {
      this.updatedAssignedTo = this.assignedTo;
      this.editMode = true;
    },
    stopEditMode() {
      this.updatedAssignedTo = undefined as unknown as Types.ObjectId;
      this.editMode = false;
    },
    async getEmployee() {
      if (!this.assignedTo) {
        this.assignedEmployee = undefined as unknown as Employee;
        return;
      }
      this.initializing = true;
      try {
        this.assignedEmployee = await EmployeeHandlerAPI.findById(this.assignedTo, {fields: ['firstName', 'lastName', 'profileImg']});
        await sleep(200);
      } catch (e) {
        this.$$showSnackbar('Fehler beim Laden der Verantwortliche:n', 'error');
      } finally {
        this.initializing = false;
      }
    },
    async saveChanges() {
      this.loading = true;
      try {
        await StackManagerApi.updateItem(this.stackId, this.itemId, {assignedTo: this.updatedAssignedTo || null});
        this.$$showSnackbar('Die Änderungen wurden erfolgreich gespeichert', 'success');
        this.$emit('update:assignedTo', this.updatedAssignedTo);
        this.stopEditMode();
      } catch (e) {
        this.$$showSnackbar('Beim Speichern der Änderungen ist ein Fehler aufgetreten', 'error');
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    assignedTo: {
      immediate: true,
      handler() {
        this.getEmployee();
      },
    },
  },
});
