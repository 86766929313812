
import Vue from 'vue';
import {
  avataaarAccessoriesType,
  avataaarEyebrowType,
  avataaarEyesType,
  avataaarFacialHairType,
  avataaarMouthType,
} from '@/constants/avataaar.constant';
import ColorButton from '@/components/common/profile/createAvataaarDialog/colorButton.vue';

export default Vue.extend({
  components: {ColorButton},
  props: {
    facialHairType: {
      type: String,
      default: '',
    },
    facialHairColor: {
      type: String,
      default: '',
    },
    eyebrowType: {
      type: String,
      default: '',
    },
    eyesType: {
      type: String,
      default: '',
    },
    mouthType: {
      type: String,
      default: '',
    },
    accessoriesType: {
      type: String,
      default: '',
    },
  },
  computed: {
    facialHairTypeItems: () => avataaarFacialHairType,
    eyebrowTypeItems: () => avataaarEyebrowType,
    eyesTypeItems: () => avataaarEyesType,
    mouthTypeItems: () => avataaarMouthType,
    accessoriesTypeItems: () => avataaarAccessoriesType,
  },
});
