
import Vue from 'vue';
import { Abilities } from '@/interfaces/abilities.interface';


interface CanHaveAbilities {
  abilities: Abilities;
}

export default Vue.extend({
  props: {
    entity: {
      type: Object as () => CanHaveAbilities,
      required: true,
    },
  },
})
