export enum HandoutEventFields {
    INSTITUTION = 'institution',
    INSTITUTION_HAS_CONFIRMED = 'institutionHasConfirmed',
    INSTITUTION_IS_ADVERTISER = 'institutionIsAdvertiser',
    COORDINATOR = 'coordinator',
    COORDINATOR_HAS_CONFIRMED = 'coordinatorHasConfirmed',
    ADVERTISER = 'advertiser',
    ADVERTISER_HAS_CONFIRMED = 'advertiserHasConfirmed',
    TEACHER = 'teacher',
    TEACHER_HAS_CONFIRMED = 'teacherHasConfirmed',
    EVENT_DATE = 'eventDate',
    EVENT_START_TIME = 'eventStartTime',
    EVENT_END_TIME = 'eventEndTime',
    HANDOUT = 'handout',
    HANDOUT_PRINTED = 'handoutPrinted',
    HANDOUT_SENT = 'handoutSent',
    EVENT_TOOK_PLACE = 'eventTookPlace',
    COURSE_START_DATE = 'courseStartDate',
    RETURNING_DATE = 'returningDate',
    HAS_RETURNED = 'hasReturned',
    IS_DELAYED = 'isDelayed',
    RETURNING_AMOUNT = 'returningAmount',
    IS_ACTIVE = 'isActive',
    PROTOCOL = 'protocol',

    COORDINATOR_REMOTE_CONFIRMATION = 'coordinatorRemoteConfirmation',
    ADVERTISER_REMOTE_CONFIRMATION = 'advertiserRemoteConfirmation',
    TEACHER_REMOTE_CONFIRMATION = 'teacherRemoteConfirmation',
    INSTITUTION_REMOTE_CONFIRMATION = 'institutionRemoteConfirmation',
}
