
import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    showMenu: false,
  }),
  computed: {
    showButton() {
      return this.$store.state.actionButton.show;
    },
    actions() {
      return this.$store.state.actionButton.actions;
    },
    nudgeTop(): string {
      const bias = 16;
      const elementHeight = 40;
      return bias + elementHeight * this.$store.state.actionButton.actions.length + 'px';
    },
  },
  methods: {
    getName(nameProp: string | (() => string)): string {
      if (typeof nameProp === 'function') {
        return nameProp();
      }
      return nameProp;
    },
    getIcon(iconProp: string | (() => string)): string {
      if (typeof iconProp === 'function') {
        return iconProp();
      }
      return iconProp;
    },
  },
});
