
import Vue from 'vue';
import { Contract } from '@/interfaces/contract.interface';
import { isObjectId } from '@/helpers/isObjectId.helper';
import { toCurrencyString } from '@/helpers/toCurrencyString.helper';
import { SalaryBasisVue } from '@/enums/SalaryBasis.enum';
import ContractCardDialog from '@/components/contracts/contractCardDialog.vue';

interface CanHaveContract {
  contract?: Contract;
}

export default Vue.extend({
  components: {
    ContractCardDialog,
  },
  props: {
    entity: {
      type: Object as () => CanHaveContract,
      required: true,
    },
  },
  data: () => ({
    showContractCardDialog: false,
  }),
  computed: {
    isObjectId: () => isObjectId,
    toCurrency: () => toCurrencyString,
    SalaryBasisVue: () => SalaryBasisVue,
  },
})
