
import Vue from 'vue';
import {Types} from 'mongoose';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';
import {StackBus} from '@/busses/StackBus';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    stackId: {
      type: Types.ObjectId,
      required: false,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    async removeStack() {
      if (!this.stackId) {
        this.$$showSnackbar('Die Stapelreferenz konnte nicht ermittelt werden', 'error');
      }
      this.loading = true;
      try {
        await StackManagerApi.remove(this.stackId);
        this.$$showSnackbar('Der Stapel wurde erfolgreich gelöscht', 'success');
        StackBus.$emit('deleted:stack', this.stackId);
        this.reset();
      } catch (e) {
        this.$$showSnackbar('Beim Löschen des Stapels ist ein Fehler aufgetreten', 'error');
      } finally {
        this.loading = false;
      }
    },
    reset() {
      this.$emit('input', false);
    },
  },
});
