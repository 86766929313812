import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueTheMask from 'vue-the-mask';
import '@babel/polyfill';
import GlobalMixins from './mixins/global/GlobalMixins';
import {CrudManagerDirective} from '@/classes/clientOnly/CrudManagerDirective';

Vue.config.productionTip = false;
Vue.use(VueTheMask);
Vue.directive('can', {
  bind: (el, binding, vNode) => CrudManagerDirective.handle(el, binding, vNode),
  update: (el, binding, vNode) => CrudManagerDirective.handle(el, binding, vNode),
  inserted: (el, binding, vNode) => CrudManagerDirective.handle(el, binding, vNode),
});
Vue.mixin(GlobalMixins);

if (process.env.NODE_ENV === 'production') {
  if (window.location.href.includes('http') && !window.location.href.includes('https')) {
    window.location.href = window.location.href.replace('http', 'https');
  }
}

const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
Vue.config.warnHandler = (msg: string | null, vm: Vue | null, trace: string | null) => {
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
  if (msg && vm && trace) {
    return;
  }
};

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
