import {Institution} from '@/interfaces/institution.interface';
import {LeanDocument, Types} from 'mongoose';
import {API} from './api.class';
import {CreateInstitutionRequestDto} from '../dto/institutioner/request/createInstitution.request.dto';
import {GetInstitutionPlanningListRequestDTO} from '@/classes/dto/institutioner/request/getInstitutionPlanningList.request.dto';
import {GetInstitutionPlanningListResponseDTO} from '@/classes/dto/institutioner/response/getInstitutionPlanningList.response.dto';
import {FindInstitutionRequestDto} from '@/classes/dto/institutioner/request/FindInstitution.request.dto';
import {UpdateInstitutionRequestDto} from '@/classes/dto/institutioner/request/UpdateInstitution.request.dto';
import {InstitutionEmployeeAssignmentRequestDto} from '@/classes/dto/institutioner/request/InstitutionEmployeeAssignment.request.dto';
import {AddOrRemoveResponsibleRequestDto} from '@/classes/dto/responsible-handler/request/AddOrRemoveResponsible.request.dto';
import {FindByIdOptionsRequestDto} from '@/classes/dto/_common/request/FindByIdOptions.request.dto';
import {DeactivationRequestDto} from '@/classes/dto/_common/request/Deactivation.request.dto';
import {ActivationRequestDto} from '@/classes/dto/_common/request/Activation.request.dto';
import {FindStudentsByInstitutionRequestDto} from '@/classes/dto/institutioner/request/FindStudentsByInstitution.request.dto';
import {FindStudentsByInstitutionResponseDto} from '@/classes/dto/institutioner/response/FindStudentsByInstitution.response.dto';
import {GetDirectionResponseDto} from '@/classes/dto/geocoder/response/GetDirections.response.dto';
import {FindInstitutionFilter} from '@/classes/dto/institutioner/request/filters/FindInstitution.filter';
import {GeoNearRequest} from '@/classes/dto/_common/request/GeoNearRequest';

/**
 * This class is used for communicating with Institutioner microservice
 * @class InstitutionerApi
 * @author mklaczinski
 */
export class InstitutionerApi extends API {
    static service = 'institutioner';

    static async import(newDocuments: Institution[]): Promise<Institution[]> {
        return this.post('import', newDocuments);
    }

    static async create(newDocument: CreateInstitutionRequestDto): Promise<Institution> {
        return this.post('', newDocument);
    }

    static async update(id: Types.ObjectId, updateQuery: UpdateInstitutionRequestDto): Promise<void> {
        return this.put(`${id}`, updateQuery);
    }

    static async addAssignment(id: Types.ObjectId, updateQuery: InstitutionEmployeeAssignmentRequestDto): Promise<void> {
        return this.put(`addAssignment/${id}`, updateQuery);
    }

    static async removeAssignment(id: Types.ObjectId, updateQuery: InstitutionEmployeeAssignmentRequestDto): Promise<void> {
        return this.put(`removeAssignment/${id}`, updateQuery);
    }

    static async addResponsible(id: Types.ObjectId, updateQuery: AddOrRemoveResponsibleRequestDto): Promise<void> {
        return this.put(`addResponsible/${id}`, updateQuery);
    }

    static async removeResponsible(id: Types.ObjectId, updateQuery: AddOrRemoveResponsibleRequestDto): Promise<void> {
        return this.put(`removeResponsible/${id}`, updateQuery);
    }

    static async find(findQuery: FindInstitutionRequestDto & {
        filter: FindInstitutionFilter & { near: GeoNearRequest & { useExactDistance: true } };
    }): Promise<{ institutions: Array<LeanDocument<Institution> & GetDirectionResponseDto>; maxPage: number }>;
    static async find(findQuery: FindInstitutionRequestDto): Promise<{ institutions: Institution[]; maxPage: number }>;
    static async find(findQuery: FindInstitutionRequestDto): Promise<{
        institutions: Institution[] | Array<LeanDocument<Institution> & GetDirectionResponseDto>;
        maxPage: number;
    }> {
        return this.get(``, findQuery);
    }

    static async findById(id: Types.ObjectId, options?: FindByIdOptionsRequestDto): Promise<Institution> {
        return this.get(id.toString(), options);
    }

    static async getPlanningList(data?: GetInstitutionPlanningListRequestDTO): Promise<GetInstitutionPlanningListResponseDTO> {
        return this.get(`planning-list`, data);
    }

    static async remove(id: Types.ObjectId): Promise<void> {
        return this.delete(id.toString());
    }

    static async deactivateInstitution(id: Types.ObjectId, data: DeactivationRequestDto): Promise<void> {
        return this.put(`deactivate/${id}`, data);
    }

    static async reactivateInstitution(id: Types.ObjectId, data: ActivationRequestDto): Promise<void> {
        return this.put(`reactivate/${id}`, data);
    }

    static async studentByInstitution(id: Types.ObjectId, data: FindStudentsByInstitutionRequestDto): Promise<FindStudentsByInstitutionResponseDto> {
        return this.get(`studentsByInstitution/${id}`, data);
    }
}
