
import Vue from 'vue';
import {Subject} from '@/interfaces/subject.interface';

interface CanHaveSubjects {
  subjects?: Subject[];
}

export default Vue.extend({
  props: {
    entity: {
      type: Object as () => CanHaveSubjects,
      required: true,
    },
  },
})
