
import Vue from 'vue';
import {FinderAPI} from '@/classes/api/finder.api';
import {InstitutionerApi} from '@/classes/api/institutioner.api.class';
import {FinderIndices} from '@/enums/FinderIndices';
import { FindEmployeeFilter } from '@/classes/dto/employee-handler/request/filters/FindEmployee.filter';
import { FindInstitutionFilter } from '@/classes/dto/institutioner/request/filters/FindInstitution.filter';
import mongoose from "mongoose";

export default Vue.extend({
  props: {
    value: {
      required: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    smallChips: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array as () => Array<(v: any) => boolean | string>,
      default: () => ([]),
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    color: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Suche',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    throttled: {
      type: Boolean,
      default: true,
    },
    throttleDelay: {
      type: Number,
      default: 500,
    },
    prependIcon: {
      type: String,
      default: '',
    },
    prefetch: {
      type: Boolean,
      default: false,
    },
    prefetchFilter: {
      type: Object as () => FindEmployeeFilter,
      default: () => ({} as FindEmployeeFilter),
    },
    prefetchCount: {
      type: Number,
      default: 5,
    },
    appendOuterIcon: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    searchInput: '',
    items: [] as Array<{ text: string; value: mongoose.Types.ObjectId }>,
    loading: false,
    throttleCounter: 0,
  }),
  methods: {
    async handleSearchInput() {
      this.loading = true;
      if (!this.throttled) {
        if (this.searchInput) {
          await this.getItems();
        }
        this.loading = false;
      } else {
        this.throttleCounter++;
        setTimeout(async () => {
          this.throttleCounter--;
          if (this.throttleCounter === 0) {
            if (this.searchInput) {
              await this.getItems();
            }
            this.loading = false;
          }
        }, this.throttleDelay);
      }
    },
    async getItems() {
      const resp = await FinderAPI.findIndex(FinderIndices.INSTITUTIONS, this.searchInput);
      this.items = resp.items;
    },
    handleInput(v: any) {
      this.$emit('input', v);
      this.searchInput = '';
    },
    async getInitialValues() {
      if (this.value) {
        const idFilter: mongoose.Types.ObjectId[] = [];
        if (Array.isArray(this.value)) {
          idFilter.push(...this.value);
        } else {
          idFilter.push(this.value as mongoose.Types.ObjectId);
        }
        const resp = await InstitutionerApi.find({skipPagination: true, filter: { _id: idFilter }});
        this.items = resp.institutions.map((institution) => ({
          text: `${institution.name} ${institution.customId ? ' - '+ institution.customId : ''}`,
          value: institution._id,
        }));
      }
      if (this.prefetch) {
        const prefetchResp = await InstitutionerApi.find({
          // skip pagination for geo request
          skipPagination: !!(this.prefetchFilter && this.prefetchFilter.near),
          limit: this.prefetchFilter && this.prefetchFilter.near ? undefined : this.prefetchCount,
          fields: ['name', 'customId'],
          filter: this.prefetchFilter,
        });
        for (const institution of prefetchResp.institutions) {
          const found = this.items.find((item) => item.value.toString() === institution._id.toString());
          if (!found) {
            this.items.push({
              text: `${institution.name} ${institution.customId ? ' - '+ institution.customId : ''}`,
              value: institution._id,
            });
          }
        }
      }
    },
  },
  mounted() {
    this.getInitialValues();
  },
  watch: {
    prefetch: {
      handler(v: boolean) {
        if (v && this.prefetchFilter) {
          this.getInitialValues();
        }
      },
      immediate: true,
    },
    prefetchFilter: {
      deep: true,
      handler(v: FindInstitutionFilter) {
        if (v) {
          this.getInitialValues();
        }
      },
    },
  },
});
