
import Vue from 'vue';
import Avataaars from 'vue-avataaars';
import {Avataaar} from '@/classes/clientOnly/Avataaar';
import {AvataaarOptions} from '@/interfaces/avataaarOptions.interface';

export default Vue.extend({
  components: {
    Avataaars,
  },
  props: {
    user: {
      type: Object as () => { profileImg?: string; firstName?: string; lastName?: string },
      required: false,
    },
    size: {
      type: Number,
      default: 32,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    noFallback: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: 'primary',
    },
    useInitialsAsFallback: {
      type: Boolean,
      default: false,
    },
    fallbackBgColor: {
      type: String,
      default: 'grey',
    },
    fallbackIconColor: {
      type: String,
      default: 'white',
    },
    fallbackTextClass: {
      type: String,
      default: 'white--text',
    },
    useIconFallback: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'mdi-account',
    },
  },
  computed: {
    initials(): string | null {
      if (this.user && (this.user.firstName || this.user.lastName)) {
        let initials = '';
        if (this.user.firstName) {
          initials += this.user.firstName[0];
        }
        if (this.user.lastName) {
          initials += this.user.lastName[0];
        }
        return initials;
      }
      return null;
    },
    options(): AvataaarOptions {
      return new Avataaar(this.user?.profileImg).options;
    },
  },
});
