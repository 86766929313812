
import Vue from 'vue';
import {avataaarTopType} from '@/constants/avataaar.constant';
import ColorButton from '@/components/common/profile/createAvataaarDialog/colorButton.vue';

export default Vue.extend({
  components: {ColorButton},
  data: () => ({
    hairlessTypes: ['none', 'hat', 'hijab', 'turban', 'winterHat1', 'winterHat2', 'winterHat3', 'winterHat4'],
  }),
  props: {
    topType: {
      type: String,
      default: '',
    },
    topColor: {
      type: String,
      default: '',
    },
  },
  computed: {
    avataaarTopTypeItems: () => avataaarTopType,
  },
});
