
import store from '@/store';
import Vue from 'vue';
import html2canvas from 'html2canvas';
import { MailerApi } from '@/classes/api/mailer.api.class';
import { SendErrorReportRequestDTO } from '@/classes/dto/mailer/request/sendErrorReport.request.dto';
import { Employee } from '@/interfaces/employee.interface';
import Compressor from 'compressorjs';
import { RuleFactory } from '@/helpers/ruleFactory.helper';
import { Form } from '@/interfaces/Form.interface';
import * as mongoose from "mongoose";

export default Vue.extend({
  data: () => ({
    showDialog: false,
    loading: false,
    showDetails: false,
    screenshotData: null as Blob | null,
    msg: '',
    valid: false,
  }),
  computed: {
    RuleFactory: () => RuleFactory,
    error() {
      if (store.state.error) {
        return store.state.error;
      } else {
        return undefined;
      }
    },
    isConnectionError(): boolean {
      if (!this.error) {
        return false;
      }
      return !!store.state.error?.request && !store.state.error.response;
    },
    statusCode() {
      return store.state.error?.response?.status;
    },
    statusText() {
      return store.state.error?.response?.statusText;
    },
    responseMessage() {
      return (store.state.error?.response?.data as any)?.message as string;
    },
    requestUrl() {
      return `${store.state.error?.config?.baseURL}/${store.state.error?.config?.url}`;
    },
    requestMethod() {
      return store.state.error?.config?.method?.toUpperCase();
    },
    requestPayload() {
      return store.state.error?.config?.data || store.state.error?.config?.params || 'Keine Daten übermittelt';
    },
  },
  methods: {
    async sendReport() {
      const form = this.$refs.form as Form;
      if (!form.validate()) {
        return;
      }
      if (store.state.error) {
        this.loading = true;
        const user = store.state.user as Employee;
        const p: Promise<string> = new Promise((res, rej) => {
          new Compressor(this.screenshotData as Blob, {
            quality: 0.35,
            maxWidth: 1024,
            maxHeight: 1024,
            strict: true,
            success: (file: File) => {
              const reader = new FileReader();
              reader.onload = (event) => {
                if (event.target) {
                  res(event.target.result as string);
                }
              };
              reader.readAsDataURL(file);
            },
            error: (error: Error) => {
              rej(error);
            },
          });
        });
        const screenshot = await p;
        const data: SendErrorReportRequestDTO = {
          config: store.state.error.config,
          res: store.state.error.response,
          screenshot,
          msg: this.msg,
          userId: store.state.user?._id as unknown as mongoose.Types.ObjectId,
          userName: `${user.firstName} ${user.lastName}`,
        };
        try {
          await MailerApi.sendErrorReport(data);
          store.commit('setError');
        } catch (error) {
          // void
        }
        this.loading = false;
      }
    },
    reload() {
      window.location.reload();
    },
  },
  watch: {
    error(v) {
      if (v && !this.isConnectionError) {
        this.$nextTick(async () => {
          const appElement = document.getElementById('app');
          if (appElement) {
            const screenshotPromise = new Promise((resolve) => {
              html2canvas(appElement).then((canvas) => {
                canvas.toBlob((blob) => {
                  this.screenshotData = blob;
                  resolve(this.screenshotData);
                });
              });
            });
            await screenshotPromise;
          }
          this.showDialog = true;
        });
      } else this.showDialog = this.isConnectionError;
    },
  },
});
