
import store from '@/store';
import Vue from 'vue'
export default Vue.extend({
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  computed: {
    showUnauthorizedDialog() {
      return store.state.showUnauthorizedDialog;
    },
  },
});
