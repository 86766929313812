
import Vue from 'vue';
import NavigationDrawerItem from '@/components/app/navigation-drawer-item.app.vue';
import {WarehouseManagerAPI} from '@/classes/api/warehouse-manager.api.class';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import {CrudActionTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes';
import {
    WarehouseOrderFields,
} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/WarehouseOrderFields';
import {BadgeBus} from '@/busses/BadgeBus';
import {WarehouseOrderStates} from '@/enums/WarehouseOrderStates.enum';
import {CustomAccessKeys} from "@/classes/clientOnly/permissionTreeResources/enums/CustomAccessKeys";

export default Vue.extend({
  components: {NavigationDrawerItem},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    showDrawer: false,
    miniVariant: false,
    hideOverlay: true,
    orderUpdateInterval: 0,
    CrudEntityTypes: CrudEntityTypes,
  }),
  computed: {
    isUserEmployee(): boolean {
      return this.$store.state.user && this.$store.state.user.isEmployee;
    },
    isUserTeacher(): boolean {
      return this.$store.state.user && this.$store.state.user.isTeacher;
    },
    productInfoText(): string | undefined {
      if (this.$store.state.openOrders > 0) {
        return this.$store.state.openOrders.toString();
      }
      return undefined;
    },
    productInfoTooltip(): string | undefined {
      if (this.$store.state.openOrders > 0) {
        const openOrders = this.$store.state.openOrders;
        return `Derzeit gibt es ${openOrders} offene ${openOrders === 1 ? 'Bestellung' : 'Bestellungen'}`;
      }
      return undefined;
    },
    CustomAccessKeys: () => CustomAccessKeys,
    canAtLeastOneForSettings(): boolean {
      const requiredAccess = [
        // Stammdaten
        [{type: CrudActionTypes.READ, entity: CrudEntityTypes.CONFIG}],
        // Kundennummern
        [{type: CrudActionTypes.READ, entity: CrudEntityTypes.CUSTOM_ID_SCHEME}, {
          type: CrudActionTypes.UPDATE,
          entity: CrudEntityTypes.CUSTOM_ID_SCHEME,
        }],
        // Kurstabelle
        [{type: CrudActionTypes.READ, entity: CrudEntityTypes.CONTRACT}, {
          type: CrudActionTypes.READ,
          entity: CrudEntityTypes.COURSE,
        }],
        // Import
        [{type: CrudActionTypes.CREATE, entity: CrudEntityTypes.EMPLOYEE}, {
          type: CrudActionTypes.CREATE,
          entity: CrudEntityTypes.INSTITUTION,
        }],
        // Berechtigungen
        [{type: 'custom', entity: undefined, customAccessKey: CustomAccessKeys.SHARE_ACCESS}],
        // Berechtigungsroutinen
        [{type: 'custom', entity: undefined, customAccessKey: CustomAccessKeys.SHARE_ACCESS}, {
          type: CrudActionTypes.READ,
          entity: CrudEntityTypes.PERMISSION_HOOK,
        }],
        // Dokumente
        [{type: CrudActionTypes.CREATE, entity: CrudEntityTypes.DOCUMENT_TYPE}],
        // Cron-Jobs
        [{type: CrudActionTypes.READ, entity: CrudEntityTypes.CRON_JOB}],
        // API & Webhooks
        [{type: CrudActionTypes.READ, entity: CrudEntityTypes.CONFIG}, {
          type: CrudActionTypes.UPDATE,
          entity: CrudEntityTypes.CONFIG,
        }],
      ];
      for (const accessEntry of requiredAccess) {
        // test on every accessEntry if at least one is true
        const isValid = accessEntry.every((el) => {
          if (el.type === 'custom') {
            return this.$$crudAccessManager.getCustom(el.customAccessKey);
          } else {
            return this.$$crudAccessManager.canAccessType( el.entity as CrudEntityTypes, el.type as CrudActionTypes );
          }
        });
        if (isValid) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    toggleMenu() {
      if (this.value) {
        this.showDrawer = true;
        this.miniVariant = false;
      } else {
        if (this.$vuetify.breakpoint.mdAndUp) {
          this.showDrawer = true;
          this.miniVariant = true;
        } else {
          this.showDrawer = false;
          this.miniVariant = false;
        }
      }
    },
    async updateOrderNumbers() {
      const hasAccess = this.$$crudAccessManager.canReadAllProvided<WarehouseOrderFields>(CrudEntityTypes.WAREHOUSE_ORDER,
          WarehouseOrderFields.QTY,
          WarehouseOrderFields.FULFILLED_AT,
      );
      if (hasAccess) {
        const info = await WarehouseManagerAPI.getOrderNumbers({
          type: 'outgoing',
          states: [WarehouseOrderStates.UNTOUCHED],
        });
        this.$store.commit('updateOpenOrders', info.numberOfOrders);
      }
    },
    canAtLeastOneCustom(...keys: CustomAccessKeys[]) {
      for (const key of keys) {
        if (this.$$crudAccessManager.getCustom(key)) {
          return true;
        }
      }
    },
    canCreateOrRead(...types: CrudEntityTypes[]) {
      for (const type of types) {
        if (
            this.$$crudAccessManager.canAccessType(type, CrudActionTypes.CREATE)
            || this.$$crudAccessManager.canAccessType(type, CrudActionTypes.READ)
        ) {
          return true;
        }
      }
      return false;
    },
    async startInterval() {
      try {
        await this.updateOrderNumbers();
        this.orderUpdateInterval = setInterval(() => {
          this.updateOrderNumbers();
        }, 5 * 60 * 1_000) as unknown as number;
      } catch (e) {
        // Ignore
      }
    },
    emitInput(v: boolean) {
      this.$emit('input', v);
    },
  },
  watch: {
    value() {
      this.toggleMenu();
      if (this.$vuetify.breakpoint.smAndDown && this.value) {
        setTimeout(() => {
          this.hideOverlay = false;
        }, 1);
      } else {
        this.hideOverlay = true;
      }
    },
  },
  mounted() {
    this.toggleMenu();
    this.startInterval();
    BadgeBus.$on('update:orderNumbers', () => {
      this.updateOrderNumbers();
    });
  },
  beforeDestroy() {
    clearInterval(this.orderUpdateInterval);
  },
});
