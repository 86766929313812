
import Vue from 'vue';
import {Types} from 'mongoose';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';
import moment from 'moment';
import DatePicker from '@/components/common/datePicker.vue';

moment.locale('de');

export default Vue.extend({
  components: {DatePicker},
  props: {
    dueDate: {type: Object as () => Date, required: true},
    stackId: {type: Object as () => Types.ObjectId, required: true},
    itemId: {type: Object as () => Types.ObjectId, required: true},
  },
  data: () => ({
    editMode: false,
    loading: false,
    updatedDueDate: undefined as unknown as Date,
  }),
  computed: {
    moment: () => moment,
  },
  methods: {
    initializeEditMode() {
      this.updatedDueDate = this.dueDate;
      this.editMode = true;
    },
    stopEditMode() {
      this.updatedDueDate = undefined as unknown as Date;
      this.editMode = false;
    },
    async saveChanges() {
      this.loading = true;
      try {
        await StackManagerApi.updateItem(this.stackId, this.itemId, {dueDate: this.updatedDueDate || null});
        this.$$showSnackbar('Die Änderungen wurden erfolgreich gespeichert', 'success');
        this.$emit('update:dueDate', this.updatedDueDate);
        this.stopEditMode();
      } catch (e) {
        this.$$showSnackbar('Beim Speichern der Änderungen ist ein Fehler aufgetreten', 'error');
      } finally {
        this.loading = false;
      }
    },
  },
});
