
import Vue from 'vue';
import {avataaarClothesGraphicsType, avataaarClothesType} from '@/constants/avataaar.constant';
import ColorButton from '@/components/common/profile/createAvataaarDialog/colorButton.vue';

export default Vue.extend({
  components: {ColorButton},
  props: {
    clothesType: {
      type: String,
      default: '',
    },
    clothesColor: {
      type: String,
      default: '',
    },
    clothesGraphicsType: {
      type: String,
      default: '',
    },
  },
  computed: {
    clothesTypeItems: () => avataaarClothesType,
    clothesGraphicsTypeItems: () => avataaarClothesGraphicsType,
  },
});
