export enum FinderIndices {
    ARTICLES = 'articles',
    CONTRACTS = 'contracts',
    COURSE = 'courses',
    CUSTOMER_MAIL_TEMPLATES = 'customermailtemplates',
    DOCUMENT_TYPES = 'documenttypes',
    EMPLOYEES = 'employees',
    INSTITUTIONS = 'institutions',
    PARENTS = 'parents',
    RESPONSIBLES = 'responsibles',
    STUDENTS = 'students',
    SUBJECTS = 'subjects',
    TAGS = 'tags',
    WAREHOUSE_ORDERS = 'warehouseorders',
    ROLES = 'roles',
}
