
import Vue from 'vue';
import ColorButton from '@/components/common/profile/createAvataaarDialog/colorButton.vue';

export default Vue.extend({
  components: {ColorButton},
  props: {
    skinColor: {
      type: String,
      default: '',
    },
  },
});
